import React from 'react';
import { createTheme } from '@mui/material/styles';

export const CustomizedTemplate = createTheme({
  palette: {
    laksh: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#fff',
    },
    // secondary: {
    //   light: '#ff7961',
    //   main: '#f44336',
    //   dark: '#ba000d',
    //   contrastText: '#000',
    // },
  },
});
