import { FormControlLabel, Slider, Switch, alpha } from '@mui/material';
import React, { useState } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import { Label } from '@mui/icons-material';

export const SwichInputBRH = ({ name, label, value, handleChange, labelStart }) => {
  const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    ({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#f29a0e',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
    })
  );

  const [checked, setChecked] = useState(false);
  const handleChanging = (e) => {
    setChecked(e.target.checked);
    console.log(checked);
  };
  return (
    <>
      <FormControlLabel
        control={<IOSSwitch checked={value} onChange={handleChange} />}
        label={label}
        labelPlacement={labelStart ? 'start' : ''}
        //  sx={{ paddingLeft: labelStart ? '16px' : '', gap: labelStart ? '5px' : '' }}
        name={name}
        sx={{ paddingLeft: '16px', gap: '5px' }}
      />
    </>
  );
};
