import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import logo from "../../assets/images/logo2.png";
import { MdOutlineEventNote } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import API from "../../API_SERVICES/ApiServices";
import { onGetSavedFilters } from "./savedFilterSlice";
import { routeNames } from "../../API_SERVICES/routeNames";

const NavbarContainer = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedData = useSelector((state) => state?.optionReduser);

  const saveS = useSelector((state) => state?.saved?.savedFiltersData);

  const filCount = saveS?.length > 0 ? saveS?.length : 0;

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure want logout?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("token_type");
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    const { id } = JSON.parse(localStorage.getItem("user"));
    API.postMethod("api/get-option-filter-list", "POST", { user_id: id })
      .then((res) => res.json())
      .then((result) => {
        dispatch(onGetSavedFilters(result.results));
      });
  }, []);

  return (
    <Navbar className="d-flex justify-content-around" bg="light" expand="lg">
      <Container>
        <Nav className="me-auto col-md-1 col-sm-2 col-xs-2">
          <Navbar.Brand>
            <Link to="/">
              <img
                src={logo}
                width="65"
                height="60"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Link>
          </Navbar.Brand>
        </Nav>

        <Nav className="col-md-1 col-sm-2 col-xs-2">
          <Link to="/sales/saved-filters">
            <span class="icon-button">
              <FaFilter
                style={{
                  marginLeft: "1rem",
                  color: "#fc9200",
                  fontSize: "1.7rem",
                }}
              />
              <span class="icon-button__badge">{user ? filCount : null}</span>
            </span>
          </Link>
        </Nav>
        <Nav
          className="col-md-1 col-sm-2 col-xs-2"
          style={{ marginRight: "1rem" }}
        >
          <Link to="/sales/selected-options">
            <span class="icon-button">
              <MdOutlineEventNote
                style={{
                  marginLeft: "1rem",
                  color: "#fc9200",
                  fontSize: "2.25rem",
                }}
              />
              <span class="icon-button__badge badgeTop">
                {user ? selectedData.selectedData.length : null}
              </span>
            </span>
          </Link>
        </Nav>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse
          className="col-md-9 col-sm-2 col-xs-2"
          id="navbarScroll"
        >
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{ maxHeight: "125px" }}
            navbarScroll
          >
            <Nav.Link
              onClick={() => navigate("/")}
              active={window.location.pathname === "/"}
            >
              Home
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/sales/pdf-history")}
              active={window.location.pathname === "/sales/pdf-history"}
            >
              Emailed Prospect List
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/sales/client-details")}
              active={
                window.location.pathname === "/sales/client-details" ||
                window.location.pathname === "/sales/add-client"
              }
            >
              Prospect List
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/sales/property")}
              active={
                window.location.pathname === routeNames.PROSPECTPROPERTYADD ||
                window.location.pathname.includes("/sales/property/edit/") ||
                window.location.pathname === routeNames.PROSPECTPROPERTYLIST
              }
            >
              Prospect Property
            </Nav.Link>

            <NavDropdown title="Profile" id="navbarScrollingDropdown">
              <NavDropdown.Item onClick={() => navigate("/sales/profile")}>
                My Profile
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarContainer;
