import moment from "moment";
import React, { useEffect, useState } from "react";
import { Alert, Container } from "react-bootstrap";
import API from "../../API_SERVICES/ApiServices";

const PdfHistory = () => {
  const [pdfHistories, setPdfHistories] = useState([]);

  useEffect(() => {
    getHistories();
  }, []);

  const getHistories = async () => {
    const { id } = JSON.parse(localStorage.getItem("user"));

    await API.postMethod("api/get-email-pdf-history", "POST", { user_id: id })
      .then((res) => res.json())
      .then((result) => {
        console.log("Re", result.data);
        setPdfHistories(result.data);
      });
  };

  return (
    <Container>
      <div className="form-group m-2">
        <h5 className="textPrimary text-uppercase">Emailed PROSPECT list</h5>
      </div>
      {pdfHistories?.length > 0 ? (
        pdfHistories?.map((m, index) => (
          <Alert
            className="border rounded-0 m-2 p-2 d-flex justify-content-between align-items-center"
            key={index}
            variant="light"
          >
            <div className="d-flex flex-column">
              <span style={{ fontWeight: "600" }}>
                {m.client?.company_name}
              </span>
              <span style={{ fontWeight: "400" }}>
                {m.client?.contact_person_name}
              </span>
              <small style={{ fontSize: "10px", fontWeight: "600" }}>
                {moment(m.created_at).format("DD MMM  yyyy")}
              </small>
            </div>

            <a
              className="primary text-white"
              href={m.pdf_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Show PDF
            </a>
          </Alert>
        ))
      ) : (
        <h6 className="textPrimary text-capitalize text-center">
          No History Found
        </h6>
      )}
    </Container>
  );
};

export default PdfHistory;
