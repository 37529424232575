import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedData: [],
  clientData: [],
  optionsData: [],
  queryData: [],
};

export const slices = createSlice({
  name: "optionsSelected",
  initialState,
  reducers: {
    onSearchData: (state, action) => {
      state.optionsData = action.payload;
    },
    onSelectedOptions: (state, action) => {
      state.selectedData = [...state.selectedData, action.payload];
    },
    onRemoveSelected: (state, action) => {
      state.selectedData = state.selectedData.filter(
        (arrow) => arrow.id !== action.payload.id
      );
    },
    onSubmitClient: (state, action) => {
      state.clientData = action.payload;
    },
    onQueryData: (state, action) => {
      state.queryData = action.payload;
    },
  },
});

export const {
  onSelectedOptions,
  onSubmitClient,
  onRemoveSelected,
  onSearchData,
  onQueryData,
} = slices.actions;

export default slices.reducer;
