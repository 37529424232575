import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';

export const CommonAlert = ({
  open = false,
  setOpen,
  message = '',
  proceedButtonText = 'Proceed',
  cancelButtonText = 'Go Back',
  proceedFunction,
  goBackFunction,
}) => {
  // const laksh = () => {
  //   if (isLead === 1 && isOkay === 1) {
  //     return 'Verify';
  //   } else if (isLead === 0 && isOkay === 1) {
  //     return 'Approve';
  //   } else {
  //     return 'Reject';
  //   }
  // };
  //   const [open, setOpen] = useState(false);
  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ minWidth: '300px' }}>
            <DialogTitle id="alert-dialog-title">{'Confirmation!'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={goBackFunction} variant="outlined" color="secondary">
                {cancelButtonText}
              </Button>
              <Button onClick={proceedFunction} variant="contained">
                {proceedButtonText}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      </div>
    </>
  );
};
