export const routeNames = {
  LOGIN: "/login ",
  FORGOTPASSWORD: "",
  INDEX: "/dashboard/app",

  PROSPECTS: "/masters/prospects",
  PROSPECTSADD: "/masters/prospects/add",
  PROSPECTSEDIT: "/masters/prospects/edit/:id",

  PROSPECTPROPERTYLIST: "/sales/property",
  PROSPECTPROPERTYADD: "/sales/property/add",
  PROSPECTPROPERTYEDIT: "/sales/property/edit/:id",

  PROPERTYAGREEMENTSLIST: "/masters/property-agreements",
  PROPERTYAGREEMENTSADD: "/masters/property-agreements/add",

  CLIENTS: "/masters/client",
  CLIENTSADD: "/masters/client/add",
  CLIENTSEDIT: "/masters/client/edit/:id",

  COMPANYMASTER: "/settings/MG-master",
  COMPANYMASTERADD: "/settings/MG-master/add",
  COMPANYMASTEREDIT: "/settings/MG-master/edit/:id",

  CRS: "/crs",
  CRSADD: "/crs/add",
  CRSEDIT: "/crs/edit/:id",

  PRS: "/prs",
  PRSADD: "/prs/add",
  PRSEDIT: "/prs/edit:id",

  MOU: "/mou",
  MOUADD: "/mou/add",
  MOUEDIT: "/mou/edit/:id",
  CONTRACT: "/contract",
  CONTRACTADD: "/contract/add",
  CONTRACTEDIT: "/contract/edit:id",

  CONTRACTTEMPLATES: "/reports/contract-templates",

  REMINDERSETTINGS: "/settings/reminder-settings",
  VIEWPROFILE: "/profile",
};
