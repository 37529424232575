import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Dialog,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";

import moment from "moment";
import * as API from "../../API_SERVICES/ApiConfigs";

import { routeNames } from "../../API_SERVICES/routeNames";
import { TableSkeleton } from "../../ResusableComponents/skeleton/TableSkeleton";
import { contractContext } from "../../ResusableComponents/context/AppContext";
import { LoadingButtonBRH } from "../../ResusableComponents/forms/LoadingButtonBRH";
import Scrollbar from "../../ResusableComponents/scrollbar/Scrollbar";
import Iconify from "../../ResusableComponents/iconify/Iconify";
import { NormalTable } from "../../ResusableComponents/tables/NormalTable";

const ProspectPropertyList = () => {
  const { propertyList } = useContext(contractContext);
  const [propertyListData, setPropertyListData] = useState({
    propectDataList: [],
    isFetched: false,
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, //customize the default page size
  });
  const ref = useRef();
  const [qrAlert, setQrAlert] = useState({
    alert: false,
    message: "",
    qrshow: false,

    qrimage: "",
    propName: "",
  });

  const navigate = useNavigate();

  const redirectTo = () => {
    navigate("/masters/prospect-property/add");
  };

  const handleResource = (data) => {
    if (data.amenities_count > 0) {
      const tabsdata = {
        property_id: data.id,
        tabs: "workspace",
      };

      API.postMethod("api/propertytabs", tabsdata).then((result) => {
        if (result.data.status === "success") {
          navigate(`/sales/property/edit/${data.id}`);
        }
      });
    } else {
      const tabsdata = {
        property_id: data.id,
        tabs: "amenities",
      };

      API.postMethod("api/propertytabs", tabsdata).then((result) => {
        if (result.data.status === "success") {
          navigate(`/sales/property/edit/${data.id}`);
        }
      });
    }
  };

  const handlePlan = (data) => {
    if (data.resource_count > 0) {
      const tabsdata = {
        property_id: data.id,
        tabs: "plans",
      };

      API.postMethod("api/propertytabs", tabsdata).then((result) => {
        if (result.data.status === "success") {
          //  window.location.reload();
          navigate(`/sales/property/edit/${data.id}`);
        }
      });
    } else {
      const tabsdata = {
        property_id: data.id,
        tabs: "workspace",
      };

      API.postMethod("api/propertytabs", tabsdata).then((result) => {
        if (result.data.status === "success") {
          //  window.location.reload();
          navigate(`/sales/property/edit/${data.id}`);
        }
      });
    }
  };
  const handleAmenities = (data) => {
    if (data.amenities_count > 0) {
      const tabsdata = {
        property_id: data.id,
        tabs: "amenities",
      };

      API.postMethod("api/propertytabs", tabsdata).then((result) => {
        if (result.data.status === "success") {
          //  window.location.reload();
          navigate(`/sales/property/edit/${data.id}`);
        }
      });
    } else {
      const tabsdata = {
        property_id: data.id,
        tabs: "basicdetails",
      };

      API.postMethod("api/propertytabs", tabsdata).then((result) => {
        if (result.data.status === "success") {
          //  window.location.reload();
          navigate(`/sales/property/edit/${data.id}`);
        }
      });
    }
  };
  const generateQR = (rowData) => {
    const IMG_URL = "https://brhfiles.s3.ap-south-1.amazonaws.com";

    setQrAlert((prev) => ({
      ...prev,
      qrimage: `${IMG_URL}${rowData.qr_code_path}`,
      qrshow: true,
      propName: rowData.property_name,
    }));
  };
  const qrGenerate = (rowData) => {
    const propertyId = rowData.id;
    const propertyCode = rowData.property_code;

    const imgUrl = "https://brhfiles.s3.ap-south-1.amazonaws.com";

    API.postMethod(`api/propertyqrcode?property_id=${propertyId}`).then(
      (result) => {
        if (result.data.status === "success") {
          // window.location.reload();
          //   this.componentDidMount();

          setQrAlert((prev) => ({
            ...prev,
            alert: true,
            message: result.data.message,
            qrshow: true,

            qrimage: `${imgUrl}${result.data?.path}`,
            propName: rowData.property_name,
          }));
        }
      }
    );
  };
  const exportPdf = () => {
    const input = document.getElementById("App");
    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const imgWidth = 100;
      // const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgHeight = 150;
      const imgData = canvas.toDataURL("img/png");
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF("p", "mm", [100, 150]);
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.setFontSize(10);
      // pdf.text(50, 4, propName, { align: "center" });
      // pdf.setFontType("bold");
      pdf.save(`${qrAlert.propName}.pdf`);

      setQrAlert((prev) => ({ ...prev, qrshow: false }));
      window.location.reload();
    });
  };
  // const data = [
  //   {
  //     name: {
  //       firstName: 'John',
  //       lastName: 'Doe',
  //     },
  //     address: '261 Erdman Ford',
  //     city: 'East Daphne',
  //     state: 'Kentucky',
  //   },
  //   {
  //     name: {
  //       firstName: 'Jane',
  //       lastName: 'Doe',
  //     },
  //     address: '769 Dominic Grove',
  //     city: 'Columbus',
  //     state: 'Ohio',
  //   },
  //   {
  //     name: {
  //       firstName: 'Joe',
  //       lastName: 'Doe',
  //     },
  //     address: '566 Brakus Inlet',
  //     city: 'South Linda',
  //     state: 'West Virginia',
  //   },
  //   {
  //     name: {
  //       firstName: 'Kevin',
  //       lastName: 'Vandy',
  //     },
  //     address: '722 Emie Stream',
  //     city: 'Lincoln',
  //     state: 'Nebraska',
  //   },
  // ];
  const handlePropertyTabs = (rowData) => {
    if (rowData.is_draft === 1) {
      navigate(`/sales/property/edit/${rowData.id}`);
    } else {
      const tabsdata = {
        property_id: rowData.id,
        tabs: "basicdetails",
      };

      API.postMethod("api/propertytabs", tabsdata).then((result) => {
        if (result.data.status === "success") {
          navigate(`/sales/property/edit/${rowData.id}`);
        }
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "property_name",
        header: "Property Name",
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div>
                {rowData.property_name ? (
                  <Button
                    variant="text"
                    sx={{
                      background: "none",
                      cursor: "pointer",
                      border: "none",
                      color: "#3d464f",
                    }}
                    onClick={() => handlePropertyTabs(rowData)}
                  >
                    {rowData.property_name}
                  </Button>
                ) : (
                  "-"
                )}
              </div>
            </>
          );
        },
        size: 150,
      },
      {
        accessorKey: "property_code",
        header: "Code",
        size: 150,
      },
      {
        accessorKey: "provider_id",
        header: "Provider Name",
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <span>
                {rowData?.provider?.name ? rowData?.provider?.name : "Admin"}
              </span>
            </>
          );
        },
        size: 150,
      },
      {
        accessorKey: "resource_count",
        header: "No.of.Resource",
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div style={{ textAlign: "center" }}>
                {rowData.resource_count > 0 ? (
                  <Button
                    variant="text"
                    sx={{
                      background: "none",
                      cursor: "pointer",
                      border: "none",
                      color: "#3d464f",
                    }}
                    onClick={() => handleResource(rowData)}
                    href="#"
                  >
                    {rowData.resource_count}
                  </Button>
                ) : (
                  <span>Nill</span>
                )}
              </div>
            </>
          );
        },
        size: 150,
      },
      {
        accessorKey: "amenities_count",
        header: "Active Plans",
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div style={{ textAlign: "center" }}>
                {rowData.resource_plan_count ? (
                  <Button
                    variant="text"
                    sx={{
                      background: "none",
                      cursor: "pointer",
                      border: "none",
                      color: "#3d464f",
                    }}
                    onClick={() => handlePlan(rowData)}
                    href="#"
                  >
                    {rowData.resource_plan_count}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    sx={{
                      background: "none",
                      cursor: "pointer",
                      border: "none",
                      color: "#3d464f",
                    }}
                    onClick={() => handlePlan(rowData)}
                    href="#"
                  >
                    Create Plan
                  </Button>
                )}
              </div>
            </>
          );
        },
        size: 150,
      },
      {
        accessorKey: "amenities_count",
        header: "Active Services",
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div style={{ textAlign: "center" }}>
                {rowData.amenities_count ? (
                  <Button
                    variant="text"
                    sx={{
                      background: "none",
                      cursor: "pointer",
                      border: "none",
                      color: "#3d464f",
                    }}
                    onClick={() => handleAmenities(rowData)}
                    href="#"
                  >
                    {rowData.amenities_count}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    sx={{
                      background: "none",
                      cursor: "pointer",
                      border: "none",
                      color: "#3d464f",
                    }}
                    onClick={() => handleAmenities(rowData)}
                  >
                    {" "}
                    {rowData.amenities_count}
                  </Button>
                )}
              </div>
            </>
          );
        },
        size: 150,
      },
      {
        accessorKey: "updated_at",
        header: "Onboarded Date & Time",
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div>
                {rowData.updated_at
                  ? moment(rowData.updated_at).format("DD-MMM-YYYY h:mm A")
                  : rowData.created_at
                  ? moment(rowData.created_at).format("DD-MMM-YYYY h:mm A")
                  : "-"}
              </div>
            </>
          );
        },
        size: 150,
      },
      {
        header: "QR Code",
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;

          return (
            <>
              {rowData.is_draft === 1 || rowData.is_verified === 0 ? (
                <Button disabled="true">Not Generated</Button>
              ) : rowData.qr_code_path ? (
                <>
                  <Button
                    onClick={() => generateQR(rowData)}
                    className="text-info"
                  >
                    Generated
                  </Button>
                  <br />
                  {rowData.is_qr_code === 2 ? (
                    <Button
                      href="#"
                      onClick={() => qrGenerate(rowData)}
                      className="text-info"
                    >
                      Re-Generate
                    </Button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <Button
                  //  href="#"
                  onClick={() => qrGenerate(rowData)}
                  className="text-info"
                >
                  Generate New QR Code
                </Button>
              )}
            </>
          );
        },
      },
      {
        header: "Status",
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;

          return (
            <div>
              {rowData.is_draft === 1 || rowData.is_verified === 0 ? (
                "Draft"
              ) : (
                <FormControl fullWidth>
                  <select
                    className="dropdownform "
                    name="actions"
                    id={rowData.id}
                    onChange={(e) => this.handleChangeAction(e, rowData)}
                  >
                    {/* <option disabled selected={(rowData.is_verified==0) ? true : false} value="">Select</option> */}
                    <option selected={rowData.is_verified === 1} value={1}>
                      Onboard
                    </option>
                    <option selected={rowData.is_verified === 2} value={2}>
                      Publish
                    </option>
                  </select>
                </FormControl>
              )}
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      {propertyList.length > 0 ? (
        <>
          <Helmet>
            <title> Sales | BRH </title>
          </Helmet>
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h4" gutterBottom>
                Prospect Properties
              </Typography>
              <Button
                onClick={() => navigate(routeNames.PROSPECTPROPERTYADD)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Prospect Property
              </Button>
            </Stack>
            <Card sx={{ p: 2, marginBottom: "30px" }}>
              <NormalTable
                columns={columns}
                data={propertyList}
                onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                state={{ pagination }}
              />{" "}
            </Card>
          </Container>
        </>
      ) : (
        <TableSkeleton />
      )}
      <Dialog
        open={qrAlert.qrshow}
        onClose={() => setQrAlert((prev) => ({ ...prev, qrshow: false }))}
        //  fullWidth={fullWidth}
        maxWidth="lg"
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: 600, fontSize: "25px" }}>QR Generate</span>
          <Tooltip arrow placement="left" title="close">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setQrAlert((prev) => ({ ...prev, qrshow: false }))}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContentText style={{ padding: "20px" }}>
          <div className="App">
            {/* <button onClick={() => exportPdf()}>Print PDF</button>{" "} */}
            <header id="App">
              <div className="react-pdf" ref={ref}>
                <img
                  src={
                    "https://brhfiles.s3.ap-south-1.amazonaws.com/images/qr_template/1669903600.png"
                  }
                  alt="img-qrTemp"
                  style={{ width: "400px", height: "550px" }}
                  crossOrigin="*"
                />
                <div className="imgCentered">
                  <img
                    src={qrAlert.qrimage}
                    alt="img-qr"
                    style={{ width: "200px", height: "195px" }}
                    crossOrigin="*"
                  />
                </div>
                <div className="imgTitle">{qrAlert.propName}</div>
              </div>{" "}
            </header>{" "}
            <div style={{ "text-align": "center" }}>
              {" "}
              {/* <Button className="btn btn-sm  mt-2" onClick={() => exportPdf()}>
                Download Image
              </Button> */}
              <LoadingButtonBRH
                variant="containeds"
                label={"Download Image"}
                type="submit"
                style={{ width: 250 }}
                handleSubmit={exportPdf}
              />
            </div>
          </div>
        </DialogContentText>
      </Dialog>
    </>
  );
};

export default ProspectPropertyList;
