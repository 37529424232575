import React, { useRef, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import API from "../../API_SERVICES/ApiServices";
import toast from "react-simple-toasts";
import { toastConfig } from "react-simple-toasts";

import "../../App.css";

const Login = () => {
  const username = useRef();
  const password = useRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const history = useNavigate();

  toastConfig({
    time: 5000,
    className: "textPrimary",
  });

  const handleChange = (e) => {
    if (e.target.name === "email") {
      return username.current.value;
    }
    if (e.target.name === "password") {
      return username.current.value;
    }
  };

  const handleLogin = (e) => {
    let data = {
      email: username.current.value,
      password: password.current.value,
      role: ["Super Admin", "Sub Admin", "Admin", "Sales Executive", "Staff"],
    };
    e.preventDefault();
    setLoading(true);

    https: API.postMethod("user-management/api/v1/sign-in", "post", data)
      .then((res) => res.json())
      .then((result) => {
        console.log(result.data);
        if (result.status) {
          setLoading(false);
          console.log("Success", result);
          localStorage.setItem("user", JSON.stringify(result.data));
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("token_type", "Bearer");
          window.location.reload();
        } else {
          toast(result.message, { ...toastConfig });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="container-fluid text-center p-0">
      <div className="fixed-header">
        <img
          width={200}
          src="https://dev.berighthere.com/wp-content/uploads/2021/03/Logo-New.png"
          alt="logo"
          className="py-3"
        />
      </div>
      <div className="pt-5">
        <div className="primary text-dark w-100 mt-3">SALES CATLOGUE</div>
        <Form onSubmit={handleLogin} className="pt-3 salesForm px-5">
          <Form.Group className="d-flex search-field mb-2 w-100 justify-content-center">
            <Form.Control
              ref={username}
              onChange={handleChange}
              type="email"
              required
              name="email"
              placeholder="Username"
              size="lg"
              className="rounded-0 "
            />
          </Form.Group>
          <Form.Group className="d-flex search-field w-100 justify-content-center">
            <Form.Control
              ref={password}
              onChange={handleChange}
              required
              type="password"
              name="password"
              placeholder="Password"
              size="lg"
              className="rounded-0"
            />
          </Form.Group>
          <div className="mt-3">
            <button type="submit" className="w-100 font-wight-medium primary">
              {loading ? (
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              ) : (
                "SIGN IN"
              )}
            </button>
          </div>
          {/* <div className="my-2 d-flex justify-content-between align-items-center">
        <div className="form-check">
          <label className="form-check-label text-muted">
            <input type="checkbox" className="form-check-input" />
            <i className="input-helper"></i>
            Keep me signed in
          </label>
        </div>
      </div> */}
        </Form>
      </div>
      <div className="fixed-footer">
        <div>
          <div className="powerbyText">Powered by</div>
          <hr className="hrLine"></hr>
        </div>
        <img
          width={200}
          src={require("../../assets/images/email_images/logo_side.png")}
          alt="logo"
        />
      </div>
    </div>
  );
};

export default Login;
