import { MaterialReactTable } from "material-react-table";
import React from "react";

export const NormalTable = ({
  columns,
  data,
  initialState = {
    showGlobalFilter: false,
    density: "compact",
  },
  onPaginationChange,
  enableFullScreenToggle = false,
  enableTopToolbar,
  enableBottomToolbar,
  enableRowActions,
  enablePinning,
  renderRowActions,
  enableColumnActions = false,
  renderRowActionMenuItems,
  renderDetailPanel,
  renderTopToolbarCustomActions,
  renderToolbarInternalActions,
  positionToolbarAlertBanner,
  state,

  muiTableHeadCellProps = {
    sx: (theme) => ({
      background: "#fff4e6",
      borderRight: "1px solid #fff", // #006afc #ffcb99
      color: "#fc9200",
    }),
  },
}) => {
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        onPaginationChange={onPaginationChange}
        initialState={initialState}
        enableFullScreenToggle={enableFullScreenToggle}
        enableTopToolbar={enableTopToolbar}
        enableBottomToolbar={enableBottomToolbar}
        enableRowNumbers
        enableRowActions={enableRowActions}
        positionToolbarAlertBanner={positionToolbarAlertBanner}
        renderRowActions={renderRowActions}
        renderRowActionMenuItems={renderRowActionMenuItems}
        renderDetailPanel={renderDetailPanel}
        enablePinning={enablePinning}
        muiTableHeadCellProps={muiTableHeadCellProps}
        enableColumnActions={enableColumnActions}
        state={state}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        renderToolbarInternalActions={renderToolbarInternalActions}

        // positionActionsColumn="last"
        // renderRowActions={({ row: { original } }) => (
        //   <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
        //     <IconButton color="secondary" onClick={() => handleEdit(original)}>
        //       <EditIcon />
        //     </IconButton>
        //     <IconButton color="error" onClick={() => handleDelete(original)}>
        //       <DeleteIcon />
        //     </IconButton>
        //   </Box>
        // )}
        // positionGlobalFilter="left"
        // enableStickyHeader
      />
    </>
  );
};
