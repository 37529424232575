import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";

import { TabList, TabContext, TabPanel } from "@mui/lab";

import BasicDetails from "./BasicDetails";
import Amenities from "./Amenities";
import WorkSpaceList from "./WorkSpaceList";
import PlansAdd from "./PlansAdd";
import TermsAndConditions from "./TermsAndConditions";
import Others from "./Others";

import * as API from "../../API_SERVICES/ApiConfigs";

const AddProspectListPage = () => {
  const [isBasicDetailsComplete, setIsBasicDetailsComplete] = useState(false);
  const [tabdisable, setTabdisable] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  const [propertyTabs, setPropertyTabs] = React.useState("basicdetails");
  const [skipPlans, setSkipPlans] = React.useState("");
  const [resourceData, setResourceData] = useState([]);
  const [resourcePlanData, setResourcePlanData] = useState([]);
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);
  const [propertyTermsData, setPropertyTermsData] = useState([]);
  const params = useParams();

  console.log(
    "ff",
    tabdisable,
    resourceData,
    !(
      propertyId &&
      resourceData.length &&
      amenitiesData.length > 0 &&
      propertyImages.length > 0 &&
      (skipPlans === 1 || resourcePlanData.length > 0)
    )
  );
  useEffect(() => {
    if (params.id) {
      setPropertyId(params.id);
      API.getData(`api/property/${params.id}`).then((result) => {
        console.log(result.data.data);
        if (result.data.data.skip_plans === 1) {
          setSkipPlans(result.data.data.skip_plans);
        }
        if (result.data.data.furnished_status === "Bareshell") {
          setTabdisable(true);
        } else {
          setTabdisable(false);
        }
      });

      API.getData(`api/propertyamenities/${params.id}`).then((result) => {
        if (result.data.data) {
          setAmenitiesData(result.data.data);
        } else {
          localStorage.removeItem("property_tabs");
          setAmenitiesData([]);
        }
      });
      API.getData(`api/propertyresources/${params.id}`)
        //  .then((res) => res.json())
        .then((result) => {
          if (result.data.success === "success") {
            const data = result.data.data.filter((i) => i.status !== 2);
            setResourceData(data);
          } else {
            setResourceData([]);
          }
        });
      API.getData(`api/resourceplans/${params.id}`).then((result) => {
        if (result.data.data) {
          const data = result.data.data.filter((i) => i.status !== 2);
          setResourcePlanData(data);
          console.log(result.data.data);
          if (
            data.length > 0 ||
            result.data.property_tabs === "terms&conditions"
          ) {
            setSkipPlans(1);
          }
        } else {
          setResourcePlanData([]);
        }
      });
      API.getData(`api/propertyimage/${params.id}`).then((result) => {
        if (result.data.data) {
          setPropertyImages(result.data.data);
        } else {
          setPropertyImages([]);
        }
      });
      API.getData(`api/propertiesterms/${params.id}?limit=100`).then(
        (result) => {
          if (result.data.status === "success") {
            setPropertyTermsData(result.data.data);
          }
        }
      );
    }

    if (!localStorage.getItem("plan_enable")) {
      localStorage.setItem("plan_enable", "");
    }
  }, [propertyTabs]);
  const handleChange = (event, newValue) => {
    setPropertyTabs(newValue);
    localStorage.setItem("property_tabs", newValue);
  };
  useEffect(() => {
    API.getData(`api/property/${params.id}`).then((result) => {
      setPropertyTabs(result.data.data.property_tabs);

      if (result.data.data.skip_plans === 1) {
        setSkipPlans(result.data.data.skip_plans);
      }
    });
  }, []);

  return (
    <div>
      <h3
        className="page-title"
        style={{ marginLeft: "20px", marginTop: "10px" }}
      >
        {params.id ? "Edit" : "Add"} Property
      </h3>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={propertyTabs}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Basic Details" value="basicdetails" />
              <Tab
                label="Amenities"
                value="amenities"
                disabled={!(propertyId && propertyImages.length > 0)}
              />
              <Tab
                label="WorkSpace"
                value="workspace"
                disabled={
                  !(
                    propertyId &&
                    amenitiesData.length > 0 &&
                    propertyImages.length > 0
                  ) || tabdisable
                }
              />
              <Tab
                label="Plans"
                value="plans"
                disabled={
                  !(
                    propertyId &&
                    resourceData.length &&
                    amenitiesData.length > 0 &&
                    propertyImages.length > 0
                  ) || tabdisable
                }
              />
              <Tab
                label="Terms and Conditions"
                value="terms&conditions"
                disabled={
                  tabdisable
                    ? !(
                        amenitiesData.length > 0 &&
                        propertyImages.length > 0 &&
                        propertyId
                      )
                    : !(
                        propertyId &&
                        resourceData.length &&
                        amenitiesData.length > 0 &&
                        propertyImages.length > 0 &&
                        skipPlans === 1
                      )
                }
              />
              <Tab
                label="Others"
                value="others"
                disabled={
                  tabdisable
                    ? !(
                        amenitiesData.length > 0 &&
                        propertyImages.length > 0 &&
                        propertyId &&
                        propertyTermsData.length > 0
                      )
                    : !(
                        propertyId &&
                        resourceData.length > 0 &&
                        amenitiesData.length > 0 &&
                        propertyImages.length > 0 &&
                        propertyTermsData.length > 0
                      )
                }
              />
            </TabList>
          </Box>
          <TabPanel value="basicdetails">
            <BasicDetails
              propertyId={propertyId === "" ? params.id : propertyId}
              onChangeNext={() => setPropertyTabs("amenities")}
            />
          </TabPanel>
          <TabPanel value="amenities">
            <Amenities
              propertyId={propertyId === "" ? params.id : propertyId}
              onChange={() => setPropertyTabs("basicdetails")}
              onChangeNext={() => {
                if (tabdisable) {
                  setPropertyTabs("terms&conditions");
                } else {
                  setPropertyTabs("workspace");
                }
              }}
            />
          </TabPanel>
          <TabPanel value="workspace">
            <WorkSpaceList
              propertyId={propertyId === "" ? params.id : propertyId}
              onChange={() => setPropertyTabs("amenities")}
              onChangeNext={() => setPropertyTabs("plans")}
            />
          </TabPanel>
          <TabPanel value="plans">
            <PlansAdd
              propertyId={propertyId === "" ? params.id : propertyId}
              onChange={() => setPropertyTabs("workspace")}
              onChangeNext={() => setPropertyTabs("terms&conditions")}
            />
          </TabPanel>
          <TabPanel value="terms&conditions">
            <TermsAndConditions
              propertyId={propertyId === "" ? params.id : propertyId}
              onChange={() => setPropertyTabs("plans")}
              onChangeNext={() => setPropertyTabs("others")}
            />
          </TabPanel>
          <TabPanel value="others">
            <Others
              propertyId={propertyId === "" ? params.id : propertyId}
              onChange={() => setPropertyTabs("terms&conditions")}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default AddProspectListPage;
