import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import API from "../../API_SERVICES/ApiServices";
const ClientList = () => {
  const [clientData, setClientData] = useState([]);
  const [show, setShow] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalName, setModalName] = useState("");
  const [clientDetails, setClientDetails] = useState({ status: 1 });
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getClinetInfo();
  }, []);

  const getClinetInfo = async () => {
    const { id } = JSON.parse(localStorage.getItem("user"));

    await API.getData("api/sale-client/")
      .then((res) => res.json())
      .then((result) => {
        setClientData(result.data);
      })
      .catch((err) => console.log("Get client info", err));
  };
  const clientHandleChange = (e) => {
    const { name, value } = e.target;
    setClientDetails({ ...clientDetails, [name]: value, user_id: user?.id });
  };

  const onView = (info) => {
    console.log("Info", info);
    setModalName("Client Information");
    setShow(true);
    setClientDetails(info);

    setIsEditMode(false);
  };
  const onEdit = (editInfo) => {
    setShow(true);
    setModalName(" Edit Client Information");
    setIsEditMode(true);
    setClientDetails(editInfo);
  };

  const clientInfoSubmit = (e) => {
    e.preventDefault();

    API.postMethod("api/sale-client", "POST", clientDetails)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          console.log("sale-client", result);
          Swal.fire("Success", result.message, "success").then((res) => {
            if (res.isConfirmed) {
              console.log("ResClient", result);
            }
          });
        } else {
          Swal.fire("Error", result.message, "warning");
        }
      });
  };

  const editClientInfoSubmit = (e) => {
    e.preventDefault();

    const { id } = clientDetails;
    console.log("clientDetails", clientDetails);

    API.postMethod("api/sale-client/" + id, "PUT", clientDetails)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          console.log("sale-client", result);
          Swal.fire("Success", result.message, "success").then((res) => {
            if (res.isConfirmed) {
              console.log("ResClientEdit", result);
              getClinetInfo();
              setShow(false);
              setIsEditMode(false);
            }
          });
        } else {
          Swal.fire("Error", result.message, "warning");
        }
      });
  };
  const deleteClientInfoSubmit = (deleteInfo) => {
    const { id } = deleteInfo;

    API.postMethod("api/sale-client/" + id, "DELETE")
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          console.log("sale-client", result);
          Swal.fire("Success", result.message, "success").then((res) => {
            if (res.isConfirmed) {
              console.log("DeleteInfo", result);
              getClinetInfo();
            }
          });
        } else {
          Swal.fire("Error", result.message, "warning");
        }
      });
  };

  const AddClientInfo = () => {
    navigate("/sales/add-client");
  };

  const cancelBtn = (e) => {
    e.preventDefault();
    setShow(false);
  };

  const headers = [
    { label: "Company Name", key: "company_name" },
    { label: "Contact Person Name", key: "contact_person_name" },
    { label: "Contact Person Email", key: "contact_person_email" },
    { label: "Contact Person Number", key: "contact_person_number" },
    { label: "Contact Name", key: "contact_name" },
    { label: "Contact Email", key: "contact_email" },
    { label: "Contact Number", key: "contact_number" },
    { label: "Conversion Probability", key: "conversionProbality" },
    { label: "Estimated Date of Closure", key: "estimated_date_of_closure" },
    { label: "Estimated Value Deal", key: "estimated_value_of_deal" },
  ];

  const conversionProbality = (key) => {
    console.log("key", key);
    return key == 1 ? ">70%" : key == 2 ? "50%-70%" : "<50%";
  };

  let editData = [];
  clientData.forEach((ele) => {
    ele.conversionProbality = conversionProbality(ele.conversion_probality);

    editData.push(ele);
  });
  console.log("conversionProbality", editData);

  const data = editData;

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center form-group m-2">
        <h5 className="textPrimary text-uppercase">Prospect List</h5>
        <div>
          <button style={{ marginRight: "5px" }} className="primary">
            <CSVLink
              title="Export CSV File"
              className="mdi mdi-export text-decoration-none text-white"
              role="button"
              data={data}
              filename="prospect_list"
              headers={headers}
            ></CSVLink>
          </button>
          <button onClick={AddClientInfo} className="pl-1 primary">
            Add Prospect
          </button>
        </div>
      </div>
      {clientData?.length > 0 ? (
        clientData?.map((m, index) => (
          <Alert
            className="border rounded-0 m-2 p-2 d-flex justify-content-between align-items-center"
            key={index}
            variant="light"
          >
            <div style={{ width: "70%" }} className="d-flex flex-column">
              <span style={{ fontWeight: "600" }}>{m?.company_name}</span>
              <span style={{ fontWeight: "400" }}>
                {m?.contact_person_name}
              </span>
              <small style={{ fontSize: "10px", fontWeight: "600" }}>
                {moment(m.created_at).format("DD MMM  yyyy")}
              </small>
            </div>
            <div style={{ width: "30%" }}>
              <span
                title="View Client Information"
                role="button"
                onClick={() => onView(m)}
                style={{ fontSize: "25px", marginLeft: "5px" }}
                className="mdi mdi-eye text-warning"
              ></span>
              <span
                title="Edit Client Information"
                onClick={() => onEdit(m)}
                role="button"
                style={{ fontSize: "25px", marginLeft: "5px" }}
                className="mdi mdi-account-edit text-success"
              ></span>
              <span
                title="Delete Client Information"
                onClick={() => deleteClientInfoSubmit(m)}
                role="button"
                style={{ fontSize: "25px", marginLeft: "5px" }}
                className="mdi mdi-delete text-danger"
              ></span>
            </div>
          </Alert>
        ))
      ) : (
        <h6 className="textPrimary text-capitalize text-center">
          no history found
        </h6>
      )}

      <Modal show={show} fullscreen onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            className="a "
            onSubmit={isEditMode ? editClientInfoSubmit : clientInfoSubmit}
          >
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Form.Group
                  className="mb-3 mb-lg-2 "
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className=" textPrimary">
                    Company name *
                  </Form.Label>
                  <Form.Control
                    required
                    name="company_name"
                    value={clientDetails?.company_name}
                    className="rounded-0 "
                    type="text"
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3 mb-lg-2 "
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className=" textPrimary">
                    Contact person *
                  </Form.Label>
                  <Form.Control
                    required
                    name="contact_person_name"
                    value={clientDetails?.contact_person_name}
                    className="rounded-0"
                    type="text"
                    rows={3}
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Form.Group
                  className="mb-3 mb-lg-2 "
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className=" textPrimary">
                    Contact person Email *
                  </Form.Label>
                  <Form.Control
                    required
                    name="contact_person_email"
                    value={clientDetails?.contact_person_email}
                    className="rounded-0"
                    type="email"
                    rows={3}
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Form.Group>
                  <Form.Label className=" textPrimary">
                    Contact Number *
                  </Form.Label>
                  <Form.Control
                    required
                    name="contact_person_number"
                    value={clientDetails?.contact_person_number}
                    className="rounded-0"
                    type="tel"
                    maxLength="10"
                    rows={3}
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <h5 className="mt-3 mt-lg-0 mb-0">Secondary Contact details</h5>
            <hr className="mt-1" style={{ color: "#fc9200" }} />
            <Row>
              <Col xs={12} sm={12} md={6}>
                <Form.Group
                  className="mb-3 mb-lg-2 "
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className=" textPrimary">
                    Contact Name{" "}
                  </Form.Label>
                  <Form.Control
                    name="contact_name"
                    value={clientDetails?.contact_name}
                    className="rounded-0"
                    type="text"
                    rows={3}
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={6}>
                <Form.Group
                  className="mb-3 mb-lg-2 "
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className=" textPrimary">
                    Contact Email
                  </Form.Label>
                  <Form.Control
                    name="contact_email"
                    value={clientDetails?.contact_email}
                    className="rounded-0"
                    type="email"
                    rows={3}
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Form.Group>
                  <Form.Label className=" textPrimary">
                    Contact Number
                  </Form.Label>
                  <Form.Control
                    name="contact_number"
                    value={clientDetails?.contact_number}
                    className="rounded-0"
                    type="tel"
                    rows={3}
                    maxLength="10"
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>
              <h5 className="mt-3 "> Special Instructions</h5>
              <Col xs={12} sm={12} md={6}>
                <Form.Group
                  className="mb-3 mb-lg-2"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    name="special_instructions"
                    value={clientDetails?.special_instructions}
                    className="rounded-0"
                    as={"textarea"}
                    rows={3}
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>

              <h5>Estimates</h5>

              <Col xs={12} sm={12} md={6}>
                <Form.Group
                  className="mb-3 mb-lg-2 "
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className=" textPrimary">
                    Estimated Date of Closure
                  </Form.Label>
                  <Form.Control
                    name="estimated_date_of_closure"
                    value={clientDetails?.estimated_date_of_closure}
                    className="rounded-0"
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    rows={3}
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Form.Group>
                  <Form.Label className=" textPrimary">
                    Estimated Value of Deal
                  </Form.Label>
                  <Form.Control
                    name="estimated_value_of_deal"
                    value={clientDetails?.estimated_value_of_deal}
                    className="rounded-0"
                    type="number"
                    rows={3}
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Form.Group>
                  <Form.Label className=" textPrimary d-block">
                    Conversion Probability
                  </Form.Label>
                  <Form.Check
                    className="textPrimary"
                    inline
                    label=">75%"
                    name="conversion_probality"
                    defaultChecked={clientDetails?.conversion_probality == 1}
                    type="radio"
                    value={1}
                    onChange={clientHandleChange}
                  />
                  <Form.Check
                    className="textPrimary"
                    inline
                    label="50-75%"
                    name="conversion_probality"
                    type="radio"
                    defaultChecked={clientDetails?.conversion_probality == 2}
                    value={2}
                    onChange={clientHandleChange}
                  />
                  <Form.Check
                    className="textPrimary"
                    inline
                    label="<50%"
                    name="conversion_probality"
                    type="radio"
                    value={3}
                    defaultChecked={clientDetails?.conversion_probality == 3}
                    onChange={clientHandleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {isEditMode && (
              <div className="d-flex justify-content-between pt-3 pb-3">
                <button
                  type="button"
                  onClick={cancelBtn}
                  className="rounded-0 btn btn-secondary col-6"
                >
                  Cancel
                </button>
                <button type="submit" className="primary rounded-0 col-6">
                  Save
                </button>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ClientList;
