import axios, { Axios } from "axios";

export const API_URL = process.env.REACT_APP_API_URL;

export const getData = async (URL) => {
  const url = API_URL + URL;
  const tokenType = localStorage.getItem("token_type")
    ? localStorage.getItem("token_type")
    : "";
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const Authorization = `${tokenType} ${token}`;
  // console.log('url = API_URL + URL', url);

  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",
        Authorization,
      },
    });

    // console.log('Res1', response);

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("token_type");

      window.location.href = "/sales/login";
    }

    throw error;
  }
};
export const getDataToken = (URL) => {
  const url = API_URL + URL;
  return axios
    .get(url, {
      // signal,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: "2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn",
      },
    })
    .then((res) => res)
    .catch((err) => console.log(err));
  // fetch(url, {
  //   headers: {
  //     Authorization: '2P2mfbGMap5GRtjFhqTP5V85McgPT75BtmkNuRAn',
  //   },
  // })
  //   .then((res) => res)
  //   .catch((err) => console.log(err));
};

export const getMethod = (URL, signal) => {
  const url = API_URL + URL;
  const tokenType = localStorage.getItem("token_type")
    ? localStorage.getItem("token_type")
    : "";
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const Authorization = `${tokenType} ${token}`;
  return axios
    .get(url, {
      signal,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/sales/login";
      }

      throw error;
    });
};

export const postMethod = (URL, Data) => {
  const url = API_URL + URL;
  const tokenType = localStorage.getItem("token_type")
    ? localStorage.getItem("token_type")
    : "";
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const Authorization = `${tokenType} ${token}`;
  return axios
    .post(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",
        Authorization,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/sales/login";
      }

      throw error;
    });
};

export const updateMethod = async (URL, Data) => {
  const url = API_URL + URL;
  const tokenType = localStorage.getItem("token_type")
    ? localStorage.getItem("token_type")
    : "";
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const Authorization = `${tokenType} ${token}`;
  try {
    const response = await axios.put(url, Data, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",
        Authorization,
      },
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("token_type");

      window.location.href = "/sales/login";
    }

    throw error;
  }
};
export const deleteMethod = async (URL) => {
  const url = API_URL + URL;
  const tokenType = localStorage.getItem("token_type")
    ? localStorage.getItem("token_type")
    : "";
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const Authorization = `${tokenType} ${token}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",
        Authorization,
      },
    });

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("token_type");

      window.location.href = "/sales/login";
    }

    throw error;
  }
};

export const fileupload = async (URL, Data) => {
  const url = API_URL + URL;
  const tokenType = localStorage.getItem("token_type")
    ? localStorage.getItem("token_type")
    : "";
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const Authorization = `${tokenType} ${token}`;
  try {
    const response = await axios.post(url, Data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization,
      },
    });

    if (response.status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token_type");

      window.location.href = "/sales/login";
    }

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token_type");

      window.location.href = "/sales/login";
    }

    throw error;
  }
};
export const fileuploadUpdate = async (URL, Data) => {
  const url = API_URL + URL;
  const tokenType = localStorage.getItem("token_type")
    ? localStorage.getItem("token_type")
    : "";
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const Authorization = `${tokenType} ${token}`;
  try {
    const response = await axios.put(url, Data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        Authorization,
      },
    });

    if (response.status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token_type");

      window.location.href = "/sales/login";
    }

    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token_type");

      window.location.href = "/sales/login";
    }

    throw error;
  }
};

export const pdfDownload = async (URL, Data) => {
  const url = API_URL + URL;
  const tokenType = localStorage.getItem("token_type")
    ? localStorage.getItem("token_type")
    : "";
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const Authorization = `${tokenType} ${token}`;
  return axios
    .post(
      url,
      Data,
      {
        responseType: "arraybuffer",
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",

          Authorization,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("token_type");

        window.location.href = "/sales/login";
      }

      throw error;
    });
};
