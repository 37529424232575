import { Badge, ListGroup } from "react-bootstrap";
import { setDayWithOptions } from "date-fns/esm/fp";
import { confirmAlert } from "react-confirm-alert";
import CloseIcon from "@mui/icons-material/Close";

import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import StarBorderIcon from "@mui/icons-material/StarBorder";

import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { Helmet } from "react-helmet-async";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";
import { ToastBRH } from "../../ResusableComponents/toasts/ToastBRH";
import { TextInputBRH } from "../../ResusableComponents/forms/TextInputBRH";
import { SwichInputBRH } from "../../ResusableComponents/forms/SwichInputBRH";
import Scrollbar from "../../ResusableComponents/scrollbar/Scrollbar";
import Iconify from "../../ResusableComponents/iconify/Iconify";
import { AutoCompleteInputBRH } from "../../ResusableComponents/forms/AutoCompleteInputBRH";
import { LoadingButtonBRH } from "../../ResusableComponents/forms/LoadingButtonBRH";
import * as API from "../../API_SERVICES/ApiConfigs";
import { CommonAlert } from "../../ResusableComponents/toasts/CommonAlert";
import { routeNames } from "../../API_SERVICES/routeNames";

const WorkSpaceList = ({ propertyId, onChangeNext, onChange }) => {
  const IMAGE_API = "https://brhfiles.s3.ap-south-1.amazonaws.com/";
  const ref = useRef();

  const [selectedImages, setSelectedImages] = useState([]);
  const [openDialog, setOpendialog] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [AddResourceData, setAddResourceData] = useState({});
  const [workSpaceData, setWorkSpaceData] = useState({
    show: false,
    enableresourceStatus: 0,
    propertyData: {},
    activePropertyResources: [],
    resourceGroup: [],
    resourceList: [],
    resource_code: "",
    limit: 5,
    resourceData: [],
    files: [],

    imageFile: [],
    imageFloorFile: [],
    files_floor: [],
    floor_map_name: [],
    checkbool: "yes",
    calendarData: {},
    resourceid: 0,
    data: [],
    quantity_code: [],
    resourceimages: [],
    resource_quantity_data: [],
    eresource_quantity_data: [],
    files_name: [],
    sort_id: "",
    sortOrderData: [],
    isLoading: true,
    workspace: 0,
    image_id: 0,
    totalresource: 0,
    // add resource data
    resourceGrpObject: null,
    resource_group_id: "",
    capacity: "",
    quantity: "",
    quantityData: [],
    resourceCode: "",
    resourceName: "",
    description: "",
    isAdminBooking: 0,
    reqBooking: 0,
    meetingConfrance: 0,
    sortOrderObj: null,
    resourceId: "",
    // delete action
    //   deleteOpen: false,
    deleteData: null,
    floorImage: null,
    floorFileName: "",
    floorFileError: "",
  });
  const [deleteOpen, setdeleteOpen] = useState(false);
  const [draftopen, setDraftOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    API.getDataToken("api/resourcegroup?limit=500").then((res) => {
      if (res.data.status === "success") {
        const apiResourceGrpData = res.data.data.data;

        setWorkSpaceData((prevDetails) => ({
          ...prevDetails,
          resourceGroup: apiResourceGrpData,
        }));
      }
    });
    API.getData("api/resource/").then((result) => {
      if (result.data.status === "success") {
        setWorkSpaceData((prev) => ({
          ...prev,
          resourceList: result.data.data.data,
        }));
      }
    });
    API.getData("api/sortorder?limit=100").then((result) => {
      if (result) {
        setWorkSpaceData((prev) => ({
          ...prev,
          sortOrderData: result.data.data.data,
        }));
      }
    });
    if (propertyId) {
      API.getData(`api/property/${propertyId}`).then((result) => {
        setWorkSpaceData((prev) => ({
          ...prev,
          propertyData: result.data.data,
          floorImage: decodeURIComponent(result.data.data.floor_file),
        }));
      });

      API.getData(`api/propertyresources/${propertyId}`).then((result) => {
        if (result.data.success === "success") {
          setWorkSpaceData((prev) => ({
            ...prev,
            resourceData: result.data.data.filter((stat) => {
              if (stat.status !== 2) {
                return stat;
              }
              return false;
            }),
          }));

          totalResource(
            result.data.data.filter((stat) => {
              if (stat.status !== 2) {
                return stat;
              }
              return false;
            })
          );
          totalWorkspace(
            result.data.data.filter((stat) => {
              if (stat.status !== 2) {
                return stat;
              }
              return false;
            })
          );
        } else {
          setWorkSpaceData((prev) => ({ ...prev, show: true }));
        }
      });

      API.getData(`api/activepropertyresources/${propertyId}`).then(
        (result) => {
          if (result.data.success === "success") {
            setWorkSpaceData((prev) => ({
              ...prev,
              activePropertyResources: result.data.data,
            }));
          }
        }
      );
    }
  }, []);

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };
  const stringCheck = (string = "") => {
    return /\d/.test(string);
  };
  const handleResourceGroup = (event, newValue) => {
    const [name] = event.target.id.split("-");
    const value = newValue[name];
    const id = newValue.id;

    setWorkSpaceData((prevDetails) => ({
      ...prevDetails,
      [name]: newValue.resource_group_name,
      resourceGrpObject: newValue,
      resource_group_id: id,
    }));
    if (workSpaceData.capacity >= 1) {
      setWorkSpaceData((prev) => ({
        ...prev,
        //   capacity: event.target.value,
        resourceName:
          prev.capacity > 1
            ? `${prev.capacity} Seater ${prev.resourceGrpObject.resource_group_name}`
            : prev.resourceGrpObject.resource_group_name,
        resourceCode:
          prev.capacity > 1
            ? `${prev.resourceGrpObject.resource_group_code.trim()}${
                prev.capacity
              }`
            : prev.resourceGrpObject.resource_group_code,
      }));
    }
  };
  const handleSortOrder = (event, newValue) => {
    const [name] = event.target.id.split("-");
    const value = newValue[name];
    const id = newValue.id;

    setWorkSpaceData((prevDetails) => ({
      ...prevDetails,

      sortOrderObj: newValue,
      sort_id: id,
    }));
  };

  const handleChangeCapacity = (event, newValue) => {
    setWorkSpaceData((prev) => ({
      ...prev,
      capacity: event.target.value,
      resourceName:
        event.target.value >= 1
          ? `${event.target.value} Seater ${workSpaceData.resourceGrpObject.resource_group_name}`
          : "",
      resourceCode:
        event.target.value >= 1
          ? `${workSpaceData.resourceGrpObject.resource_group_code}${event.target.value}`
          : "",
      quantity: event.target.value < 1 ? "" : prev.quantity,
    }));
  };
  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };
  const redirectTo = () => {
    navigate("/masters/prospect-property/add");
  };
  const totalResource = (r) => {
    let totalResourceCount = 0;

    if (r != null) {
      r?.forEach((val) => {
        totalResourceCount += val.quantity;
      });
    }

    // Update the totalresource state and log it
    setWorkSpaceData((prev) => ({
      ...prev,
      totalresource: totalResourceCount,
    }));
  };
  const totalWorkspace = (w) => {
    let totalWorkSpaceCount = 0;

    if (w != null) {
      w?.forEach((val) => {
        totalWorkSpaceCount += val.quantity * val.capacity;
      });
    }

    // Update the totalresource state and log it
    setWorkSpaceData((prev) => ({ ...prev, workspace: totalWorkSpaceCount }));
  };

  const handleStatusUpdate = (e, value) => {
    e.preventDefault();

    if (e.target.checked === true) {
      value.status = 1;

      if (value.plans !== 0) {
        confirmAlert({
          message:
            "All the plans for this resource will be disabled, do you wish to continue?",

          buttons: [
            {
              label: "Yes",
              onClick: () => {
                API.updateMethod(`api/resource/${value.id}`, value).then(
                  (result) => {
                    if (result.status === 200) {
                      ToastBRH("success", result.data.message, "__", false);
                      API.getData(`api/propertyresources/${propertyId}`).then(
                        (result) => {
                          if (result.data.success === "success") {
                            setWorkSpaceData((prev) => ({
                              ...prev,
                              resourceData: result.data.data.filter((stat) => {
                                if (stat.status !== 2) {
                                  return stat;
                                }
                                return false;
                              }),
                            }));
                          } else {
                            setWorkSpaceData((prev) => ({
                              ...prev,
                              show: true,
                            }));
                          }
                        }
                      );
                      // localStorage.setItem("message", result.data.message);
                    }
                  }
                );
              },
            },
            {
              label: "No",
            },
          ],
        });
      } else {
        API.updateMethod(`api/resource/${value.id}`, value).then((result) => {
          if (result.status === 200) {
            ToastBRH("success", result.data.message, "__", false);
            API.getData(`api/propertyresources/${propertyId}`).then(
              (result) => {
                if (result.data.success === "success") {
                  setWorkSpaceData((prev) => ({
                    ...prev,
                    resourceData: result.data.data.filter((stat) => {
                      if (stat.status !== 2) {
                        return stat;
                      }
                      return false;
                    }),
                  }));
                } else {
                  setWorkSpaceData((prev) => ({ ...prev, show: true }));
                }
              }
            );
            // localStorage.setItem("message", result.data.message);
          }
        });
      }
    } else if (e.target.checked === false) {
      value.status = 0;
      if (value.plans !== 0) {
        confirmAlert({
          message: `All Resource and workspaces will be disabled, do you wish to continue?`,

          buttons: [
            {
              label: "Yes",
              onClick: () => {
                API.updateMethod(`api/resource/${value.id}`, value).then(
                  (result) => {
                    if (result.status === 200) {
                      ToastBRH("success", result.data.message, "__", false);
                      API.getData(`api/propertyresources/${propertyId}`).then(
                        (result) => {
                          if (result.data.success === "success") {
                            setWorkSpaceData((prev) => ({
                              ...prev,
                              resourceData: result.data.data.filter((stat) => {
                                if (stat.status !== 2) {
                                  return stat;
                                }
                                return false;
                              }),
                            }));
                          } else {
                            setWorkSpaceData((prev) => ({
                              ...prev,
                              show: true,
                            }));
                          }
                        }
                      );
                      // localStorage.setItem("message", result.data.message);
                    }
                  }
                );
              },
            },
            {
              label: "No",
            },
          ],
        });
      } else {
        API.updateMethod(`api/resource/${value.id}`, value).then((result) => {
          if (result.status === 200) {
            ToastBRH("success", result.data.message, "__", false);
            API.getData(`api/propertyresources/${propertyId}`).then(
              (result) => {
                if (result.data.success === "success") {
                  setWorkSpaceData((prev) => ({
                    ...prev,
                    resourceData: result.data.data.filter((stat) => {
                      if (stat.status !== 2) {
                        return stat;
                      }
                      return false;
                    }),
                  }));
                } else {
                  setWorkSpaceData((prev) => ({ ...prev, show: true }));
                }
              }
            );
            //  localStorage.setItem("message", result.data.message);
          }
        });
      }
    }
  };
  const handleDelete = () => {
    API.deleteMethod(`api/resource/${workSpaceData.deleteData.id}`).then(
      (result) => {
        if (result.data.status === "success") {
          //     this.setState({ alert: true, message: result.data.message });
          ToastBRH("success", result.data.message, "_", false);

          setWorkSpaceData((prev) => ({
            ...prev,
            resourceData: prev.resourceData.filter(
              (el) => el.id !== prev.deleteData.id
            ),
          }));
          setdeleteOpen(false);
          localStorage.setItem("message", result.message);
          // window.location.reload();
        } else {
          localStorage.setItem("message", result.message);
          // window.location.reload();
        }
      }
    );
  };
  const handleDeleteBack = () => {
    setdeleteOpen(false);
  };
  const handleDraftBack = () => {
    setDraftOpen(false);
  };
  useEffect(() => {
    totalResource(workSpaceData.resourceData);
  }, [workSpaceData.resourceData]);
  useEffect(() => {
    totalWorkspace(workSpaceData.resourceData);
  }, [workSpaceData.resourceData]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "resource_group_name",
        header: "Resource Group",
        //   sortDescFirst: true,
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <span>{cell.getValue().resource_group_name}</span>
            </>
          );
        },
      },
      {
        accessorKey: "resource_name",
        header: "Resource Name",
        size: 150,
      },
      {
        accessorKey: "resource_code",
        header: "Code",
        size: 150,
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        size: 150,
      },
      {
        accessorKey: "capacity",
        header: "Capacity",
        size: 150,
      },
      {
        accessorKey: "is_only_admin_booking",
        header: "Admin only",
        size: 150,

        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div>
                {cell.getValue() === 1 ? (
                  <span style={{ textAlign: "center", color: "green" }}>
                    &#10004;
                  </span>
                ) : (
                  <span style={{ textAlign: "center", color: "red" }}>
                    &#10008;
                  </span>
                )}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "is_only_request_booking",
        header: "Request Only",
        size: 150,
        Cell: ({ cell }) => {
          return (
            <>
              <div>
                {cell.getValue() === 1 ? (
                  <span style={{ textAlign: "center", color: "green" }}>
                    &#10004;
                  </span>
                ) : (
                  <span style={{ textAlign: "center", color: "red" }}>
                    &#10008;
                  </span>
                )}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Enable/Disable",
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div>
                <SwichInputBRH
                  name="Enable/diable"
                  // label="E/D"
                  value={cell.getValue() === 1}
                  handleChange={(e) => handleStatusUpdate(e, rowData)}
                />
              </div>
            </>
          );
        },
      },
    ],
    []
  );
  const handleOpen = (mode, row) => {
    setOpendialog(true);
    setDialogMode(mode);
    if (mode === "Edit") {
      API.getData(`api/propertyresourceimages/${row.id}`).then((res) => {
        if (res.data.status === "success") {
          setSelectedImages(res.data.data);
        }
      });
      setWorkSpaceData((prev) => ({
        ...prev,
        sort_id: row.sort_id,
        resourceId: row.id,
        //  totalresource: 0,
        resourceGrpObject: prev.resourceGroup.find(
          (i) => i.id === row.resource_group_id
        ),
        resource_group_id: row.resource_group_id,
        capacity: row.capacity,
        quantity: row.quantity,

        resourceCode: row.resource_code,
        resourceName: row.resource_name,
        description: row.description,
        isAdminBooking: row.is_only_admin_booking,
        reqBooking: row.is_only_request_booking,
        meetingConfrance: row.is_meeting_conf_room,
        sortOrderObj: prev.sortOrderData.find((i) => i.id === row.sort_id),
      }));
    }
  };
  const handleClose = () => {
    setOpendialog(false);
    setWorkSpaceData((prev) => ({
      ...prev,
      sort_id: "",

      resourceGrpObject: null,
      resource_group_id: "",
      capacity: "",
      quantity: "",
      quantityData: [],
      resourceCode: "",
      resourceName: "",
      description: "",
      isAdminBooking: 0,
      reqBooking: 0,
      meetingConfrance: 0,
      sortOrderObj: null,
    }));
    setSelectedImages([]);
  };
  const handleImageSelection = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };
  // const handleImageDelete = (index) => {
  //   const updatedImages = selectedImages.filter((_, i) => i !== index);
  //   setSelectedImages(updatedImages);
  // };
  const handleImageDelete = (image) => {
    API.deleteMethod(`api/propertyimages/${image.id}`).then((res) => {
      if (res.data.status === "success") {
        setSelectedImages((prevImages) =>
          prevImages.filter((i) => i.id !== image.id)
        );
      }
    });
  };
  const handleImageDeletenewAdded = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
    // API.deleteMethod(`api/propertyimages/${image.id}`).then((res) => {
    //   if (res.data.status === 'success') {
    //     setSelectedImages((prevImages) => prevImages.filter((i) => i.id !== image.id));
    //   }
    // });
  };
  const handleQuantityChnage = (e) => {
    /* eslint-disable no-plusplus */

    const quantityCode = Array.from({ length: e }, (_, i) => {
      const code = (i + 1).toString().padStart(3, "0");
      return `${workSpaceData.propertyData.property_code}${workSpaceData.resourceGrpObject.resource_group_code}${code}`;
    });

    const datas = quantityCode.map((x) => ({
      resource_type_code: x,
      status: 1,
    }));
  };
  const handleQuantity = (e) => {
    const inputValue = e.target.value;
    const numericValue = parseInt(inputValue, 10);
    setWorkSpaceData((prev) => ({ ...prev, quantity: e.target.value }));
  };
  const QuantityInc = (e) => {
    const numericValue = parseInt(workSpaceData.quantity, 10) + 1;
    setWorkSpaceData((prev) => ({ ...prev, quantity: numericValue }));
  };
  useEffect(() => {
    const numericValue = parseInt(workSpaceData.quantity, 10);
    const quantityCode = Array.from({ length: numericValue }, (_, i) => {
      const code = (i + 1).toString().padStart(3, "0");
      return `${workSpaceData.propertyData.property_code.replace(
        " ",
        ""
      )}${workSpaceData.resourceCode.replace("\r\n", "")}${code}`;
    });
    console.log(quantityCode);
    const data = quantityCode.map((x) => ({
      resource_type_code: x.replace(" ", ""),
      status: 1,
    }));
    setWorkSpaceData((prev) => ({
      ...prev,
      quantityData: data,
    }));
  }, [workSpaceData.quantity, workSpaceData.resourceCode]);
  const handlePropertyTabs = (tab) => {
    const tabsdata = {
      property_id: propertyId,
      tabs: tab,
    };

    API.postMethod("api/propertytabs", tabsdata).then((result) => {
      if (result.data.status === "success") {
        window.location.reload();
        // window.location.href = "/property-list";
      }
    });
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (selectedImages.length <= 0) {
      ToastBRH("error", "please upload atleast one image", "__", false);
      return;
    }
    const data = {
      property_id: propertyId,
      resource_group_id: workSpaceData.resource_group_id,
      resource_name: workSpaceData.resourceName,
      resource_code: workSpaceData.resourceCode,
      description: workSpaceData.description,
      quantity: workSpaceData.quantity,
      capacity: workSpaceData.capacity,
      sort_id: workSpaceData.sort_id,
      is_meeting_conf_room: workSpaceData.meetingConfrance,
      is_only_admin_booking: workSpaceData.isAdminBooking,
      is_only_request_booking: workSpaceData.reqBooking,
      status: 1,
      resource_quantity_data: workSpaceData.quantityData,
    };

    if (dialogMode === "Edit") {
      API.updateMethod(`api/resource/${workSpaceData.resourceId}`, data)
        .then((res) => {
          if (res.data.status === "success") {
            //  ToastBRH('success', res.data.message, '__', false);
            const formData = new FormData();
            // selectedImages &&
            //   selectedImages.map((value, index) => {
            //     formData.append('image_path[]', value);
            //     formData.append('thumbnail_path[]', value);
            //     formData.append('image_order[]', index);
            //   });
            if (selectedImages.length >= 1) {
              selectedImages?.map(
                (i) => !i.image_path && formData.append("image_path[]", i)
              );
              selectedImages?.map((i, index) =>
                formData.append("thumbnail_path[]", i)
              );
              selectedImages?.map((i, index) =>
                formData.append("image_order[]", index)
              );
              formData.append("property_id", res.data.data.property_id);
              formData.append("resource_id", res.data.data.id);
            } else {
              formData.append("image_path[]", []);
              formData.append("thumbnail_path[]", []);
              formData.append("image_order[]", []);
              formData.append("property_id", res.data.data.property_id);
              formData.append("resource_id", res.data.data.id);
            }
            API.fileupload("/api/resourceimages/", formData)
              .then((res) => {
                if (res.status === 200) {
                  //  this.setState({ show: false });
                  setOpendialog(false);
                  handleClose();
                  localStorage.setItem("message", res.data.message);
                  // localStorage.setItem("property_tabs", "plans");
                  ToastBRH("success", res.data.message, "__", false);
                  //  handlePropertyTabs('workspace');
                  API.getData(`api/propertyresources/${propertyId}`).then(
                    (result) => {
                      if (result.data.success === "success") {
                        //  this.setState({ resourceData: result.data });
                        //    setWorkSpaceData((prev) => ({ ...prev, resourceData: result.data.data }));
                        setWorkSpaceData((prev) => ({
                          ...prev,
                          resourceData: result.data.data.filter((stat) => {
                            if (stat.status !== 2) {
                              return stat;
                            }
                            return false;
                          }),
                        }));
                      }
                    }
                  );
                  // window.location.reload();
                } else {
                  localStorage.setItem("messageErr", res.data.message);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          ToastBRH("error", e, "__", false);
        });
    } else {
      API.postMethod("api/resource/", data)
        .then((result) => {
          if (result.status === 200) {
            const formData = new FormData();
            // selectedImages &&
            //   selectedImages.map((value, index) => {
            //     formData.append('image_path[]', value);
            //     formData.append('thumbnail_path[]', value);
            //     formData.append('image_order[]', index);
            //   });
            selectedImages?.map(
              (i) => !i.image_path && formData.append("image_path[]", i)
            );
            selectedImages?.map((i, index) =>
              formData.append("thumbnail_path[]", i)
            );
            selectedImages?.map((i, index) =>
              formData.append("image_order[]", index)
            );
            formData.append("property_id", result.data.data.property_id);
            formData.append("resource_id", result.data.data.id);

            API.fileupload("/api/resourceimages/", formData)
              .then((res) => {
                if (res.status === 200) {
                  //  this.setState({ show: false });
                  setOpendialog(false);
                  handleClose();
                  localStorage.setItem("message", result.data.message);
                  // localStorage.setItem("property_tabs", "plans");
                  ToastBRH("success", result.data.message, "__", false);
                  //  handlePropertyTabs('workspace');
                  API.getData(`api/propertyresources/${propertyId}`).then(
                    (result) => {
                      if (result.data.success === "success") {
                        //  this.setState({ resourceData: result.data });
                        //    setWorkSpaceData((prev) => ({ ...prev, resourceData: result.data.data }));
                        setWorkSpaceData((prev) => ({
                          ...prev,
                          resourceData: result.data.data.filter((stat) => {
                            if (stat.status !== 2) {
                              return stat;
                            }
                            return false;
                          }),
                        }));
                      }
                    }
                  );
                  // setOpendialog(false);
                  // window.location.reload();
                } else {
                  localStorage.setItem("messageErr", result.data.message);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const onToggleButton = (e) => {
    if (e.target.name === "admin_booking") {
      setWorkSpaceData((prev) => ({
        ...prev,
        reqBooking: 0,
        isAdminBooking: e.target.checked ? 1 : 0,
      }));
    } else if (e.target.name === "meeting_conference") {
      setWorkSpaceData((prev) => ({
        ...prev,
        meetingConfrance: e.target.checked ? 1 : 0,
      }));
    } else if (e.target.name === "request_booking") {
      setWorkSpaceData((prev) => ({
        ...prev,
        isAdminBooking: 0,
        reqBooking: e.target.checked ? 1 : 0,
      }));
    }
  };

  const handleFilesChange = (e, ref) => {
    const name = e.target.name;

    if (name === "floor_file") {
      const type = e.target.files[0].type.split("/");
      const { size } = e.target.files[0];
      const floorImageName = e.target.files[0].name;

      if (
        type[1] === "png" ||
        type[1] === "jpg" ||
        type[1] === "jpeg" ||
        type[1] === "pdf"
      ) {
        if (size >= 204800) {
          const floorFiles = e.target.files[0];

          setWorkSpaceData((prev) => ({
            ...prev,
            floorImage: floorFiles,

            floorFileName: floorImageName,
            floorFileError: "",
          }));
        } else {
          setWorkSpaceData((prev) => ({
            ...prev,
            floorFileError: "Image size should more than 200 KB",
          }));
        }
      } else {
        setWorkSpaceData((prev) => ({
          ...prev,
          floorFileError: "File type not valid",
        }));
      }
    }
  };

  const handleWorkSpace = () => {
    if (workSpaceData.resourceData.length <= 0) {
      ToastBRH("warning", "Please Add a Resource..", "__", false);
      return;
    }
    // if (
    //   workSpaceData.floorImage === "null" ||
    //   workSpaceData.floorImage === null
    // ) {
    //   ToastBRH("warning", "Please Upload a floor image..", "__", false);
    //   return;
    // }

    const formData = new FormData();
    if (workSpaceData.floorImage.name) {
      formData.append("floor_file", workSpaceData.floorImage);
      formData.append("property_id", propertyId);
      API.fileupload("api/propertyfloormap", formData).then((result) => {
        if (result.data.status === "success") {
          ToastBRH("success", result.data.message, "__", false);
          localStorage.setItem("property_tabs", "plans");
          //  handlePropertyTabs('plans');
          onChangeNext();
          //   window.location.reload();
          // localStorage.setItem("message", result.data.message);
          // // window.location.href = "/roles-list";
          // window.location.reload();
          // browserHistory.push("/roles-list");
        } else if (result.data.error === "error") {
          ToastBRH("error", result.message, "__", false);
          // this.setState({ messageErr: result.message });
        }
      });
    } else {
      onChangeNext();
      // window.location.reload();
    }
  };
  const handleDraft = () => {
    const tabsdata = {
      property_id: propertyId,
      tabs: "workspace",
    };
    if (workSpaceData.resourceData.length <= 0) {
      ToastBRH("warning", "Please Add a Resource..", "__", false);
      return;
    }
    if (
      workSpaceData.floorImage === "null" ||
      workSpaceData.floorImage === null
    ) {
      ToastBRH("warning", "Please Upload a floor image..", "__", false);
      return;
    }

    const formData = new FormData();
    if (workSpaceData.floorImage.name) {
      formData.append("floor_file", workSpaceData.floorImage);
      formData.append("property_id", propertyId);
      API.fileupload("api/propertyfloormap", formData).then((result) => {
        if (result.data.status === "success") {
          ToastBRH("success", result.data.message, "__", false);
          localStorage.setItem("property_tabs", "plans");
          //  handlePropertyTabs('plans');
          API.postMethod("api/propertytabs", tabsdata).then((result) => {
            if (result.data.status === "success") {
              ToastBRH("success", result.data.message, "___", false);
              navigate(routeNames.PROSPECTPROPERTYLIST);
            }
          });
          //   window.location.reload();
          // localStorage.setItem("message", result.data.message);
          // // window.location.href = "/roles-list";
          // window.location.reload();
          // browserHistory.push("/roles-list");
        } else if (result.data.error === "error") {
          ToastBRH("error", result.message, "__", false);
          // this.setState({ messageErr: result.message });
        }
      });
    } else {
      API.postMethod("api/propertytabs", tabsdata).then((result) => {
        if (result.data.status === "success") {
          ToastBRH("success", result.data.message, "___", false);
          navigate(routeNames.PROSPECTPROPERTYLIST);
        }
      });
      // window.location.reload();
    }

    // window.location.href = '/property-list';
  };
  const handlePrevious = () => {
    localStorage.setItem("property_tabs", "amenities");
    //   handlePropertyTabs('amenities');
    // window.location.reload();
    onChange();
  };
  return (
    <>
      <Helmet>
        <title> Sales | BRH </title>
      </Helmet>
      <Container>
        {/* <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/dashboard">
          Dashboard
        </Link>
        <Typography color="text.primary">CRS</Typography>
      </Breadcrumbs> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Work Space List
          </Typography>
          <Button
            onClick={() => handleOpen("Add")}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Resource
          </Button>
          <Dialog
            open={openDialog}
            onClose={handleClose}
            fullWidth={fullWidth}
            maxWidth="lg"
          >
            <form onSubmit={handleFormSubmit}>
              <DialogTitle
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: 600, fontSize: "25px" }}>
                  {dialogMode === "Add" ? "Add Resource" : "Edit Resource"}
                </span>
                <Tooltip arrow placement="left" title="close">
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </DialogTitle>
              <DialogContentText style={{ padding: "20px" }}>
                <FormControl sx={{ display: "flex" }}>
                  {/* <span style={{ fontWeight: 600, fontSize: '25px' }} className="py-3">
                    {dialogMode === 'Add' ? 'Add Resource' : 'Edit Resource'}
                  </span> */}
                  <Grid sx={{ display: " flex" }} container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <AutoCompleteInputBRH
                        label="Resource Group"
                        name="resource_group_name"
                        options={workSpaceData.resourceGroup}
                        value={workSpaceData.resourceGrpObject}
                        handleChange={handleResourceGroup}
                        size="medium"
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextInputBRH
                        label="Capacity"
                        name="capacity"
                        value={workSpaceData.capacity}
                        handleChange={handleChangeCapacity}
                        required
                        size="medium"
                        disable={workSpaceData.resourceGrpObject === null}
                      />
                      {workSpaceData.resourceGrpObject === null ? (
                        <span style={{ color: "red" }}>
                          {" "}
                          * select resource group{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextInputBRH
                        label="Quantity"
                        name="quantity"
                        value={workSpaceData.quantity}
                        handleChange={handleQuantity}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextInputBRH
                        label="Resource Code"
                        name="resource_code"
                        value={workSpaceData.resourceCode}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextInputBRH
                        label="Resource Name"
                        name="resource_name"
                        value={workSpaceData.resourceName}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <AutoCompleteInputBRH
                        label="Sort Order"
                        name="sort_order"
                        size="medium"
                        options={workSpaceData.sortOrderData}
                        value={workSpaceData.sortOrderObj}
                        handleChange={handleSortOrder}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextInputBRH
                        label="Description"
                        name="description"
                        multiline
                        rows={3}
                        value={workSpaceData.description}
                        handleChange={(e) =>
                          setWorkSpaceData((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                    </Grid>

                    <Grid
                      container
                      className="d-flex flex-column"
                      xs={12}
                      sm={12}
                      md={4}
                    >
                      <Grid item className="p-4">
                        <SwichInputBRH
                          name="admin_booking"
                          label="Admin Only Booking"
                          value={workSpaceData.isAdminBooking === 1}
                          handleChange={onToggleButton}
                        />
                      </Grid>
                      <Grid item className="p-4">
                        <SwichInputBRH
                          name="meeting_conference"
                          label="Meeting or Conference"
                          value={workSpaceData.meetingConfrance === 1}
                          handleChange={onToggleButton}
                        />
                      </Grid>
                      <Grid item className="p-4">
                        <SwichInputBRH
                          name="request_booking"
                          label="Request Only Booking"
                          value={workSpaceData.reqBooking === 1}
                          handleChange={onToggleButton}
                        />
                      </Grid>
                    </Grid>
                    {workSpaceData.quantityData.length >= 1 && (
                      <Grid
                        container
                        className="d-flex flex-column  pl-2"
                        xs={12}
                        sm={12}
                        md={4}
                      >
                        <Typography
                          variant="h6"
                          color="secondary"
                          sx={{
                            paddingRight: "10px",
                            alignSelf: "end",
                            cursor: "pointer",
                          }}
                          onClick={() => QuantityInc()}
                        >
                          Add Resource
                        </Typography>

                        <ListGroup
                          style={{
                            height:
                              workSpaceData.quantityData.length >= 4 &&
                              "30.5vh",
                            overflowY:
                              workSpaceData.quantityData.length >= 4 &&
                              "scroll",
                            paddingLeft: "25px",
                          }}
                        >
                          {workSpaceData.quantityData &&
                            workSpaceData.quantityData.map((item, index) => (
                              <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                {" "}
                                <Typography variant="body1">
                                  {item.resource_type_code}
                                </Typography>
                                <SwichInputBRH
                                  name={`quantity${index}`}
                                  labelStart="true"
                                  value={item.status}
                                  // handleChange={onToggleButton}
                                />
                              </ListGroup.Item>
                            ))}
                        </ListGroup>
                      </Grid>
                    )}
                  </Grid>
                  <Grid sx={{ display: " flex" }}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Button
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          padding: 0,
                        }}
                        component="label"
                      >
                        <CloudUploadIcon size={50} />
                        Upload Property Images
                        <input
                          type="file"
                          hidden
                          multiple
                          onChange={handleImageSelection}
                        />
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "40vw",
                          height: selectedImages.length >= 1 ? "30vh" : "",
                          overflowY: selectedImages.length > 3 ? "scroll" : "",
                          //  overflow: 'hidden',
                          // '&::-webkit-scrollbar': {
                          //   display: 'none',
                          // },
                          //   border: '1px solid #ccc',
                          //   padding: '5px',
                          //   height: '10rem',
                        }}
                      >
                        {/* {selectedImages &&
                          selectedImages.map((image, index) => (
                            <div key={index} style={{ marginRight: '10px' }}>
                              <IconButton onClick={() => handleImageDelete(index)}>
                                <ClearIcon />
                              </IconButton>
                              <img
                                src={URL.createObjectURL(image)}
                                alt={`Preview ${index}`}
                                style={{ width: 'auto', height: '100px', marginRight: '10px' }}
                              />
                            </div>
                          ))} */}
                        {selectedImages?.map((image, index) =>
                          image.image_path ? (
                            <div key={index}>
                              <IconButton
                                onClick={() => handleImageDelete(image)}
                              >
                                <ClearIcon />
                              </IconButton>
                              <img
                                // src={URL.createObjectURL(IMAGE_API+'images/properties/property-images/image/1655296974_1.jpg')}
                                src={`${IMAGE_API}${image.image_path}`}
                                alt={`Preview ${index}`}
                                style={{
                                  width: "auto",
                                  height: "100px",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                          ) : (
                            <div key={index}>
                              <IconButton
                                onClick={() => handleImageDeletenewAdded(index)}
                              >
                                <ClearIcon />
                              </IconButton>
                              <img
                                src={URL.createObjectURL(image)}
                                //  src={`${IMAGE_API}${image.image_path}`}
                                alt={`Preview ${index}`}
                                style={{
                                  width: "auto",
                                  height: "100px",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </FormControl>
              </DialogContentText>
              <DialogActions sx={{ marginBottom: "16px" }}>
                <Box sx={{ marginLeft: "8px" }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleClose()}
                    size="medium"
                    sx={{ padding: "8px 16px" }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box sx={{ marginLeft: "16px" }}>
                  <LoadingButtonBRH
                    type="submit"
                    variant="contained"
                    label={dialogMode}
                  />
                </Box>
              </DialogActions>
            </form>
          </Dialog>
        </Stack>
        <Card sx={{ p: 2 }}>
          <MaterialReactTable
            columns={columns}
            data={workSpaceData.resourceData}
            enableRowActions
            enableColumnActions={false}
            initialState={{
              sorting: [
                // sort by state in ascending order by default
                // { id: 'resource_group_name', desc: true }, // then sort by city in descending order by default
                { id: "resource_name", desc: true }, // then sort by city in descending order by default
              ],
            }}
            muiTableBodyCellProps={{
              sx: {
                textAlign: "center",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                textAlign: "center",
              },
              align: "center",
            }}
            positionActionsColumn={"last"}
            renderRowActions={({ row, table }) => (
              <Box className="d-flex flex-row justify-content-start p-0 ">
                <Tooltip arrow placement="left" title="Edit">
                  <IconButton
                    sx={{
                      width: 36,
                      height: 38,
                      border: 0.6,
                      borderColor: "#F36E24",
                      "& .MuiSvgIcon-root": {
                        fill: "#F36E24",
                      },
                      marginRight: "10px",
                    }}
                    onClickCapture={() => handleOpen("Edit", row.original)}
                  >
                    <EditIcon
                      sx={{ fontSize: 14 }}
                      //  onClick={() => editUser(row)}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip arrow placement="right" title="Delete">
                  <IconButton
                    sx={{
                      width: 36,
                      height: 38,
                      border: 0.5,
                      borderColor: "#F36E24",
                      "& .MuiSvgIcon-root": {
                        fill: "#F36E24",
                      },
                    }}
                    onClick={() => {
                      setdeleteOpen(true);
                      setWorkSpaceData((prev) => ({
                        ...prev,
                        deleteData: row.original,
                      }));
                    }}
                  >
                    <DeleteIcon
                      sx={{ fontSize: 14 }}
                      //  onClick={() => handleDeleteRow(row)}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />

          <div className="d-flex flex-row justify-content-around pt-3">
            <span>
              Total Number of Resources:{" "}
              <span
                style={{
                  color: "white",
                  backgroundColor: "#fc9200",
                  borderRadius: 5,
                }}
                className="px-3 py-1"
              >
                {workSpaceData.totalresource}
              </span>
            </span>
            <span>
              Total Number of Workspace:{" "}
              <span
                style={{
                  color: "white",
                  backgroundColor: "#fc9200",
                  borderRadius: 5,
                }}
                className="px-3 py-1"
              >
                {workSpaceData.workspace}
              </span>
            </span>
          </div>
        </Card>
      </Container>
      <Button
        sx={{
          display: "flex",
          gap: "10px",
          marginLeft: "55px",
          width: "20%",
          alignSelf: "start",
          justifyContent: "flex-start",
          mt: 4,
          fontSize: "20px",
        }}
        component="label"
      >
        <CloudUploadIcon size={70} />
        Upload Floor Map
        <input
          name="floor_file"
          id="floor_file_id"
          type="file"
          hidden
          multiple
          onChange={(e) => handleFilesChange(e)}
        />
      </Button>
      <span
        style={{
          display: "flex",
          gap: "10px",
          marginLeft: "70px",
          width: "20%",
          alignSelf: "start",
          justifyContent: "flex-start",
          //  margui: 4,
          fontSize: "12px",
        }}
      >
        {workSpaceData.floorFileError !== "" ? (
          <>
            {" "}
            <span style={{ color: "red" }}>
              *{workSpaceData.floorFileError}
            </span>
          </>
        ) : workSpaceData.floorImage?.type === "image/jpeg" ? (
          <img
            // src={URL.createObjectURL(workSpaceData.floorImage)}
            src={URL.createObjectURL(workSpaceData.floorImage)}
            alt={`Preview `}
            style={{ width: "auto", height: "100px", marginRight: "10px" }}
          />
        ) : workSpaceData.floorImage?.name?.includes("pdf") ? (
          <span>{workSpaceData.floorImage?.name}</span>
        ) : workSpaceData.floorImage === "null" ? (
          ""
        ) : (
          <img
            // src={URL.createObjectURL(workSpaceData.floorImage)}
            src={`${IMAGE_API}${workSpaceData.floorImage}`}
            alt={`Preview 2`}
            style={{ width: "auto", height: "100px", marginRight: "10px" }}
          />
        )}
      </span>
      <Grid
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "flex-end",
          marginTop: "30px",
          marginRight: "65px",
        }}
        item
        xs={12}
        sm={12}
        md={6}
      >
        {/* <Button sx={{ height: '2.5rem', width: 150, mx: 1 }} variant="contained">
                          Cancel
                        </Button> */}
        {/* <LoadingButtonBRH type="submit" variant="containeds" label="Save" color="grey" />
          <LoadingButtonBRH type="submit" variant="containeds" label="Save" /> */}
        <Button
          type="button"
          sx={{
            height: "2.5rem",
            width: 150,
            mx: 1,
            backgroundColor: "grey",
            color: "white",
            ":hover": { color: "#fc9200", backgroundColor: "#fff7cd" },
          }}
          onClick={() => navigate(routeNames.PROSPECTPROPERTYLIST)}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          type="button"
          sx={{
            height: "2.5rem",
            width: 150,
            mx: 1,
            backgroundColor: "grey",
            color: "white",
            ":hover": { color: "#fc9200", backgroundColor: "#fff7cd" },
          }}
          onClick={() => handlePrevious()}
          variant="contained"
        >
          Previous
        </Button>
        {workSpaceData.propertyData.is_draft === 1 ? (
          <Button
            sx={{
              height: "2.5rem",
              width: 150,
              mx: 1,
              backgroundColor: "grey",
              color: "white",
              ":hover": { color: "#fc9200", backgroundColor: "#fff7cd" },
            }}
            //  color="grey"
            variant="contained"
            onClick={() => setDraftOpen(true)}
          >
            Save as Draft
          </Button>
        ) : (
          ""
        )}
        {localStorage.getItem("verified") === "1" ||
        localStorage.getItem("verified") === "2" ? (
          <LoadingButtonBRH
            variant="containeds"
            label="Update"
            handleSubmit={handleWorkSpace}
          />
        ) : (
          <LoadingButtonBRH
            variant="containeds"
            label="Save & Next"
            handleSubmit={handleWorkSpace}
          />
        )}
      </Grid>
      <CommonAlert
        open={deleteOpen}
        setKycOpen={setdeleteOpen}
        message="Are you sure you want to delete this row?"
        proceedFunction={handleDelete}
        goBackFunction={handleDeleteBack}
      />
      <CommonAlert
        open={draftopen}
        setKycOpen={setDraftOpen}
        message="All Workspaces will be save as draft, do you wish to Proceed ?"
        proceedFunction={handleDraft}
        goBackFunction={handleDraftBack}
      />
    </>
  );
};

export default WorkSpaceList;
