import { FormControl, MenuItem, TextField } from "@mui/material";
import React from "react";

export const TextFieldSelectBRH = ({
  label,
  value,
  name,
  options,
  renderLabel,
  renderValue,
  handleChange,
  required,
  size = "medium",
}) => (
  <FormControl fullWidth>
    <TextField
      select
      id={label}
      label={label}
      key={value}
      name={name}
      value={value}
      onChange={handleChange}
      required={required}
      size={size}
    >
      {options?.map((op) => (
        <MenuItem key={op[renderValue]} value={op[renderValue]}>
          {op[renderLabel]}
        </MenuItem>
      ))}
    </TextField>
  </FormControl>
);
