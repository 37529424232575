import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedTab: "propertyDetails",
};

export const TabsSelectionSlice = createSlice({
  name: "TabSelection",
  initialState,
  reducers: {
    TabSelection: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { TabSelection } = TabsSelectionSlice.actions;

export default TabsSelectionSlice.reducer;
