import { FormControl, InputAdornment, TextField } from '@mui/material';
import React from 'react';

export const GmailInputBRH = ({ name, label, value, handleChange, sx, required, size, readOnly, disabled, key }) => {
  return (
    <>
      <FormControl fullWidth>
        <TextField
          label={label}
          name={name}
          value={value}
          onChange={handleChange}
          required={required}
          sx={sx}
          variant="outlined"
          type="email"
          size={size}
          readOnly={readOnly}
          disabled={disabled}
          key={key ?? label}
          InputLabelProps={{ shrink: value }}
        />
      </FormControl>
    </>
  );
};
