import React, { useEffect, useState } from "react";
import { Badge, Col, Container, Form, Image, Row } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Api from "../../API_SERVICES/ApiServices";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { Range, getTrackBackground } from "react-range";
import toast from "react-simple-toasts";
import { useDispatch, useSelector } from "react-redux";
import {
  onQueryData,
  onRemoveSelected,
  onSearchData,
  onSelectedOptions,
} from "../options/OptionsSlice";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FaAngleUp } from "react-icons/fa";

import airplane from "../../assets/images/email_images/airport.png";
import bus from "../../assets/images/email_images/bus.png";
import train from "../../assets/images/email_images/RlywSt (bLUE).png";
import moment from "moment";

const MySwal = withReactContent(Swal);

const IMG_URL = process.env.REACT_APP_IMAGE_URL;

const SearchPage = (props) => {
  const PRICESTEP = 100;
  const PRICEMIN = 100;
  const PRICEMAX = 200000;

  const [loader, setLoader] = useState(false);

  const [price, setPrice] = useState([100, 200000]);
  const [cardIndex, setCardIndex] = useState(null);
  const [address, setaddress] = useState("");
  const [coordinates, setCoordinates] = useState({});
  const [toggle, setToggle] = useState(true);
  const [moreInfo, setMoreInfo] = useState(false);
  const [toggleSpace, setToggleSpace] = useState(false);
  const [amenitiesToggle, setAmenitiesToggle] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [resourceForMeeting, setResourcesForMeeting] = useState([]);
  const [resourceForWorkspace, setResourcesForWorkspace] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [propertySearchData, setPropertySearchData] = useState([]);
  const [amenitySearchData, setAmenitySearchData] = useState([]);
  const [meetingSpaceSearchData, setMeetingSpaceSearchData] = useState([]);
  const [workspaceSpaceSearchData, setWorkspaceSpaceSearchData] = useState([]);
  // const [properties, setProperties] = useState([]);
  const [propertyInfo, setPropertyInfo] = useState([]);
  const [officeSpaces, setOfficeSpaces] = useState([]);

  var heading = ["SPACE", "C", "TQ", "A", "Price/m"];

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const dispatch = useDispatch();
  const properties = useSelector((state) => state?.optionReduser?.optionsData);

  const priceFormat = (str) => parseInt(str).toLocaleString("en-IN");

  const user = JSON.parse(localStorage.getItem("user"));

  const localSearchData = JSON.parse(sessionStorage.getItem("search_data"));
  const locationName = sessionStorage.getItem("location");
  const localPropertyTypeData = JSON.parse(
    sessionStorage.getItem("property_type_data")
  );

  const localAmenitiesData = JSON.parse(
    sessionStorage.getItem("amenities_data")
  );
  const localWorkspaceData = JSON.parse(
    sessionStorage.getItem("workspace_space_resource_id")
  );
  const localMeetingSpace = JSON.parse(
    sessionStorage.getItem("meeting_space_resource_id")
  );

  let wp = resourceForWorkspace.filter((m) => m.isCheck === true);
  let mp = resourceForMeeting.filter((m) => m.isCheck === true);
  let ame = amenities.filter((m) => m.isCheck === true);
  let prop = propertySearchData.filter((m) => m.isCheck === true);
  wp = wp.map((m) => m.id);
  mp = mp.map((m) => m.id);
  ame = ame.map((m) => m.id);
  prop = prop.map((m) => m.id);

  const history = useNavigate();

  const locationChange = (value) => {
    // console.log("locationChange", value);

    setaddress(value);
  };

  useEffect(() => {
    if (properties.length !== 0) {
      console.log("Pr", properties.length != 0);
      setToggle(false);
    }
  }, []);

  const handleLocation = (value) => {
    geocodeByAddress(value)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        //  console.log("res", latLng);
        setaddress(value);
        setCoordinates({
          ...coordinates,
          latitude: latLng.lat,
          longitude: latLng.lng,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setSearchData({ ...searchData, [name]: value });
    if (name === "is_workspace" && checked === true) {
      setSearchData({ ...searchData, [name]: 1, is_meeting_space: 0 });
      let resWorkspace = [...resourceForWorkspace];
      resWorkspace.forEach((ele) => {
        ele.isCheck = true;
      });
      setResourcesForWorkspace(resWorkspace);

      //console.log("resWorkspace", resWorkspace);
    }
    if (name === "is_workspace" && checked === false) {
      setSearchData({ ...searchData, [name]: 0 });
      let resWorkspace = [...resourceForWorkspace];
      resWorkspace.forEach((ele) => {
        ele.isCheck = true;
      });
      setResourcesForWorkspace(resWorkspace);
      //console.log("resWorkspace", resWorkspace);
    }
    if (name === "is_meeting_space" && checked === true) {
      setSearchData({ ...searchData, [name]: 1, is_workspace: 0 });

      let resMeeting = [...resourceForMeeting];
      resMeeting.forEach((ele) => {
        ele.isCheck = true;
      });
      setResourcesForMeeting(resMeeting);

      //  console.log("resMeeting", resMeeting);
    }
    if (name === "is_meeting_space" && checked === false) {
      setSearchData({ ...searchData, [name]: 0 });
      let resMeeting = [...resourceForMeeting];
      resMeeting.forEach((ele) => {
        ele.isCheck = false;
      });
      setResourcesForMeeting(resMeeting);
      console.log("resMeeting", resMeeting);
    }
    if (name === "selectall" && checked === true) {
      setSearchData({ ...searchData, [name]: 1 });
      let ams = [...amenities];
      ams.forEach((ele) => {
        ele.isCheck = true;
      });
      setAmenities(ams);
      // console.log("Ames", ams);
    }
    if (name === "selectall" && checked === false) {
      setSearchData({ ...searchData, [name]: 0 });
      let ams = [...amenities];
      ams.forEach((ele) => {
        ele.isCheck = false;
      });
      setAmenities(ams);
      // console.log("Ames", ams);
    }
    if (name === "is_brh_center" && checked === true) {
      setSearchData({ ...searchData, [name]: 1 });
    }
    if (name === "is_brh_center" && checked === false) {
      setSearchData({ ...searchData, [name]: 0 });
    }
    if (name === "is_partner_centers" && checked === true) {
      setSearchData({ ...searchData, [name]: 1 });
    }
    if (name === "is_partner_centers" && checked === false) {
      setSearchData({ ...searchData, [name]: 0 });
    }
  };

  useEffect(() => {
    //console.log({ searchData });
  }, [searchData]);

  const saveFilter = () => {
    if (
      coordinates.latitude === undefined &&
      coordinates.longitude === undefined
    ) {
      Swal.fire("Location is Mandatory");
    } else {
      MySwal.fire({
        input: "text",
        inputPlaceholder: "Filter Name",
        inputAttributes: {
          autocapitalize: "on",
        },
        showCancelButton: true,
        confirmButtonText: "Save",
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          //  console.log({ login });
          let data = {
            user_id: user?.id,
            client_id: null,
            filter_name: login,
            latitude: String(coordinates?.latitude),
            longitude: String(coordinates?.longitude),
            no_of_seat: Number(searchData?.seats),
            brh_managed_centre: searchData?.is_brh == 0 ? 1 : 0,
            partner_centre: searchData?.is_brh == 1 ? 1 : 0,
            is_work_space: searchData?.is_work_space === 1 ? 1 : 0,
            is_meeting_space: searchData?.is_meeting_space === 1 ? 1 : 0,
            resource_group_id: searchData?.is_meeting_space === 1 ? mp : wp,
            is_amenities: ame ? 1 : 0,
            amenity_id:
              searchData?.selectall === 1 ? amenities.map((m) => m.id) : ame,
            min_price: price[0],
            max_price: price[1],
            inventory_status: Number(searchData?.status),
            start_date: searchData?.start_date,
            month: Number(searchData?.no_of_months),
            status: 1,
          };

          Api.postMethod("api/post-option-filter", "POST", data)
            .then((res) => res.json())
            .then((result) => console.log("RESU", result))
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !MySwal.isLoading(),
      }).then((result) => {
        //  console.log(result);
        if (result.isConfirmed) {
          MySwal.fire({
            title: `success`,
          });
        }
      });
    }
  };

  const reset = () => {
    Swal.fire({
      title: "Do you want to reset",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes,Reset!",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  };

  const handleChangeForPropertyType = (e, i, index) => {
    let data = [...propertyTypes];
    data[index].isCheck = e.target.checked;

    const { name, value } = e.target;
    if (e.target.checked === true) {
      setPropertySearchData(data);
      //  console.log("Data", data);
    }
    if (e.target.checked === false) {
      setPropertySearchData(propertySearchData.filter((p) => p.id !== data.id));
    }
  };

  const handleChangeForAmenities = (e, i, index) => {
    let data = [...amenities];
    setSearchData({ ...searchData, selectall: 0 });

    const { name, value } = e.target;

    data[index].isCheck = e.target.checked;
    if (e.target.checked === true) {
      setAmenitySearchData(data);
      setAmenities(data);
    }
    if (e.target.checked === false) {
      setAmenitySearchData(amenitySearchData.filter((a) => a.id !== value.id));
      setAmenities(data);
    }
  };
  const handleChangeForMeetingSpace = (e, i, index) => {
    let data = [...resourceForMeeting];
    data[index].isCheck = e.target.checked;
    const { name, value } = e.target;

    if (e.target.checked === true) {
      setMeetingSpaceSearchData(data);
      setResourcesForMeeting(data);
      //   console.log("Data", data);
    }
    if (e.target.checked === false) {
      // setMeetingSpaceSearchData(
      //   meetingSpaceSearchData.filter((m) => m.id !== value.id)
      // );
      setResourcesForMeeting(data);
    }
  };
  const handleChangeForWorkspace = (e, i, index) => {
    let data = [...resourceForWorkspace];
    data[index].isCheck = e.target.checked;
    const { name, value } = e.target;
    //  console.log("Clicked", i);
    if (e.target.checked === true) {
      setWorkspaceSpaceSearchData(data);
      setResourcesForWorkspace(data);
    }
    if (e.target.checked === false) {
      setWorkspaceSpaceSearchData(data);
    }
  };

  const searchOptions = {
    types: ["(regions)"],
    componentRestrictions: { country: "in" },
  };

  //useEffect(() => {}, [propertyTypes]);

  useEffect(() => {
    //console.log(searchData);
  }, [searchData]);
  useEffect(() => {
    // console.log("amenities", amenities);
  }, [amenities]);
  useEffect(() => {
    //  console.log("workspaceSpaceSearchData", workspaceSpaceSearchData);
  }, [workspaceSpaceSearchData]);

  useEffect(() => {
    _getPropTypes();
    _getResourcesForMeeting();
    _getResourcesForWorkSpace();
    _getAmenities();
  }, []);

  const _getPropTypes = async () => {
    await Api.getData("api/propertytype")
      .then((response) => response.json())
      .then((data) => {
        let bArr = [];
        if (localPropertyTypeData) {
          localPropertyTypeData?.forEach((element) => {
            let arr = data.data.data?.filter(
              (item) => element.isCheck === true && element.id === item.id
            );
            if (arr.length > 0) {
              element.isCheck = true;
              bArr.push(element);
            } else {
              element.isCheck = false;
              bArr.push(element);
            }
          });
          setPropertyTypes(bArr);

          //console.log("setPropertyTypes", bArr);
        } else {
          setPropertyTypes(
            data.data.data,
            data.data.data.map((m) => (m.isCheck = false))
          );
        }
      });
  };

  function _getResourcesForWorkSpace() {
    Api.getData("api/resourcegroup")
      .then((response) => response.json())
      .then((data) => {
        let bArr = [];
        if (localWorkspaceData) {
          localWorkspaceData?.forEach((element) => {
            let arr = data.data.data?.filter(
              (item) => element.isCheck === true && element.id === item.id
            );
            if (arr.length > 0) {
              element.isCheck = true;
              bArr.push(element);
            } else {
              element.isCheck = false;
              bArr.push(element);
            }
          });
          //  console.log("Brr", bArr);
          setResourcesForWorkspace(bArr);

          // console.log("setResourcesForWorkspace", bArr);
        } else {
          let bArr = [];
          bArr = data.data.data.filter(
            (d) =>
              d.id === 16 ||
              d.id === 8 ||
              d.id === 13 ||
              d.id === 10 ||
              d.id === 5 ||
              d.id === 9 ||
              d.id === 12 ||
              d.id === 14 ||
              d.id === 4
          );
          //    console.log("BARRR", bArr);
          setResourcesForWorkspace(
            bArr,
            data.data.data.map((m) => (m.isCheck = false))
          );
        }
      });
  }

  const _getResourcesForMeeting = async () => {
    await Api.getData("api/meeting-resource-group")
      .then((response) => response.json())
      .then((data) => {
        let meetingResourcesTypes = data.results;

        setResourcesForMeeting(meetingResourcesTypes);
      });
  };

  const _getAmenities = async () => {
    await Api.getData("api/amenities")
      .then((response) => response.json())
      .then((data) =>
        setAmenities(
          data.data.data,
          data.data.data.map((m) => (m.isCheck = false))
        )
      );
  };

  useEffect(() => {
    //console.log("amenities", amenities);
  }, [amenities]);

  const _getProperties = async (page = 1) => {
    let formData = {
      address: address,
      latitude: String(coordinates?.latitude),
      longitude: String(coordinates?.longitude),
      seats: Number(searchData?.seats),
      property_type_id:
        searchData?.is_partner_centers === 1 && searchData?.is_brh_center === 1
          ? [1, 2, 3, 4]
          : searchData?.is_brh_center === 1
          ? [4]
          : searchData?.is_partner_centers === 1
          ? [2, 3, 1]
          : null,
      is_meeting_space: searchData?.is_meeting_space === 1 ? 1 : 0,
      //is_workspace: searchData?.is_workspace === 1 ? 1 : null,
      //type_property: prop,
      resource_group_id: searchData?.is_meeting_space === 1 ? mp : wp,
      amenity_id: ame,
      min_price: price[0],
      max_price: price[1],
      status: Number(searchData?.status),
      start_date: searchData?.start_date,
      // months: Number(searchData?.no_of_months),
    };

    setLoader(true);

    // console.log("FormData", formData);

    // return;

    await Api.postMethod("api/get-option-list?page=" + page, "POST", formData)
      .then((res) => res.json())

      .then((result) => {
        if (result.status === "success") {
          let { data, to, total } = result.data;
          const { current_page: currentPage, last_page: lastPage } =
            result.data;

          const newData = page > 1 ? [...properties.data, ...data] : data;
          dispatch(onQueryData(formData));
          dispatch(
            onSearchData({
              currentPage,
              data: newData,
              lastPage,
              to,
              total,
            })
          );
          // setProperties({
          //   currentPage,
          //   data: newData,
          //   lastPage,
          //   to,
          //   total,
          // });
          setLoader(false);

          setToggle(false);
          // history("/sales/options", { state: data });
        } else {
          toast(result.message, 3000);
          setToggle(true);
          setLoader(false);
          dispatch(
            onSearchData({
              currentPage: 1,
              data: [],
              lastPage: 0,
              to: 0,
              total: 0,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInfo = async (data, index, val) => {
    console.log("Data", data);

    await Api.postMethod("api/get-option-info", "POST", {
      property_id: data?.id,

      resource_id: data?.resource_id,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          setPropertyInfo(result.data[0]);
          console.log("RES", result.data[0]);

          console.log("Test", result.data[0]?.international_airport?.distance);

          setOfficeSpaces(result.data[0]?.resource);
          setCardIndex(index);
          if (data.id === result.data[0].id && val === "more") {
            setMoreInfo(true);
          } else {
            setMoreInfo(false);
          }
        }
      })
      .catch((err) => {
        toast(err);
      });
  };

  const formSubmit = (e) => {
    e.preventDefault();

    if (
      coordinates.latitude === undefined &&
      coordinates.longitude === undefined
    ) {
      Swal.fire("Location is Mandatory");
    } else {
      _getProperties();
      setToggle(!toggle);
    }
  };

  const selectedData = useSelector((state) => state?.optionReduser);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [height, width]);

  const handleAdd = (data) => {
    if (selectedData.selectedData.find((sel) => sel.id === data.id)) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to remove this option",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Removed!",
            "Your option has been removed.",
            "success"
          ).then((res) => {
            if (res.isConfirmed) {
              dispatch(onRemoveSelected(data));
            }
          });
        }
      });
    } else {
      dispatch(onSelectedOptions(data));
    }
  };

  useEffect(() => {
    // console.log(selectedData.selectedData);
  }, [selectedData.selectedData]);

  return (
    <div className="a">
      <Container>
        <div
          className="d-flex justify-content-between align-items-center cursor"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <h5>Search Criteria</h5>
          <span>
            {toggle ? (
              <i className="mdi mdi-chevron-up h1"></i>
            ) : (
              <i className="mdi mdi-chevron-down h1"></i>
            )}
          </span>
        </div>
        <Form onSubmit={formSubmit} className={toggle ? "" : "d-none"}>
          <Form.Group>
            <Form.Label className="fs-6 fw-bold" style={{ color: "#fc9200" }}>
              Location*
            </Form.Label>
            <PlacesAutocomplete
              value={address}
              defaultValue={locationName}
              onChange={locationChange}
              onSelect={handleLocation}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
                index,
              }) => (
                <div key={index} className="search-data">
                  <input
                    key={index}
                    {...getInputProps({
                      placeholder: "Search Places...",
                      className: "location-search-input form-control",
                      autoFocus: true,
                    })}
                  />

                  <div
                    key={index}
                    className="autocomplete-dropdown-container"
                    style={{ padding: "4px 0" }}
                  >
                    {suggestions.slice(0, 5).map((suggestion) => {
                      const classes = suggestion.active
                        ? "suggestion-item active"
                        : "suggestion-item";

                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fc9200",
                            color: "#fff",
                            cursor: "pointer",
                            padding: "4px 8px",
                          }
                        : {
                            backgroundColor: "#fff",
                            cursor: "pointer",
                            padding: "4px 8px",
                          };

                      return (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            classes,
                            style,
                          })}
                        >
                          <span key={index}>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>

            <Form.Group className="d-flex  justify-content-between align-items-center mt-3 mb-3">
              {/* <Form.Label style={{ color: "#fc9200" }}>Seats*</Form.Label> */}

              <Form.Control
                placeholder="Seats *"
                required
                style={{
                  width: "24%",
                }}
                className="rounded-0 col4 inputID"
                type="number"
                name="seats"
                id="inputID"
                defaultValue={localSearchData?.seats ?? searchData?.seats}
                onChange={handleChange}
              />

              <Form.Check
                className="col-4 d-flex justify-content-center align-items-center"
                type="checkbox"
              >
                <Form.Check.Input
                  name="is_brh_center"
                  type="checkbox"
                  defaultChecked={localSearchData?.is_brh == 0 ? true : false}
                  onChange={handleChange}
                />
                <Form.Check.Label
                  style={{
                    fontSize: "14px",
                    marginLeft: "6px",
                    paddingTop: "3px",
                  }}
                  className=" text-nowrap"
                >
                  BRH Centers
                </Form.Check.Label>
              </Form.Check>

              <Form.Check
                className="d-flex justify-content-center align-items-center"
                type="checkbox"
              >
                <Form.Check.Input
                  name="is_partner_centers"
                  type="checkbox"
                  defaultChecked={localSearchData?.is_brh == 1 ? true : false}
                  onChange={handleChange}
                />
                <Form.Check.Label
                  style={{
                    fontSize: "14px",
                    marginLeft: "6px",
                    paddingTop: "3px",
                  }}
                  className=" text-nowrap"
                >
                  Partner Centers
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Form.Group>

          {/* Property types */}

          {/* <div
            role="button"
            onClick={() => setTogglePro(!togglePro)}
            className="d-flex justify-content-between align-items-center"
          >
            <h6 style={{ color: "#fc9200" }} className="d-inline">
              Type of Property
            </h6>
            <span>
              {togglePro ? (
                <i className="mdi mdi-chevron-up h1"></i>
              ) : (
                <i className="mdi mdi-chevron-down h1"></i>
              )}
            </span>
          </div>
          <Form.Group className={togglePro ? "col-sm-12 m-1" : "d-none"}>
            <Row>
              {propertyTypes?.map((type, index) => (
                <Col xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Form.Check
                    key={index}
                    name="property_type_id"
                    onChange={(e) =>
                      handleChangeForPropertyType(e, type, index)
                    }
                    value={type.id}
                    checked={type.isCheck === true ? true : false}
                    style={{ marginLeft: "0.5rem" }}
                    type="checkbox"
                    label={type?.property_type_name}
                  />
                </Col>
              ))}
            </Row>
          </Form.Group> */}

          {/* Property types */}

          <div className="shadow-sm border px-3 py-2 rounded mb-2">
            <div
              role="button"
              onClick={() => setToggleSpace(!toggleSpace)}
              className="d-flex justify-content-between align-items-center"
            >
              <h6 style={{ color: "#fc9200" }} className="d-inline">
                Type of Space
              </h6>
              <span>
                {toggleSpace ? (
                  <i className="mdi mdi-chevron-up h1"></i>
                ) : (
                  <i className="mdi mdi-chevron-down h1"></i>
                )}
              </span>
            </div>
            <Form.Group
              className={toggleSpace ? "d-flex flex-column col-12 " : "d-none"}
            >
              <div className="col-12 d-flex justify-content-between">
                <Form.Check className="w-50" type="checkbox">
                  <Form.Check.Input
                    name="is_workspace"
                    onChange={handleChange}
                    checked={searchData?.is_workspace === 1 ? true : false}
                    type="checkbox"
                  />
                  <Form.Check.Label className="textPrimary">
                    WorkSpace
                  </Form.Check.Label>
                </Form.Check>

                <Form.Check className="w-50" type="checkbox">
                  <Form.Check.Input
                    name="is_meeting_space"
                    onChange={handleChange}
                    //value={}
                    checked={
                      localSearchData?.is_meeting_space === 1
                        ? true
                        : searchData?.is_meeting_space === 1
                        ? true
                        : false
                    }
                    type="checkbox"
                  />
                  <Form.Check.Label className="textPrimary">
                    Meeting Space
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="col-12">
                <Row>
                  {localSearchData?.is_workspace === 1 ||
                  searchData?.is_workspace === 1
                    ? resourceForWorkspace?.map((type, index) => (
                        <Col xs={6} sm={6} md={6} lg={4} key={index}>
                          <Form.Check
                            name="resource_group_id"
                            onChange={(e) =>
                              handleChangeForWorkspace(e, type, index)
                            }
                            checked={type?.isCheck === true ? true : false}
                            key={index}
                            value={type.id}
                            style={{ marginLeft: "0.5rem" }}
                            type="checkbox"
                            label={type?.resource_group_name}
                          />
                        </Col>
                      ))
                    : null}
                </Row>
                <Row>
                  {searchData?.is_meeting_space === 1
                    ? resourceForMeeting?.map((type, index) => (
                        <Col
                          xs={6}
                          sm={6}
                          md={6}
                          lg={4}
                          key={index}
                          //style={{ marginLeft: "1rem" }}
                        >
                          <Form.Check
                            name="resource_group_id"
                            onChange={(e) =>
                              handleChangeForMeetingSpace(e, type, index)
                            }
                            checked={type.isCheck === true ? true : false}
                            key={index}
                            value={type.id}
                            style={{ marginLeft: "0.5rem" }}
                            type="checkbox"
                            label={type?.resource_group_name}
                          />
                        </Col>
                      ))
                    : null}
                </Row>
              </div>
            </Form.Group>
          </div>

          <div className="shadow-sm border px-3 py-2 rounded mb-2">
            <div
              onClick={() => setAmenitiesToggle(!amenitiesToggle)}
              role="button"
              className="d-flex justify-content-between align-items-center"
            >
              <h6 style={{ color: "#fc9200" }} className="d-inline">
                Amenities
              </h6>
              <span>
                {amenitiesToggle ? (
                  <i className="mdi mdi-chevron-up h1"></i>
                ) : (
                  <i className="mdi mdi-chevron-down h1"></i>
                )}
              </span>
            </div>
            <Form.Group className={amenitiesToggle ? "col-sm-12  " : "d-none"}>
              <Row>
                <Col xs={6} sm={6} md={6} lg={4} xl={3}>
                  <Form.Check
                    name="selectall"
                    onChange={handleChange}
                    style={{ marginLeft: "0.5rem" }}
                    type="checkbox"
                    checked={searchData?.selectall === 1 ? true : false}
                    label="Select All"
                  />
                </Col>
                {amenities?.map((type, index) => (
                  <Col xs={6} sm={6} md={6} lg={4} xl={3}>
                    <Form.Check
                      name="amenity_id"
                      onChange={(e) => handleChangeForAmenities(e, type, index)}
                      key={index}
                      checked={type?.isCheck === true ? true : false}
                      value={type.id}
                      style={{ marginLeft: "0.5rem" }}
                      type="checkbox"
                      label={type?.amenity_name}
                    />
                  </Col>
                ))}
              </Row>
            </Form.Group>
          </div>

          <div className="shadow-sm border px-3 py-2 rounded mb-2">
            <div className="d-flex justify-content-between mt-3 mb-3 ">
              <div className=" col-5" style={{ maxWidth: "50%" }}>
                <h6 style={{ marginBottom: "0px", color: "#fc9200" }}>
                  Price(Per Month)
                </h6>
                <span
                  style={{
                    fontSize: "10px",
                    color: "#fc9200",
                    whiteSpace: "nowrap",
                  }}
                >
                  Price from ₹{price[0]} to ₹{price[1]}
                </span>
                <br />

                <Range
                  values={price}
                  step={PRICESTEP}
                  min={PRICEMIN}
                  max={PRICEMAX}
                  onChange={(values) => {
                    setPrice(values);

                    console.log("PRICE", values);

                    // searchedData = searchedData.filter((p) => {
                    //   const pPrice = p.least_plan_price.price;
                    //   return pPrice && pPrice > price[0] && pPrice < price[1];
                    // });
                  }}
                  renderTrack={({ props, children }) => (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: "18px",
                        display: "flex",
                        width: "100%",
                        marginLeft: "6px",
                        marginTop: "5px",
                      }}
                    >
                      <div
                        ref={props.ref}
                        style={{
                          height: "4px",
                          width: "100%",
                          borderRadius: "4px",
                          background: getTrackBackground({
                            values: price,
                            colors: ["#E1E1E1", "#FF8E0A", "#E1E1E1"],
                            min: PRICEMIN,
                            max: PRICEMAX,
                          }),
                          alignSelf: "center",
                        }}
                      >
                        {children}
                      </div>
                    </div>
                  )}
                  renderThumb={({ props, isDragged }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "18px",
                        width: "18px",
                        borderRadius: "50%",
                        backgroundColor: "#FF8E0A",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-5" style={{ maxWidth: "50%" }}>
                <h6 style={{ color: "#fc9200" }} className="d-inline">
                  Inventory Status
                </h6>
                <Form.Check
                  name="status"
                  onChange={handleChange}
                  value={1}
                  defaultChecked={localSearchData?.status == 1 ? true : false}
                  type="radio"
                  label="Available"
                />
                <Form.Check
                  name="status"
                  onChange={handleChange}
                  value={0}
                  defaultChecked={localSearchData?.status == 0 ? true : false}
                  type="radio"
                  label="All"
                />
              </div>
            </div>
          </div>

          <div className="shadow-sm border rounded mb-2 px-3 py-2">
            <div className="d-flex justify-content-between py-2 ">
              <div className="col45">
                <h6 style={{ color: "#fc9200" }} className="d-inline">
                  Start Date
                  <Form.Control
                    name="start_date"
                    onChange={handleChange}
                    className="rounded-0"
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    defaultValue={new Date().toISOString().split("T")[0]}
                  />
                </h6>
              </div>
              <div className="col45">
                <h6 style={{ color: "#fc9200" }} className="d-inline">
                  Months
                </h6>
                <Form.Control
                  name="no_of_months"
                  onChange={handleChange}
                  className="rounded-0"
                  min={1}
                  type="number"
                  defaultValue={localSearchData?.no_of_months}
                />
              </div>
            </div>
          </div>

          <div
            style={{ marginTop: "1.5rem" }}
            className="d-flex justify-content-between mb-2 "
          >
            <button type="button" className="primary col3" onClick={reset}>
              Reset
            </button>
            <button onClick={saveFilter} type="button" className="primary col3">
              Save Filter
            </button>
            <button type="submit" className="primary col3">
              Generate options
            </button>
          </div>
        </Form>
      </Container>

      {loader && (
        <Container className="d-flex justify-content-center align-items-center">
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Container>
      )}

      {properties?.data?.length > 0 && (
        <Container>
          <div
            className="d-flex justify-content-between align-items-center cursor mt-2 mb-3"
            onClick={() => setToggle(!toggle)}
          >
            <h5>Options</h5>
            <span>
              {!toggle ? (
                <i className="mdi mdi-chevron-up h1"></i>
              ) : (
                <i className="mdi mdi-chevron-down h1"></i>
              )}
            </span>
          </div>
          <Row className={toggle ? "d-none" : " justify-content-center p-1 "}>
            {properties?.data?.map((option, index) => (
              <Col
                className="border p-2 cardItem mb-2  "
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                key={index}
              >
                <div className="d-flex  ">
                  <div className="p-1 d-flex flex-column justify-content-between  ">
                    <div class="tooo">
                      <Image
                        width={140}
                        height={140}
                        src={
                          option?.images[0]?.image_path
                            ? IMG_URL + option?.images[0]?.image_path
                            : require("../../assets/images/placeholder.png")
                        }
                        alt="option_image"
                      />
                      {option?.property_type_id === 4 && (
                        <div class="topleft">BRH</div>
                      )}
                    </div>
                    <div className="ml-2 p-1">
                      <span className="textPrimary">T&nbsp;</span>
                      <span>{option?.total_seats}&nbsp;</span>
                      <span className="textPrimary">O&nbsp;</span>
                      <span>
                        {option?.property_type_id === 4
                          ? option?.total_seats - 11
                          : "--"}
                        &nbsp;
                      </span>
                      <span className="textPrimary">A&nbsp;</span>
                      <span style={{ color: "#34E16A" }}>
                        {option?.property_type_id === 4 ? (
                          option?.total_seats - 5
                        ) : (
                          <span className="mdi mdi-checkbox-blank-circle text-success"></span>
                        )}
                        &nbsp;
                      </span>
                    </div>
                  </div>

                  <div
                    style={{ width: "300px" }}
                    className="p-1 d-flex flex-column justify-content-between"
                  >
                    <div className="mb-4">
                      <h6 style={{ padding: 0, margin: 0 }}>
                        {option?.property_name}
                      </h6>
                      <p style={{ padding: 0, margin: 0 }}>
                        <small>{option?.locality},</small>
                        <small>{option?.city?.name}</small>
                      </p>
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          whiteSpace: "nowrap",
                          fontSize: "14px  ",
                        }}
                      >
                        Price /<small>Seat (Monthly) </small> :
                        <span
                          style={{
                            padding: 0,
                            margin: 0,
                            whiteSpace: "nowrap",
                            fontSize: "14px  ",
                            marginLeft: "5px",
                          }}
                          className="textPrimary fw-bold"
                        >
                          ₹{priceFormat(option?.least_plan_price?.price)}
                        </span>
                      </p>
                      <small style={{ padding: 0, margin: 0 }}>
                        <i className="mdi mdi-map-marker-outline" />{" "}
                        {Math.round(option?.distance[0]?.distance)} Kms
                      </small>
                    </div>
                    <div className=" d-flex justify-content-between align-items-center">
                      {selectedData.selectedData.find(
                        (sel) => sel.id === option.id
                      ) ? (
                        <button
                          type="button"
                          onClick={() => handleAdd(option)}
                          className=" w-50 btn btn-danger rounded-0"
                        >
                          Remove
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleAdd(option)}
                          className=" w-50 btn btn-secondary rounded-0"
                        >
                          Add
                        </button>
                      )}

                      {moreInfo && index === cardIndex ? (
                        <button
                          onClick={() => {
                            getInfo(option, index, "less");
                          }}
                          className="w-50 primary text-nowrap"
                        >
                          Less Info
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            getInfo(option, index, "more");
                          }}
                          className="w-50 primary text-nowrap"
                        >
                          More Info
                        </button>
                      )}

                      {/* <button
                        onClick={() => {
                          getInfo(option, index);
                        }}
                        className="w-50 primary text-nowrap"
                      >
                        {moreInfo && index === cardIndex
                          ? "Less Info"
                          : "More Info"}
                      </button> */}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    moreInfo && index === cardIndex ? "d-block" : "d-none"
                  }
                >
                  <hr />
                  <div className="p-2">
                    <div>
                      <div>
                        <span
                          className="mdi mdi-map-marker textPrimary"
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: "20px",
                          }}
                        ></span>

                        <h6
                          style={{
                            padding: 0,
                            margin: 0,
                            display: "inline",
                          }}
                        >
                          &nbsp;
                          {propertyInfo?.address1 +
                            " , " +
                            propertyInfo?.address2}
                        </h6>
                      </div>
                      <p
                        className="d-flex justify-content-between"
                        style={{ padding: 0, margin: 0 }}
                      >
                        <span>
                          <span
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: "20px",
                            }}
                            className="mdi mdi-account-outline textPrimary"
                          ></span>
                          <span>{propertyInfo?.contact_person}</span>
                        </span>

                        <span>
                          <span
                            style={{
                              padding: 0,
                              margin: 0,
                              fontSize: "20px",
                            }}
                            className="mdi mdi-phone-in-talk textPrimary"
                          ></span>
                          <span>
                            {propertyInfo?.contact_no ??
                              propertyInfo?.mobile_no}
                          </span>
                        </span>
                      </p>
                      <span style={{ padding: 0, margin: 0, fontSize: "20px" }}>
                        <span className="mdi mdi-email-outline textPrimary"></span>
                        <small>{propertyInfo?.email}</small>
                      </span>
                      <hr />
                      <p
                        className="d-flex justify-content-start"
                        style={{ padding: 0, margin: 0 }}
                      >
                        <span>Property Type</span> :
                        <span className="textPrimary">
                          &nbsp;{" "}
                          {propertyInfo?.property_type?.property_type_name}
                        </span>
                      </p>
                      <p
                        className="d-flex justify-content-start"
                        style={{ padding: 0, margin: 0 }}
                      >
                        <span>Property Code</span> :
                        <span className="textPrimary">
                          {" "}
                          &nbsp;{propertyInfo?.property_code}
                        </span>
                      </p>
                      <p
                        className="d-flex justify-content-between"
                        style={{ padding: 0, margin: 0 }}
                      >
                        <span>
                          Total Sq Ft:&nbsp;
                          <span className="textPrimary">
                            {propertyInfo?.total_sq_ft}
                          </span>
                        </span>
                        <span>
                          Floor:&nbsp;
                          <span className="textPrimary">
                            {propertyInfo?.floor_no}
                          </span>
                        </span>
                      </p>
                      <p
                        className="d-flex justify-content-start"
                        style={{ padding: 0, margin: 0 }}
                      >
                        <span>Work Time</span> :
                        <span className="textPrimary">
                          &nbsp;
                          {moment(propertyInfo?.start_at, "hh").format(
                            "hh:mm A"
                          )}
                          to{" "}
                          {moment(propertyInfo?.end_at, "hh").format("hh:mm A")}
                        </span>
                      </p>
                      <hr />
                      <h6>OFFICE SPACES</h6>
                      <Table size="sm" striped bordered hover>
                        <thead
                          style={{
                            backgroundColor: "#fc9200",
                            color: "#ffffff",
                            fontWeight: "300",
                          }}
                        >
                          <tr>
                            {heading.map((th, index) => (
                              <th title={th}>{th}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {officeSpaces.map((td) => (
                            <tr>
                              <>
                                <td>{td?.resource_name}</td>
                                <td>{td?.capacity}</td>
                                <td>{td?.quantity}</td>
                                <td>{td?.quantity}</td>
                                <td>
                                  ₹{priceFormat(td?.resource_price?.price)}
                                </td>
                              </>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <hr />
                      <h6>AMENITIES</h6>
                      <div
                        style={{
                          padding: "5px",
                          margin: "5px",
                        }}
                      >
                        <Row>
                          {propertyInfo?.amenities?.map((ame) => (
                            <Col
                              style={{
                                width: "70px",
                                height: "65px",
                                backgroundColor: "#fff",
                                border: "1px solid #fc9200",
                                margin: "5px",
                              }}
                              xs={3}
                            >
                              <img
                                className="w-95 mt-3 "
                                width={35}
                                src={IMG_URL + ame.icon_path}
                                alt={ame.amenity_name}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                      <hr />
                      {propertyInfo?.social_infrastructure?.length > 0 && (
                        <>
                          <h6>DISTANCE</h6>
                          <div
                            style={{
                              height: "80px",
                            }}
                            className="d-flex justify-content-between  "
                          >
                            <div className="d-flex justify-content-center align-items-center p-1">
                              <div
                                style={{
                                  width: "70px",
                                  height: "65px",
                                  padding: "3px",
                                }}
                                className="d-flex flex-column"
                              >
                                <img
                                  style={{ width: "80%", height: "90%" }}
                                  alt="plane"
                                  src={airplane}
                                />
                                <small>Airport</small>
                              </div>
                              <div
                                style={{ color: "#fc9200" }}
                                className="d-flex flex-column align-items-center"
                              >
                                <span style={{ fontSize: "40px" }}>
                                  {
                                    propertyInfo?.international_airport
                                      ?.distance
                                  }
                                </span>
                                <span>Kms</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center p-1">
                              <div
                                style={{
                                  width: "70px",
                                  height: "65px",
                                  padding: "3px",
                                }}
                                className="d-flex flex-column"
                              >
                                <img
                                  style={{ width: "100%", height: "90%" }}
                                  alt="plane"
                                  src={bus}
                                />
                                <small>Bus Stop</small>
                              </div>
                              <div
                                style={{ color: "#fc9200" }}
                                className="d-flex flex-column align-items-center"
                              >
                                <span style={{ fontSize: "40px" }}>
                                  {propertyInfo?.bus_stop?.distance}
                                </span>
                                <span>Kms</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center p-1">
                              <div
                                style={{
                                  width: "70px",
                                  height: "65px",
                                  padding: "3px",
                                }}
                                className="d-flex flex-column"
                              >
                                <img
                                  style={{ width: "80%", height: "90%" }}
                                  alt="plane"
                                  src={train}
                                />
                                <small>Railways</small>
                              </div>
                              <div
                                style={{ color: "#fc9200" }}
                                className="d-flex flex-column align-items-center"
                              >
                                <span style={{ fontSize: "40px" }}>
                                  {propertyInfo?.social_infrastructure?.map(
                                    (a) => (a.id === 2 ? a.distance : "")
                                  )}
                                </span>
                                <span>Kms</span>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                      <div
                        role="button"
                        style={{ color: "#fc9200" }}
                        onClick={() => setMoreInfo(!moreInfo)}
                        className="d-flex justify-content-center"
                      >
                        {<FaAngleUp />}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
            {properties.data.length > 1 && (
              <div className="text-center mx-3">
                {properties.data !== [] &&
                  properties.lastPage !== properties.currentPage && (
                    <button
                      className="btn load-more"
                      onClick={() => _getProperties(properties.currentPage + 1)}
                    >
                      Show more
                    </button>
                  )}
              </div>
            )}
          </Row>
        </Container>
      )}
    </div>
  );
};

export default SearchPage;
