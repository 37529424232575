import { ClosedCaption } from "@mui/icons-material";
import {
  Autocomplete,
  TextField,
  Dialog,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React from "react";
import { SyncLoader } from "react-spinners";
import CloseIcon from "@mui/icons-material/Close";
export const AutoCompleteInputBRH = ({
  name,
  label,
  value,
  handleChange,
  required,
  options,
  defaultValue,
  isSelectDataloaded = true,
  size,
  sx,
  blur,
  escape,
  handleClear,
  readOnly = false,
}) => {
  return (
    <>
      <Autocomplete
        // sx={{ display: isSelectDataloaded ? 'block' : 'none' }}
        onChange={handleChange}
        id={name}
        value={value}
        options={options}
        defaultValue={defaultValue}
        getOptionLabel={(option) => {
          return option[name];
        }}
        clearIcon={
          <span onClick={handleClear}>
            <CloseIcon />
          </span>
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            // value={value}
            required={required}
          />
        )}
        size={size}
        readOnly={readOnly}
        sx={{ ...sx, backgroundColor: readOnly ? "#f8f9fa" : "inherit" }}
      />

      <Dialog open={!isSelectDataloaded}>
        <Box
          sx={{
            width: "150px",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: "16px",
          }}
        >
          {" "}
          <Typography variant="overline">Loading</Typography>
          <SyncLoader color="#fc9200" />
        </Box>
      </Dialog>
    </>
  );
};
