import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import API from "../../API_SERVICES/ApiServices";
import NavbarContainer from "../HomePage/NavbarContainer";
import toast from "react-simple-toasts";
import { onSubmitClient } from "./OptionsSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

const ClientForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientData = useSelector((state) => state.optionReduser?.clientData);
  const selectedData = useSelector((state) => state?.optionReduser);

  console.log("clientData", clientData);

  const [clientDetails, setClientDetails] = useState({ status: 1 });
  const user = JSON.parse(localStorage.getItem("user"));

  const clientInfoSubmit = (e) => {
    e.preventDefault();

    console.log("clientDetails", clientDetails);

    API.postMethod("api/sale-client", "POST", clientDetails)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          console.log("sale-client", result);
          Swal.fire("Success", result.message, "success").then((res) => {
            if (res.isConfirmed) {
              dispatch(onSubmitClient(result.data));
              if (selectedData?.selectedData <= 0) {
                navigate("/sales/client-details");
              } else {
                navigate("/sales/selected-options");
              }
            }
          });
        } else {
          Swal.fire("Error", result.message, "warning");
        }
      });
  };
  const cancelBtn = (e) => {
    e.preventDefault();
    navigate("/sales/selected-options");
  };

  const clientHandleChange = (e) => {
    const { name, value } = e.target;
    setClientDetails({ ...clientDetails, [name]: value, user_id: user?.id });
  };

  useEffect(() => {
    // console.log("clientDetails", clientDetails);
  }, [clientDetails]);

  return (
    <>
      <Container className="a">
        <h5 className=" mt-2 mb-0">Add client information</h5>
        <hr className="mt-1" style={{ color: "#fc9200" }} />
        <Form onSubmit={clientInfoSubmit}>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className=" textPrimary">Company name *</Form.Label>
                <Form.Control
                  required
                  name="company_name"
                  className="rounded-0 "
                  type="text"
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className=" textPrimary">
                  Contact person *
                </Form.Label>
                <Form.Control
                  required
                  name="contact_person_name"
                  className="rounded-0"
                  type="text"
                  rows={3}
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className=" textPrimary">
                  Contact person Email *
                </Form.Label>
                <Form.Control
                  required
                  name="contact_person_email"
                  className="rounded-0"
                  type="email"
                  rows={3}
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Group>
                <Form.Label className=" textPrimary">
                  Contact Number *
                </Form.Label>
                <Form.Control
                  required
                  name="contact_person_number"
                  className="rounded-0"
                  type="tel"
                  maxLength="10"
                  rows={3}
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <h5 className="mt-3 mb-0">Secondary Contact details</h5>
          <hr className="mt-1" style={{ color: "#fc9200" }} />
          <Row>
            <Col xs={12} sm={12} md={6}>
              <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className=" textPrimary">Contact Name </Form.Label>
                <Form.Control
                  name="contact_name"
                  className="rounded-0"
                  type="text"
                  rows={3}
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12} sm={12} md={6}>
              <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className=" textPrimary">Contact Email</Form.Label>
                <Form.Control
                  name="contact_email"
                  className="rounded-0"
                  type="email"
                  rows={3}
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Group>
                <Form.Label className=" textPrimary">Contact Number</Form.Label>
                <Form.Control
                  name="contact_number"
                  className="rounded-0"
                  type="tel"
                  rows={3}
                  maxLength="10"
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>
            <h5 className="mt-3 "> Special Instructions</h5>
            <Col xs={12} sm={12} md={6}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  name="special_instructions"
                  className="rounded-0"
                  as={"textarea"}
                  rows={3}
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>

            <h5>Estimates</h5>

            <Col xs={12} sm={12} md={6}>
              <Form.Group
                className="mb-3 "
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label className=" textPrimary">
                  Estimated Date of Closure
                </Form.Label>
                <Form.Control
                  name="estimated_date_of_closure"
                  className="rounded-0"
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  rows={3}
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Group>
                <Form.Label className=" textPrimary">
                  Estimated Value of Deal
                </Form.Label>
                <Form.Control
                  name="estimated_value_of_deal"
                  className="rounded-0"
                  type="number"
                  rows={3}
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Group>
                <Form.Label className=" textPrimary d-block">
                  Conversion Probability
                </Form.Label>
                <Form.Check
                  className="textPrimary"
                  inline
                  label=">75%"
                  name="conversion_probality"
                  type="radio"
                  value={1}
                  onChange={clientHandleChange}
                />
                <Form.Check
                  className="textPrimary"
                  inline
                  label="50-75%"
                  name="conversion_probality"
                  type="radio"
                  value={2}
                  onChange={clientHandleChange}
                />
                <Form.Check
                  className="textPrimary"
                  inline
                  label="<50%"
                  name="conversion_probality"
                  type="radio"
                  value={3}
                  onChange={clientHandleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-between pt-3 pb-3">
            <button
              type="button"
              onClick={cancelBtn}
              className="rounded-0 btn btn-secondary col-6"
            >
              Cancel
            </button>
            <button type="submit" className="primary rounded-0 col-6">
              Save
            </button>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default ClientForm;
