import "./App.css";
import Login from "./components/LoginPage/Login";
import SearchPage from "./components/SearchPage/SearchPage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import NavbarContainer from "./components/HomePage/NavbarContainer";
import ClientForm from "./components/options/ClientForm";
import SelectedOptions from "./components/options/SelectedOptions";
import SavedFilters from "./components/SearchPage/SavedFilters";
import PdfHistory from "./components/HomePage/PdfHistory";
import ProfileScreen from "./components/LoginPage/ProfileScreen";
import PageNotFound from "./components/HomePage/PageNotFound";
import ClientList from "./components/options/ClientList";
import ProspectProperty from "./components/options/ProspectProperty";
import ProspectPropertyList from "./components/ProspectProperty/PropectPropertyList";
import AppContext from "./ResusableComponents/context/AppContext";
import { HelmetProvider } from "react-helmet-async";
import ThemeProvider from "./theme";
import AddProspectListPage from "./components/ProspectProperty/AddProspectListPage";
import { routeNames } from "./API_SERVICES/routeNames";

function App(props) {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <HelmetProvider>
      <ThemeProvider>
        <AppContext>
          <Router>
            {user && <NavbarContainer />}
            <Routes>
              <Route
                exact
                path="/sales/login"
                element={!user ? <Login /> : <Navigate to="/" replace />}
              />
              <Route
                exact
                path="/"
                element={
                  user ? <SearchPage /> : <Navigate to="/sales/login" replace />
                }
              />

              <Route
                exact
                path="/sales/selected-options"
                element={
                  user ? (
                    <SelectedOptions />
                  ) : (
                    <Navigate to="/sales/login" replace />
                  )
                }
              />
              <Route
                exact
                path="/sales/saved-filters"
                element={
                  user ? (
                    <SavedFilters />
                  ) : (
                    <Navigate to="/sales/login" replace />
                  )
                }
              />
              <Route
                exact
                path="/sales/add-client"
                element={
                  user ? <ClientForm /> : <Navigate to="/sales/login" replace />
                }
              />
              <Route
                exact
                path="/sales/profile"
                element={
                  user ? (
                    <ProfileScreen />
                  ) : (
                    <Navigate to="/sales/login" replace />
                  )
                }
              />
              <Route
                exact
                path="/sales/pdf-history"
                element={
                  user ? <PdfHistory /> : <Navigate to="/sales/login" replace />
                }
              />
              <Route
                exact
                path="/sales/client-details"
                element={
                  user ? <ClientList /> : <Navigate to="/sales/login" replace />
                }
              />
              <Route
                exact
                path="/sales/property"
                element={
                  user ? (
                    <ProspectPropertyList />
                  ) : (
                    <Navigate to="/sales/login" replace />
                  )
                }
              />
              <Route
                exact
                path={routeNames.PROSPECTPROPERTYADD}
                element={
                  user ? (
                    <AddProspectListPage />
                  ) : (
                    <Navigate to="/sales/login" replace />
                  )
                }
              />
              <Route
                exact
                path={routeNames.PROSPECTPROPERTYEDIT}
                element={
                  user ? (
                    <AddProspectListPage />
                  ) : (
                    <Navigate to="/sales/login" replace />
                  )
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Router>
        </AppContext>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
