import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { TextInputBRH } from "../../ResusableComponents/forms/TextInputBRH";

import { GmailInputBRH } from "../../ResusableComponents/forms/GmailInputBRH";
import { TextFieldSelectBRH } from "../../ResusableComponents/forms/TextFieldSelectBRH";
// import TextFieldSelectBRH from '../../ResusableComponents/forms/TextFieldSelectBRH';
import { AutoCompleteInputBRH } from "../../ResusableComponents/forms/AutoCompleteInputBRH";
import { SelectInputBRH } from "../../ResusableComponents/forms/SelectInputBRH";
import { NumberInputBRH } from "../../ResusableComponents/forms/NumberInputBRH";
import { TimeInputBRH } from "../../ResusableComponents/forms/TimeInputBRH";
import { CheckBoxInputBRH } from "../../ResusableComponents/forms/CheckBoxInputBRH";
import { MobileInputBRH } from "../../ResusableComponents/forms/MobileInputBRH";
import * as API from "../../API_SERVICES/ApiConfigs";
import { COUNTRYLIST, STATELIST, CITYLIST } from "../../API_SERVICES/ApiNames";
import { ToastBRH } from "../../ResusableComponents/toasts/ToastBRH";
import { LoadingButtonBRH } from "../../ResusableComponents/forms/LoadingButtonBRH";
import {
  setBascisDetailsRedux,
  setFormImcompleteRedux,
  setSelectedImagesRedux,
} from "../../Redux/actions/BasicDetailsActions";
import { routeNames } from "../../API_SERVICES/routeNames";
import { SyncLoader } from "react-spinners";
import AddProvider from "./Provider/AddProvider";

const STATUS_FIT = 1;
const STATUS_NOTFIT = 0;
const STATUS_AVAILABLE = 1;
const STATUS_NOTAVAILABLE = 0;

const statusFitOptions = [
  {
    label: "Fit",
    value: STATUS_FIT,
  },
  {
    label: "Not Fit",
    value: STATUS_NOTFIT,
  },
];
const statusAvailableOptions = [
  {
    label: "Available",
    value: STATUS_AVAILABLE,
  },
  {
    label: "Not Available",
    value: STATUS_NOTAVAILABLE,
  },
];

const BasicDetails = ({ onSubmit, propertyId, onChangeNext }) => {
  const IMAGE_API = "https://brhfiles.s3.ap-south-1.amazonaws.com/";

  const basicDetailsdata = useSelector(
    (state) => state.BasicDetails.basicDetails
  );

  const selectedData = useSelector((state) => state.BasicDetails.selectedData);
  const selectedImagesRedux = useSelector(
    (state) => state.BasicDetails.selectedImages
  );
  const [selectedImages, setSelectedImages] = useState(
    selectedImagesRedux.length <= 0 ? [] : selectedImagesRedux
  );
  const [sortOrder, setSortOrder] = useState([]);
  const [openDialog, setOpendialog] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [imageError, setImageError] = useState(false);
  const [formData, setformData] = useState({});
  const [Pstatus, setPStatus] = useState();
  const [Istatus, setIStatus] = useState();
  const navigate = useNavigate();
  const starttime = new Date();
  starttime.setHours(10);
  starttime.setMinutes(0);
  const endtime = new Date();
  endtime.setHours(19);
  endtime.setMinutes(0);
  // const [accordian1FormImcomplete, setFormIncomplete] = useState(true);
  const [basicDetails, setBascisDetails] = useState({
    dontLoad: false,
    address1: null,
    isSelectDataloadedCountry: true,
    address2: null,
    property_type_name: null,
    name: null,
    sortOptions: [],
    imageFile: [],
    contact_alternate_email: "",
    email: "",
    contact_person: "",
    countryList: [],
    countryObject: null,
    country_id: 101,
    stateList: [],
    stateObject: null,
    state_id: null,
    cityList: [],
    pseudo_name: null,
    cityObject: null,
    city_id: null,
    workingDayMon: false,
    workingDayTue: false,
    workingDayWed: false,
    workingDayThu: false,
    workingDayFri: false,
    workingDaySat: false,
    workingDaySun: false,
    workingHoursStart: starttime,
    workingHoursEnd: endtime,
    floor_no: "",

    property_status: "",
    is_draft: "",
    landmark: "",
    landline: "",
    latitude: "",
    locality: "",
    long_desc: "",
    longitude: "",
    contact_number: null,
    property_code: null,
    property_name: "",
    property_tabs: "",
    property_type_id: null,
    provider_id: null,
    pseudoname: "",
    short_desc: "",
    show_actual_name: "",
    sort_id: null,
    sortOrderObject: null,
    sort_order: null,
    start_at: "",
    propertyDataList: [],
    propertyTypeObject: null,
    status: "",
    total_sq_ft: "",
    zipcode: "",
    // pincode: null,
    providerDataList: [],
    isFetched: false,
    is_verified: 0,
    isSelectDataloaded: true,
    addProvider: false,
    // statusAvailable: STATUS_NOTFIT,
    statusAvailable: null,
    statusFit: null,
  });
  console.log("basic", basicDetails.statusAvailable);
  // parellel

  const accordian1FormImcomplete = useSelector(
    (state) => state.BasicDetails.accordian1FormImcomplete
  );

  useEffect(() => {
    // let apiProviderData;
    // let apiCountryData;
    // step-1
    API.getData("api/provider/")
      .then((resProv) => {
        if (resProv?.data?.status === "success") {
          const apiProviderData = resProv?.data?.data;
          const providerId = apiProviderData.id;

          setBascisDetails((prevDetails) => ({
            ...prevDetails,
            providerDataList: apiProviderData,
            provider_id: providerId,
            isSelectDataloaded: true,
            countryObject: {
              id: 101,
              name: "India",
            },
          }));
          // setBascisDetails({
          //   ...basicDetails,
          //   providerDataList: apiProviderData,
          // });
        }
      })
      .catch((errProv) => {
        console.log(errProv);
      });
    // step-2
    API.getData(`${COUNTRYLIST}`)
      .then((resCountry) => {
        if (resCountry?.data?.status === true) {
          const apiCountryData = resCountry?.data?.data;
          API.getData(`${STATELIST}/${basicDetails.country_id}`)
            .then((resultState) => {
              if (resultState?.data?.status === true) {
                const dataState = resultState?.data?.data;
                setBascisDetails((prev) => ({
                  ...prev,
                  countryList: apiCountryData,
                  countryObject: apiCountryData.find(
                    (country) => country.id === 101
                  ),
                  stateList: dataState,
                  state_id: dataState.length > 0 ? dataState[0].id : null,
                  isFetched: true,
                }));
                // setBascisDetails({
                //   ...basicDetails,
                //   countryList: apiCountryData,
                //   countryObject: apiCountryData.find((country) => country.id === 101),
                //   stateList: dataState,
                //   state_id: dataState.length > 0 ? dataState[0].id : null,
                //   isFetched: true,
                // });
              }
            })
            .catch((errState) => {
              setBascisDetails((prev) => ({
                ...prev,
                countryList: apiCountryData,
                countryObject: apiCountryData[0],
                isFetched: true,
              }));
            });
        }
      })
      .catch((errCountry) => {
        setBascisDetails((prev) => ({ ...prev, isFetched: true }));
      });

    API.getDataToken("api/propertytype/")
      .then((resProperty) => {
        if (resProperty?.data?.status === "success") {
          const apiPropertyData = resProperty?.data?.data?.data;

          setBascisDetails((prevDetails) => ({
            ...prevDetails,
            propertyDataList: apiPropertyData,
            isSelectDataloaded: true,
          }));
        }
      })
      .catch((errProp) => {
        setBascisDetails((prevDetails) => ({
          ...prevDetails,
          propertyDataList: [],
          isSelectDataLoaded: true,
        }));
      });

    API.getData("api/sortorder?limit=10")
      .then((result) => {
        if (result.data.data) {
          const sortOrderOptions = result.data.data.data.map((item) => ({
            id: item.id,
            sort_order: item.sort_order,
          }));
          setBascisDetails((prevDetails) => ({
            ...prevDetails,
            sortOptions: sortOrderOptions,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    if (propertyId) {
      API.getData(`api/property/${propertyId}`).then((res) => {
        localStorage.setItem("verified", res.data.data.is_verified);

        // Get the current date
        const startTime = new Date();
        const endTime = new Date();

        // Parse the time string "09:30"
        const StartTime = res.data.data.start_at.split(":");
        const starthours = parseInt(StartTime[0], 10);
        const startminutes = parseInt(StartTime[1], 10);
        const EndTime = res.data.data.end_at.split(":");
        const endhours = parseInt(EndTime[0], 10);
        const endminutes = parseInt(EndTime[1], 10);
        // Set the time on the current date
        startTime.setHours(starthours, startminutes, 0, 0);
        endTime.setHours(endhours, endminutes, 0, 0);

        setBascisDetails((prev) => ({
          ...prev,
          provider_id: res.data.data.provider_id,
          city_id: res.data.data.city_id,
          property_name: res.data.data.property_name,
          property_type_id: res.data.data.property_type_id,
          state_id: res.data.data.state_id,
          contact_person: res.data.data.contact_person,
          email: res.data.data.email,
          contact_alternate_email: res.data.data.contact_alternate_email,
          contact_number: res.data.data.mobile_no,
          landline: res.data.data.landline,
          longitude: res.data.data.longitude,
          latitude: res.data.data.latitude,
          address1: res.data.data.address1,
          address2: res.data.data.address2,
          country_id: res.data.data.country_id,
          floor_no: res.data.data.floor_no,
          zipcode: res.data.data.zipcode,
          property_code: res.data.data.property_code,
          landmark: res.data.data.landmark,
          locality: res.data.data.locality,
          total_sq_ft: res.data.data.total_sq_ft,
          short_desc: res.data.data.short_desc,
          long_desc: res.data.data.long_desc,
          pseudoname: res.data.data.pseudoname,
          workingHoursStart: startTime,
          workingHoursEnd: endTime,
          is_verified: res.data.data.is_verified,
          property_status: res.data.data.furnished_status,
          statusFit: res.data.data.is_fit,
          statusAvailable: res.data.data.is_available,
        }));
        if (res.data.data.state_id) {
          API.getData(`${CITYLIST}/${res.data.data.state_id}`).then((res) => {
            setBascisDetails((prev) => ({
              ...prev,
              cityList: res.data.data,
            }));
          });
        }
      });
      API.getData(`api/property-timings/${propertyId}`).then((res) => {
        // Iterate through the dataArray and update the properties
        res.data.data.forEach((item) => {
          switch (item.day_id) {
            case "mon":
              setBascisDetails((prev) => ({
                ...prev,
                workingDayMon: item.is_open === 1,
              }));
              break;
            case "tue":
              setBascisDetails((prev) => ({
                ...prev,
                workingDayTue: item.is_open === 1,
              }));
              break;
            case "wed":
              setBascisDetails((prev) => ({
                ...prev,
                workingDayWed: item.is_open === 1,
              }));
              break;
            case "thu":
              setBascisDetails((prev) => ({
                ...prev,
                workingDayThu: item.is_open === 1,
              }));
              break;
            case "fri":
              setBascisDetails((prev) => ({
                ...prev,
                workingDayFri: item.is_open === 1,
              }));
              break;
            case "sat":
              setBascisDetails((prev) => ({
                ...prev,
                workingDaySat: item.is_open === 1,
              }));
              break;
            case "sun":
              setBascisDetails((prev) => ({
                ...prev,
                workingDaySun: item.is_open === 1,
              }));
              break;
            default:
              // Handle unexpected day_id values here, if necessary
              break;
            // Add cases for other days if needed
          }
        });
      });
      API.getData(`api/propertyimage/${propertyId}`).then((result) => {
        if (result.data.status === "success") {
          setSelectedImages(result.data.data);
        } else {
          setSelectedImages([]);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (basicDetails.property_type_name && basicDetails.stateObject) {
      const pseudoName = `BRH ${basicDetails.property_type_name} ${basicDetails.property_code}`;
      // alert(pseudoName);
      setBascisDetails((prev) => ({ ...prev, pseudoname: pseudoName }));
    }
  }, [basicDetails.property_type_name, basicDetails.stateObject]);
  useEffect(() => {
    if (propertyId && basicDetails.dontLoad === false) {
      API.getData(`api/property/${propertyId}`).then((res) => {
        localStorage.setItem("verified", res.data.data.is_verified);
        const PTObj = basicDetails?.propertyDataList?.find(
          (i) => i.id === res.data.data.property_type_id
        );
        const PRObj = basicDetails?.providerDataList?.find(
          (i) => i.id === res.data.data.provider_id
        );
        const STObj = basicDetails?.stateList?.find(
          (i) => i.id === res.data.data.state_id
        );
        const COObj = basicDetails?.countryList?.find(
          (i) => i.id === res.data.data.country_id
        );
        const CIObj = basicDetails?.cityList?.find(
          (i) => i.id === res.data.data.city_id
        );
        const SOObj = basicDetails?.sortOptions?.find(
          (i) => i.id === res.data.data.sort_id
        );
        const SObj = basicDetails?.sortOptions?.find(
          (i) => i.id === res.data.data.sort_id
        );

        setBascisDetails((prev) => ({
          ...prev,
          provider_id: res.data.data.provider_id,
          name: PRObj,

          propertyTypeObject: PTObj,

          stateObject: STObj,

          countryObject: COObj,
          cityObject: CIObj,
          sortOrderObject: SOObj,
          sort_id: res.data.data.sort_id,
        }));
      });
    } else {
      console.log("dontload");
    }
  }, [
    basicDetails.propertyDataList,
    basicDetails.providerDataList,
    basicDetails.stateList,
    basicDetails.countryList,
    basicDetails.cityList,
    basicDetails.sortOptions,
  ]);
  useEffect(() => {
    if (basicDetails.name === null) {
      setBascisDetails((prev) => ({
        ...prev,
        isSelectDataloaded: false,
      }));
    } else {
      setBascisDetails((prev) => ({
        ...prev,
        isSelectDataloaded: true,
      }));
    }
  }, []);
  const handleCountryChange = (event, newValue) => {
    const [name] = event.target.id.split("-");
    const value = newValue[name];
    const id = newValue.id;
    if (name === "name") {
      setBascisDetails((prev) => ({
        ...prev,
        isSelectDataloadedCountry: false,
      }));
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setBascisDetails((prev) => ({
              ...prev,
              country_id: id,
              countryObject: newValue,
              stateList: res.data.data,
              stateObject: null,
              cityList: [],
              cityObject: null,
              isSelectDataloadedCountry: true,
            }));
          }
        })
        .catch((err) => {
          setBascisDetails((prev) => ({
            ...prev,
            country_id: id,
            stateList: [],
            isSelectDataloadedCountry: true,
          }));
        });
    }
  };
  const handleStateChange = (event, newValue) => {
    const [name] = event.target.id.split("-");
    const value = newValue[name];
    const id = newValue.id;
    const data = { state_id: id };

    if (name === "name") {
      // MAKE LOADER ONLY WHEN VALUE IS THERE
      setBascisDetails((prev) => ({ ...prev, isSelectDataloadedState: false }));
      // GET DETAILS HERE BY SEQUENTIALLY EVENTHOUGH IT IS INDEPENDEDNT
      // CALL CITY API ACCORDING TO STATE
      API.getData(`${CITYLIST}/${id}`)
        .then((resCity) => {
          if (resCity.data.status) {
            // GET PROPERTY CODE FROM API FOR SPECIFIC STATE

            API.postMethod("/api/propertycode", data).then((res) => {
              if (res.data.status !== "success") {
                const laksh = `${newValue.iso2}001`;
                setBascisDetails({
                  ...basicDetails,
                  isSelectDataloadedState: true,
                  stateObject: newValue,
                  state_id: id,
                  property_code: laksh,
                  cityList: resCity.data.data,
                  cityObject: null,
                });
              } else {
                const code = res.data.data.property_code;
                let propertycodes = parseInt(code.match(/\d+/g)[0], 10) + 1;
                propertycodes =
                  propertycodes > 99
                    ? `${propertycodes}`
                    : propertycodes > 9
                    ? `0${propertycodes}`
                    : `00${propertycodes}`;

                const laksh = `${code.match(/[a-zA-Z]+/g)[0]}${propertycodes}`;
                setBascisDetails((prev) => ({
                  ...prev,
                  isSelectDataloadedState: true,
                  stateObject: newValue,
                  state_id: id,
                  property_code: laksh,
                  cityList: resCity.data.data,
                  cityObject: null,
                }));
              }
            });
          }
        })
        .catch((err) => {
          ToastBRH("error", `${err?.response?.data?.message}`, "_", false);
          setBascisDetails((prev) => ({
            ...prev,
            isSelectDataloaded: true,
            cityList: [],
            state_id: id,
          }));
        });
    }
  };
  const handleCityChange = (event, newValue) => {
    const [name] = event.target.id.split("-");
    const value = newValue[name];
    const id = newValue.id;
    if (name === "name") {
      setBascisDetails((prev) => ({
        ...prev,
        city_id: id,
        cityObject: newValue,
      }));
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (
      name === "workingDaySun" ||
      name === "workingDayMon" ||
      name === "workingDayTue" ||
      name === "workingDayWed" ||
      name === "workingDayThu" ||
      name === "workingDayFri" ||
      name === "workingDaySat"
    ) {
      setBascisDetails((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (type === "checkbox") {
      if (name === "is_default_1" && checked) {
        setBascisDetails((prev) => ({
          ...prev,
          is_default_1: 1,
          is_default_2: 0,
        }));
      } else if (name === "is_default_2" && checked) {
        setBascisDetails((prev) => ({
          ...prev,
          is_default_1: 0,
          is_default_2: 1,
        }));
      } else {
        setBascisDetails((prev) => ({
          ...prev,
          is_default_1: 0,
          is_default_2: 0,
        }));
      }
    } else {
      setBascisDetails((prev) => ({ ...prev, [name]: value }));
    }
  };
  const handleImageSelection = (event) => {
    const files = Array.from(event.target.files);
    setImageError(false);
    setSelectedImages((prevSelectedImages) => [
      ...prevSelectedImages,
      ...files,
    ]);
  };
  const handleImageDelete = (image) => {
    // const updatedImages = selectedImages.filter((_, i) => i !== index);
    // setSelectedImages(updatedImages);
    API.deleteMethod(`api/propertyimages/${image.id}`).then((res) => {
      if (res.data.status === "success") {
        setSelectedImages((prevImages) =>
          prevImages.filter((i) => i.id !== image.id)
        );
      }
    });
  };
  const handleImageDeletenewAdded = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  const handlePropertyTypeChange = (event, newValue) => {
    const [name] = event.target.id.split("-");
    const value = newValue[name];
    const id = newValue.id;

    setBascisDetails((prevDetails) => ({
      ...prevDetails,
      [name]: newValue.property_type_name,
      propertyTypeObject: newValue,
      property_type_id: id,
    }));
  };
  const handleSortOrderChange = (event, newValue) => {
    if (!newValue) return;

    setBascisDetails((prevDetails) => ({
      ...prevDetails,
      sort_id: newValue.id, // for backend
      sortOrderObject: newValue, // for frontend
    }));
  };
  const handlePropertyStatusChange = (event) => {
    const selectedValues = event.target.value; // An array of selected IDs
    setBascisDetails((prevDetails) => ({
      ...prevDetails,
      property_status: selectedValues,
    }));
  };
  const handleAutoCompleteChange = (event, newValue) => {
    const [name] = event.target.id.split("-");

    const value = newValue[name];
    const id = newValue.id;

    if (name === "name") {
      const selectedProvider = basicDetails.providerDataList.find(
        (val) => val.id === id
      );

      if (selectedProvider) {
        setBascisDetails((prev) => ({
          ...prev,
          isSelectDataloaded: false,
          provider_id: newValue.id,
          dontLoad: true,
        }));
        const selectedCountry = basicDetails.countryList.find(
          (country) => country.id === selectedProvider.country_id
        );

        setBascisDetails((prevDetails) => ({
          ...prevDetails,
          [name]: newValue,
          countryObject: selectedCountry,
          state_id: selectedProvider.state_id,
          city_id: selectedProvider.city_id,
          address1: selectedProvider.address1,
          address2: selectedProvider.address2,
          zipcode: selectedProvider.zipcode,
          contact_person: selectedProvider.contact_person,
          email: selectedProvider.email,
          contact_number: selectedProvider.contact_number,
          mobile_no: selectedProvider.contact_number,
          // isSelectDataloaded: true,
        }));

        API.getData(`${STATELIST}/${selectedProvider.country_id}`)
          .then((resultState) => {
            if (resultState?.data?.status === true) {
              const dataState = resultState?.data?.data;
              const selectedState = dataState.find(
                (state) => state.id === selectedProvider.state_id
              );
              setBascisDetails((prevDetails) => ({
                ...prevDetails,
                stateList: dataState,
                stateObject: selectedState,
                state_id: selectedProvider.state_id,
                isSelectDataloaded: true,
              }));
              const data = { state_id: selectedProvider.state_id };
              API.postMethod("/api/propertycode", data).then((res) => {
                if (res.data.status !== "success") {
                  const laksh = selectedState ? `${newValue.iso2}001` : "";
                  setBascisDetails((prev) => ({
                    ...prev,

                    property_code: laksh,
                  }));
                } else {
                  const code = res.data.data.property_code;
                  let propertycodes = parseInt(code.match(/\d+/g)[0], 10) + 1;
                  propertycodes =
                    propertycodes > 99
                      ? `${propertycodes}`
                      : propertycodes > 9
                      ? `0${propertycodes}`
                      : `00${propertycodes}`;

                  const laksh = selectedState
                    ? `${code.match(/[a-zA-Z]+/g)[0]}${propertycodes}`
                    : "";
                  setBascisDetails((prev) => ({
                    ...prev,

                    property_code: laksh,
                  }));
                }
              });
            }
          })
          .catch((errState) => {
            setBascisDetails((prevDetails) => ({
              ...prevDetails,
              stateList: [],
              isSelectDataloaded: true,
            }));
          });
        API.getData(`${CITYLIST}/${selectedProvider.state_id}`)
          .then((resultCity) => {
            if (resultCity?.data?.status === true) {
              const dataCity = resultCity?.data?.data;
              const selectedCity = dataCity.find(
                (city) => city.id === selectedProvider.city_id
              );
              setBascisDetails((prevDetails) => ({
                ...prevDetails,
                cityList: dataCity,
                cityObject: selectedCity,
                city_id: selectedProvider.city_id,
                isSelectDataloaded: true,
              }));
            }
          })
          .catch((errCity) => {
            setBascisDetails((prevDetails) => ({
              ...prevDetails,
              cityList: [],
              isSelectDataloaded: true,
            }));
          });
      }
    }
  };
  console.log(basicDetails.provider_id, "pp");
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const data = {
      provider_id: basicDetails.provider_id,
      property_name: basicDetails.property_name,
      property_type_id: basicDetails.property_type_id,
      contact_person: basicDetails.contact_person,
      // contact_email: basicDetails.email,
      contact_alternate_email: basicDetails.contact_alternate_email,
      mobile_no: basicDetails.mobile_no,

      email: basicDetails.email,
      landline: basicDetails.landline,
      address1: basicDetails.address1,
      address2: basicDetails.address2,
      latitude: basicDetails.latitude,
      longitude: basicDetails.longitude,
      country_id: basicDetails.country_id,
      state_id: basicDetails.state_id,
      city_id: basicDetails.city_id,
      landmark: basicDetails.landmark,
      locality: basicDetails.locality,
      zipcode: basicDetails.zipcode,
      property_code: basicDetails.property_code,
      //  alias: basicDetails.alias,
      total_sq_ft: basicDetails.total_sq_ft,
      floor_no: basicDetails.floor_no,
      start_at: basicDetails.workingHoursStart.toLocaleTimeString("en-IN", {
        hour12: false,
      }),
      end_at: basicDetails.workingHoursEnd.toLocaleTimeString("en-IN", {
        hour12: false,
      }),
      sort_id: basicDetails.sort_id,
      short_desc: basicDetails.short_desc,
      long_desc: basicDetails.long_desc,
      // show_actual_name: basicDetails.show_actual_name,
      pseudoname: basicDetails.pseudoname,
      status: 0,
      days: [
        {
          day: "Monday",
          day_id: "mon",
          open: basicDetails.workingDayMon ? 1 : 0,
          status: basicDetails.workingDayMon ? 1 : 0,
        },
        {
          day: "Tuesday",
          day_id: "tue",
          open: basicDetails.workingDayTue ? 1 : 0,
          status: basicDetails.workingDayTue ? 1 : 0,
        },
        {
          day: "Wednesday",
          day_id: "wed",
          open: basicDetails.workingDayWed ? 1 : 0,
          status: basicDetails.workingDayWed ? 1 : 0,
        },
        {
          day: "Thursday",
          day_id: "thu",
          open: basicDetails.workingDayThu ? 1 : 0,
          status: basicDetails.workingDayThu ? 1 : 0,
        },
        {
          day: "Friday",
          day_id: "fri",
          open: basicDetails.workingDayFri ? 1 : 0,
          status: basicDetails.workingDayFri ? 1 : 0,
        },
        {
          day: "Saturday",
          day_id: "sat",
          open: basicDetails.workingDaySat ? 1 : 0,
          status: basicDetails.workingDaySat ? 1 : 0,
        },
        {
          day: "Sunday",
          day_id: "sun",
          open: basicDetails.workingDaySun ? 1 : 0,
          status: basicDetails.workingDaySun ? 1 : 0,
        },
      ],
      is_draft: 1,

      property_tabs: "basicdetails",
      furnished_status: basicDetails.property_status,
      is_fit: Number(basicDetails.statusFit) === STATUS_FIT ? 1 : 0,
      is_available:
        Number(basicDetails.statusAvailable) === STATUS_AVAILABLE ? 1 : 0,
    };

    // return console.log('laksh', data);
    API.postMethod("api/property/", data)

      .then((result) => {
        console.log("laksh", "run");
        if (result.data.status === "success") {
          localStorage.setItem("message", result.data.message);
          window.location.href = `/sales/property/edit/${result.data.data.id}`;
          // Redirect
        }
        if (result.data.status === "error") {
          ToastBRH("error", result.data.message, "_", false);
        }
      })
      .catch((e) => {
        ToastBRH(
          "error",
          "There is an error in property insertion",
          "_",
          false
        );
      });

    setFormImcompleteRedux(false);
    setExpanded("panel2a-content");
    setBascisDetailsRedux(basicDetails);
  };

  const [expanded, setExpanded] = React.useState("panel1a-content");

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleBasicDetails = async () => {
    if (
      basicDetails.property_name === "" ||
      basicDetails.property_name === null
    ) {
      ToastBRH("error", "Property name field is required", "_", false);
      return;
    }
    if (basicDetails.name === "" || basicDetails.name === null) {
      ToastBRH("error", "Provider name field is required", "_", false);
      return;
    }
    if (
      basicDetails.propertyTypeObject === "" ||
      basicDetails.propertyTypeObject === null
    ) {
      ToastBRH("error", "Property Type field is required", "_", false);
      return;
    }
    if (
      basicDetails.propertyTypeObject === "" ||
      basicDetails.propertyTypeObject === null
    ) {
      ToastBRH("error", "Property Type field is required", "_", false);
      return;
    }
    if (
      basicDetails.contact_person === "" ||
      basicDetails.contact_person === null
    ) {
      ToastBRH("error", "Contact person field is required", "_", false);
      return;
    }
    if (basicDetails.email === "" || basicDetails.email === null) {
      ToastBRH("error", "email field is required", "_", false);
      return;
    }
    if (
      basicDetails.contact_number === "" ||
      basicDetails.contact_number === null
    ) {
      ToastBRH("error", "phone number field is required", "_", false);
      return;
    }
    if (basicDetails.address1 === "" || basicDetails.address1 === null) {
      ToastBRH("error", "address1 field is required", "_", false);
      return;
    }
    if (
      basicDetails.countryObject === "" ||
      basicDetails.countryObject === null
    ) {
      ToastBRH("error", "Country field is required", "_", false);
      return;
    }
    if (basicDetails.stateObject === "" || basicDetails.stateObject === null) {
      ToastBRH("error", "State field is required", "_", false);
      return;
    }
    if (basicDetails.cityObject === "" || basicDetails.cityObject === null) {
      ToastBRH("error", "City field is required", "_", false);
      return;
    }
    if (basicDetails.locality === "" || basicDetails.locality === null) {
      ToastBRH("error", "locality field is required", "_", false);
      return;
    }
    if (basicDetails.zipcode === "" || basicDetails.zipcode === null) {
      ToastBRH("error", "Pincode field is required", "_", false);
      return;
    }
    if (basicDetails.pseudoname === "" || basicDetails.pseudoname === null) {
      ToastBRH("error", "Display name field is required", "_", false);
      return;
    }
    if (
      basicDetails.property_code === "" ||
      basicDetails.property_code === null
    ) {
      ToastBRH("error", "Property code field is required", "_", false);
      return;
    }
    if (basicDetails.floor_no === "" || basicDetails.floor_no === null) {
      ToastBRH("error", "Floor number field is required", "_", false);
      return;
    }
    if (
      basicDetails.workingHoursStart === starttime ||
      basicDetails.workingHoursStart === null ||
      basicDetails.workingHoursStart === ""
    ) {
      ToastBRH("error", "Working hours From field is required", "_", false);
      return;
    }
    if (
      basicDetails.workingHoursEnd === endtime ||
      basicDetails.workingHoursEnd === null ||
      basicDetails.workingHoursEnd === ""
    ) {
      ToastBRH("error", "Working hours To field is required", "_", false);
      return;
    }
    if (
      basicDetails.workingDayMon === false &&
      basicDetails.workingDayTue === false &&
      basicDetails.workingDayWed === false &&
      basicDetails.workingDayThu === false &&
      basicDetails.workingDayFri === false &&
      basicDetails.workingDaySat === false &&
      basicDetails.workingDaySun === false
    ) {
      ToastBRH(
        "error",
        "Atleast one Working Day has To be selected",
        "_",
        false
      );
      return;
    }
    // if (
    //   basicDetails.sortOrderObject === "" ||
    //   basicDetails.sortOrderObject === null
    // ) {
    //   ToastBRH("error", "Sort Order field is required", "_", false);
    //   return;
    // }
    if (
      basicDetails.property_status === "" ||
      basicDetails.property_status === null
    ) {
      ToastBRH("error", "Furnished status field is required", "_", false);
      return;
    }
    if (basicDetails.short_desc === "" || basicDetails.short_desc === null) {
      ToastBRH("error", "Please enter short description", "_", false);
      return;
    }
    // if (basicDetails.long_desc === "" || basicDetails.long_desc === null) {
    //   ToastBRH("error", "Please enter long description", "_", false);
    //   return;
    // }

    //  // if (basicDetails.latitude === "" || basicDetails.latitude === null) {
    //  //   return;
    //  // }
    //  // if (basicDetails.longitude === "" || basicDetails.longitude === null) {
    //  //   return;
    //  // }
    //  if (basicDetails.locality === '' || basicDetails.locality === null) {
    //    ToastBRH('error', result.data.status, '_', false);.({ errorMessage: 'Locality field is required' });
    //    return;
    //  }
    //  if (basicDetails.zipcode === '' || basicDetails.zipcode === null) {
    //    ToastBRH('error', result.data.status, '_', false);.({ errorMessage: 'Zipcode field is required' });
    //    return;
    //  }
    //  if (basicDetails.floor_no === '' || basicDetails.floor_no === null) {
    //    ToastBRH('error', result.data.status, '_', false);.({ errorMessage: 'Floor No field is required' });
    //    return;
    //  }
    //  if (basicDetails.sort_id === '' || basicDetails.sort_id === null) {
    //    ToastBRH('error', result.data.status, '_', false);.({ errorMessage: 'Sord Id field is required' });
    //    return;
    //  }

    if (selectedImages.length === 0) {
      ToastBRH("error", "Please upload atleast one property image", "_", false);
      // return;
    }
    const data = {
      provider_id: basicDetails.provider_id,

      property_name: basicDetails.property_name,

      property_type_id: basicDetails.property_type_id,
      contact_person: basicDetails.contact_person,

      contact_alternate_email: basicDetails.contact_alternate_email,
      mobile_no: basicDetails.contact_number,

      email: basicDetails.email,
      landline: basicDetails.landline,
      address1: basicDetails.address1,
      address2: basicDetails.address2,
      latitude: basicDetails.latitude,
      longitude: basicDetails.longitude,
      country_id: basicDetails.country_id,
      state_id: basicDetails.state_id,
      city_id: basicDetails.city_id,
      landmark: basicDetails.landmark,
      locality: basicDetails.locality,
      zipcode: basicDetails.zipcode,
      property_code: basicDetails.property_code,

      total_sq_ft: basicDetails.total_sq_ft,
      floor_no: basicDetails.floor_no,
      start_at: basicDetails.workingHoursStart.toLocaleTimeString("en-IN", {
        hour12: false,
      }),
      end_at: basicDetails.workingHoursEnd.toLocaleTimeString("en-IN", {
        hour12: false,
      }),
      sort_id: basicDetails.sort_id,
      short_desc: basicDetails.short_desc,
      long_desc: basicDetails.long_desc,
      is_draft: 1,
      // is_primary: basicDetails.is_primary,
      // is_alternative_primary: basicDetails.is_alternative_primary,
      property_tabs: "basicdetails",
      furnished_status: basicDetails.property_status,
      // show_actual_name: basicDetails.show_actual_name,
      pseudoname: basicDetails.pseudoname,
      status:
        localStorage.getItem("verified") === 1 ||
        localStorage.getItem("verified") === 2
          ? 1
          : 0,
      days: [
        {
          day: "Monday",
          day_id: "mon",
          open: basicDetails.workingDayMon ? 1 : 0,
          status: basicDetails.workingDayMon ? 1 : 0,
        },
        {
          day: "Tuesday",
          day_id: "tue",
          open: basicDetails.workingDayTue ? 1 : 0,
          status: basicDetails.workingDayTue ? 1 : 0,
        },
        {
          day: "Wednesday",
          day_id: "wed",
          open: basicDetails.workingDayWed ? 1 : 0,
          status: basicDetails.workingDayWed ? 1 : 0,
        },
        {
          day: "Thursday",
          day_id: "thu",
          open: basicDetails.workingDayThu ? 1 : 0,
          status: basicDetails.workingDayThu ? 1 : 0,
        },
        {
          day: "Friday",
          day_id: "fri",
          open: basicDetails.workingDayFri ? 1 : 0,
          status: basicDetails.workingDayFri ? 1 : 0,
        },
        {
          day: "Saturday",
          day_id: "sat",
          open: basicDetails.workingDaySat ? 1 : 0,
          status: basicDetails.workingDaySat ? 1 : 0,
        },
        {
          day: "Sunday",
          day_id: "sun",
          open: basicDetails.workingDaySun ? 1 : 0,
          status: basicDetails.workingDaySun ? 1 : 0,
        },
      ],
      is_fit: Number(basicDetails.statusFit) === STATUS_FIT ? 1 : 0,
      is_available:
        Number(basicDetails.statusAvailable) === STATUS_AVAILABLE ? 1 : 0,
    };

    await API.updateMethod(`api/property/${propertyId}`, data)

      .then((result) => {
        if (result.data.status === "success") {
          setPStatus("save&next");
          localStorage.setItem("message", result.data.message);
          // this.handleImageUpload();
        } else if (result.data.status === "error") {
          ToastBRH("error", result.data.message, "_", false);
        }
      })
      .catch((e) => {
        console.log("testing data", e);
      });

    if (selectedImages.length > 0) {
      const formData = new FormData();

      selectedImages?.map(
        (i) => !i.image_path && formData.append("image_path[]", i)
      );
      selectedImages?.map((i, index) =>
        formData.append("image_order", `new_${index}`)
      );
      formData.append("property_id", propertyId);
      //  formData.append('is_default', basicDetails.is_default);

      await API.fileupload("api/propertyimages", formData).then((result) => {
        if (result.status === 200) {
          if (result.data.status === "success") {
            localStorage.setItem("message", result.data.message);
            setIStatus("save&next");

            localStorage.setItem("property_tabs", "amenities");
          } else if (result.data.status === "failure") {
            localStorage.setItem("message", result.data.message);
            setIStatus("save&next");
          }

          // window.location.reload();
        }
      });
    }
  };
  const handleDraft = async () => {
    const data = {
      provider_id: basicDetails.name.id,

      property_name: basicDetails.property_name,

      property_type_id: basicDetails.property_type_id,
      contact_person: basicDetails.contact_person,

      contact_alternate_email: basicDetails.contact_alternate_email,
      mobile_no: basicDetails.contact_number,

      email: basicDetails.email,
      landline: basicDetails.landline,
      address1: basicDetails.address1,
      address2: basicDetails.address2,
      latitude: basicDetails.latitude,
      longitude: basicDetails.longitude,
      country_id: basicDetails.country_id,
      state_id: basicDetails.state_id,
      city_id: basicDetails.city_id,
      landmark: basicDetails.landmark,
      locality: basicDetails.locality,
      zipcode: basicDetails.zipcode,
      property_code: basicDetails.property_code,

      total_sq_ft: basicDetails.total_sq_ft,
      floor_no: basicDetails.floor_no,
      start_at: basicDetails.workingHoursStart.toLocaleTimeString("en-IN", {
        hour12: false,
      }),
      end_at: basicDetails.workingHoursEnd.toLocaleTimeString("en-IN", {
        hour12: false,
      }),
      sort_id: basicDetails.sort_id,
      short_desc: basicDetails.short_desc,
      long_desc: basicDetails.long_desc,
      is_draft: 1,
      // is_primary: basicDetails.is_primary,
      // is_alternative_primary: basicDetails.is_alternative_primary,
      property_tabs: "basicdetails",
      furnished_status: basicDetails.property_status,
      // show_actual_name: basicDetails.show_actual_name,
      pseudoname: basicDetails.pseudoname,
      status: 0,
      days: [
        {
          day: "Monday",
          day_id: "mon",
          open: basicDetails.workingDayMon ? 1 : 0,
          status: basicDetails.workingDayMon ? 1 : 0,
        },
        {
          day: "Tuesday",
          day_id: "tue",
          open: basicDetails.workingDayTue ? 1 : 0,
          status: basicDetails.workingDayTue ? 1 : 0,
        },
        {
          day: "Wednesday",
          day_id: "wed",
          open: basicDetails.workingDayWed ? 1 : 0,
          status: basicDetails.workingDayWed ? 1 : 0,
        },
        {
          day: "Thursday",
          day_id: "thu",
          open: basicDetails.workingDayThu ? 1 : 0,
          status: basicDetails.workingDayThu ? 1 : 0,
        },
        {
          day: "Friday",
          day_id: "fri",
          open: basicDetails.workingDayFri ? 1 : 0,
          status: basicDetails.workingDayFri ? 1 : 0,
        },
        {
          day: "Saturday",
          day_id: "sat",
          open: basicDetails.workingDaySat ? 1 : 0,
          status: basicDetails.workingDaySat ? 1 : 0,
        },
        {
          day: "Sunday",
          day_id: "sun",
          open: basicDetails.workingDaySun ? 1 : 0,
          status: basicDetails.workingDaySun ? 1 : 0,
        },
      ],
    };

    if (propertyId) {
      await API.updateMethod(`api/property/${propertyId}`, JSON.stringify(data))
        .then((result) => {
          if (result.data.status === "success") {
            setPStatus(result.data.status);
            localStorage.setItem("message", result.data.message);
            ToastBRH("success", "Successfully Saved as Draft", "_", false);
            // navigate(routeNames.PROSPECTPROPERTYLIST);
            // this.handleImageUpload();
          } else if (result.data.status === "error") {
            ToastBRH("error", result.data.message, "_", false);
          }
        })
        .catch((e) => {
          console.log("testing data", e);
        });
      if (selectedImages.length > 0) {
        const formData = new FormData();

        selectedImages?.map(
          (i) => !i.image_path && formData.append("image_path[]", i)
        );
        selectedImages?.map((i, index) =>
          formData.append("image_order", `new_${index}`)
        );
        formData.append("property_id", propertyId);
        //  formData.append('is_default', basicDetails.is_default);

        await API.fileupload("api/propertyimages", formData).then((result) => {
          if (result.status === 200) {
            if (result.data.status === "success") {
              localStorage.setItem("message", result.data.message);
              setIStatus(result.data.status);

              // localStorage.setItem('property_tabs', 'amenities');
            }

            // window.location.reload();
          }
        });
      }
    } else {
      await API.postMethod(`api/property/`, JSON.stringify(data))
        .then((result) => {
          if (result.data.status === "success") {
            setPStatus(result.data.status);
            localStorage.setItem("message", result.data.message);
            ToastBRH("success", "Successfully Saved as Draft", "_", false);
            //  navigate(routeNames.PROSPECTPROPERTYLIST);
            // this.handleImageUpload();
          } else if (result.data.status === "error") {
            ToastBRH("error", result.data.message, "_", false);
          }
        })
        .catch((e) => {
          console.log("testing data", e);
        });
      if (selectedImages.length > 0) {
        const formData = new FormData();

        selectedImages?.map(
          (i) => !i.image_path && formData.append("image_path[]", i)
        );
        selectedImages?.map((i, index) =>
          formData.append("image_order", `new_${index}`)
        );
        formData.append("property_id", propertyId);
        //  formData.append('is_default', basicDetails.is_default);

        await API.fileupload("api/propertyimages", formData).then((result) => {
          if (result.status === 200) {
            if (result.data.status === "success") {
              localStorage.setItem("message", result.data.message);
              setIStatus(result.data.status);
            }
          }
        });
      }
    }
  };
  useEffect(() => {
    if (
      (Pstatus === "success" && Istatus === "success") ||
      Pstatus === "success"
    ) {
      const data = {
        address1: null,
        isSelectDataloadedCountry: true,
        address2: null,
        property_type_name: null,
        name: null,
        sortOptions: [],
        imageFile: [],
        contact_alternate_email: "",
        email: "",
        contact_person: "",
        countryList: [],
        countryObject: null,
        country_id: 101,
        stateList: [],
        stateObject: null,
        state_id: null,
        cityList: [],
        pseudo_name: null,
        cityObject: null,
        city_id: null,
        workingDayMon: true,
        workingDayTue: true,
        workingDayWed: true,
        workingDayThu: true,
        workingDayFri: true,
        workingDaySat: false,
        workingDaySun: false,
        workingHoursStart: new Date(2023, 1, 1, 10, 0, 0),
        workingHoursEnd: new Date(2023, 1, 1, 18, 0, 0),
        floor_no: "",

        property_status: "",
        is_draft: "",
        landmark: "",
        landline: "",
        latitude: "",
        locality: "",
        long_desc: "",
        longitude: "",
        contact_number: null,
        property_code: null,
        property_name: "",
        property_tabs: "",
        property_type_id: null,
        provider_id: null,
        pseudoname: "",
        short_desc: "",
        show_actual_name: "",
        sort_id: null,
        sortOrderObject: null,
        sort_order: null,
        start_at: "",
        propertyDataList: [],
        propertyTypeObject: null,
        status: "",
        total_sq_ft: "",
        zipcode: "",
        // pincode: null,
        providerDataList: [],
        isFetched: false,
        isSelectDataloaded: true,
      };
      setBascisDetailsRedux(data);
      setFormImcompleteRedux(true);
      setSelectedImagesRedux([]);
      navigate(routeNames.PROSPECTPROPERTYLIST);
    } else if (Pstatus === "save&next" && Istatus === "save&next") {
      ToastBRH(
        "success",
        "BasicDetails and images are successfully saved",
        "_",
        false
      );
      // handlePropertyTabs();
      onChangeNext();
      //  window.location.reload();
    }
  }, [Pstatus, Istatus]);

  const handlePropertyTabs = () => {
    const tabsdata = {
      property_id: propertyId,
      tabs: "amenities",
    };

    API.postMethod("api/propertytabs", tabsdata).then((result) => {
      if (result.data.status === "success") {
        window.location.reload();
        // window.location.href = "/property-list";
      }
    });
  };
  const handleListOfUsers = () => {
    API.getData("api/provider/")
      .then((resProv) => {
        if (resProv?.data?.status === "success") {
          const apiProviderData = resProv?.data?.data.filter(
            (i) => i.role_id === 3
          );
          console.log(apiProviderData);

          setBascisDetails((prevDetails) => ({
            ...prevDetails,
            providerDataList: apiProviderData,
            addProvider: false,
          }));

          // setBascisDetails({
          //   ...basicDetails,
          //   providerDataList: apiProviderData,
          // });
        }
      })
      .catch((errProv) => {
        console.log(errProv);
      });
  };
  console.log(basicDetails.providerDataList, "123");
  return (
    <>
      <Accordion
        sx={{ background: "light", padding: "20px" }}
        expanded={expanded === "panel1a-content"}
        onChange={handleChangeAccordian("panel1a-content")}
      >
        <AccordionSummary
          sx={{ backgroundColor: "#f9fafb" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ background: "#f9fafb", width: "100%" }}>
            {" "}
            Property Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h4" gutterBottom>
                  Add Basic Details
                </Typography>
              </Stack> */}
            <Card sx={{ p: 2 }}>
              <Box sx={{ flexGrow: 1, m: 4 }}>
                {/* <Button
                  style={{ marginBottom: ' 10px' }}
                  onClick={handleOpen}
                  variant="text"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Add New Provider
                </Button> */}
                <Dialog open={!basicDetails.isSelectDataloaded}>
                  <Box
                    sx={{
                      width: "150px",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      rowGap: "16px",
                    }}
                  >
                    {" "}
                    <Typography variant="overline">Loading</Typography>
                    <SyncLoader color="#fc9200" />
                  </Box>{" "}
                </Dialog>
                <Dialog
                  open={basicDetails.addProvider}
                  onClose={() =>
                    setBascisDetails((prev) => ({
                      ...prev,
                      addProvider: false,
                    }))
                  }
                  maxWidth="md"
                >
                  <DialogTitle
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: 600, fontSize: "25px" }}>
                      Add Provider
                    </span>
                    <Tooltip arrow placement="left" title="close">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() =>
                          setBascisDetails((prev) => ({
                            ...prev,
                            addProvider: false,
                          }))
                        }
                        aria-label="close"
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </DialogTitle>
                  <DialogContentText style={{ padding: "20px" }}>
                    <AddProvider
                      countryList={basicDetails.countryList}
                      handleProviderList={handleListOfUsers}
                    />
                  </DialogContentText>
                </Dialog>
                <form noValidate={false} onSubmit={(e) => handleFormSubmit(e)}>
                  <FormControl sx={{ flexGrow: 1, width: "100%" }} sub>
                    <Grid
                      container
                      rowSpacing={2.5}
                      columnSpacing={{ xs: 2, sm: 2, md: 2 }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        className="d-flex justify-content-end"
                      >
                        {basicDetails.is_verified === 0 ? (
                          <Button
                            type="button"
                            onClick={() =>
                              setBascisDetails((prev) => ({
                                ...prev,
                                addProvider: true,
                              }))
                            }
                            variant="contained"
                            style={{ marginBottom: "-10px" }}
                          >
                            <AddIcon /> Add New Provider
                          </Button>
                        ) : null}
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} />
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="Provider Name"
                          name="name"
                          required="true"
                          //  defaultValue={basicDetailsdata.name}
                          value={basicDetails.name}
                          handleChange={handleAutoCompleteChange}
                          options={basicDetails.providerDataList}
                          isSelectDataloaded={basicDetails.isSelectDataloaded}
                          handleClear={() =>
                            setBascisDetails((prev) => ({
                              ...prev,
                              name: null,
                              countryObject: null,
                              stateList: [],
                              stateObject: null,
                              cityList: [],
                              cityObject: null,

                              address1: "",
                              address2: "",
                              zipcode: "",
                              contact_person: "",
                              email: "",
                              contact_number: "",
                              mobile_no: "",
                              property_code: "",
                            }))
                          }
                          // handleChange={}

                          // onChange={handleEventChange}
                          // options={}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Property Name"
                          name="property_name"
                          required="true"
                          value={basicDetails.property_name}
                          handleChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="Property Type"
                          name="property_type_name"
                          required="true"
                          value={basicDetails.propertyTypeObject}
                          handleChange={handlePropertyTypeChange}
                          options={basicDetails.propertyDataList}
                          handleClear={() => {
                            setBascisDetails((prev) => ({
                              ...prev,
                              propertyTypeObject: null,
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Contact Person Name"
                          name="contact_person"
                          required="true"
                          value={basicDetails.contact_person}
                          handleChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <GmailInputBRH
                          label="Contact Person Email"
                          name="email"
                          required="true"
                          value={basicDetails.email}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <GmailInputBRH
                          label="Alternative Email"
                          name="contact_alternate_email"
                          value={basicDetails.contact_alternate_email}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        //  sx={{ marginBottom: "32px" }}
                      >
                        <MobileInputBRH
                          label="Contact Person Number"
                          name="contact_number"
                          required="true"
                          value={basicDetails.contact_number}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        //  sx={{ marginBottom: "32px" }}
                      >
                        <MobileInputBRH
                          label="Landline Number"
                          name="landline"
                          value={basicDetails.landline}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Address Line #1"
                          name="address1"
                          required="true"
                          value={basicDetails.address1}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Address Line #2"
                          name="address2"
                          value={basicDetails.address2}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Latitude"
                          name="latitude"
                          value={basicDetails.latitude}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Longitude"
                          name="longitude"
                          value={basicDetails.longitude}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="Country"
                          name="name"
                          value={basicDetails.countryObject}
                          handleChange={handleCountryChange}
                          options={basicDetails.countryList}
                          isSelectDataloaded={basicDetails.isSelectDataLoaded}
                          required
                          handleClear={() => {
                            setBascisDetails((prev) => ({
                              ...prev,

                              countryObject: null,
                              stateList: [],
                              stateObject: null,
                              cityList: [],
                              cityObject: null,

                              property_code: "",
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="State"
                          required="true"
                          name="name"
                          value={basicDetails.stateObject}
                          handleChange={handleStateChange}
                          options={basicDetails.stateList}
                          isSelectDataloaded={
                            basicDetails.isSelectDataloadedState
                          }
                          handleClear={() => {
                            setBascisDetails((prev) => ({
                              ...prev,

                              stateObject: null,
                              cityList: [],
                              cityObject: null,

                              property_code: "",
                            }));
                          }}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="City"
                          name="name"
                          required="true"
                          value={basicDetails.cityObject}
                          handleChange={handleCityChange}
                          options={basicDetails.cityList}
                          handleClear={() => {
                            setBascisDetails((prev) => ({
                              ...prev,

                              cityList: [],
                              cityObject: null,
                            }));
                          }}
                          // required
                        />{" "}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Land Mark"
                          name="landmark"
                          value={basicDetails.landmark}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Locality"
                          name="locality"
                          required="true"
                          value={basicDetails.locality}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <NumberInputBRH
                          label="Pincode"
                          name="zipcode"
                          required="true"
                          value={basicDetails.zipcode}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Display Name"
                          name="pseudoname"
                          required="true"
                          value={basicDetails.pseudoname}
                          handleChange={handleChange} // Assuming you have a handleChange function
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Property Code"
                          name="property_code"
                          required="true"
                          value={basicDetails.property_code}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Total Square Feet"
                          name="total_sq_ft"
                          value={basicDetails.total_sq_ft}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Floor Number"
                          name="floor_no"
                          required="true"
                          value={basicDetails.floor_no}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TimeInputBRH
                          label="Working Hours From"
                          name="workingHoursStart"
                          required="true"
                          value={basicDetails.workingHoursStart}
                          handleChange={(e) => {
                            setBascisDetails({
                              ...basicDetails,
                              workingHoursStart: new Date(e),
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TimeInputBRH
                          label="Working Hours To"
                          name="workingHoursEnd"
                          required="true"
                          value={basicDetails.workingHoursEnd}
                          handleChange={(e) =>
                            setBascisDetails({
                              ...basicDetails,
                              workingHoursEnd: e,
                            })
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid
                          container
                          sx={{ border: "1px solid #ccc", padding: "5px 10px" }}
                        >
                          <Grid item xs={12}>
                            <Typography>Working Days</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Sun"
                              name="workingDaySun"
                              value={basicDetails.workingDaySun}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Mon"
                              name="workingDayMon"
                              value={basicDetails.workingDayMon}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Tue"
                              name="workingDayTue"
                              value={basicDetails.workingDayTue}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Wed"
                              name="workingDayWed"
                              value={basicDetails.workingDayWed}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Thu"
                              name="workingDayThu"
                              value={basicDetails.workingDayThu}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Fri"
                              name="workingDayFri"
                              value={basicDetails.workingDayFri}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Sat"
                              name="workingDaySat"
                              value={basicDetails.workingDaySat}
                              handleChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="Sort Order"
                          name="sort_order"
                          value={basicDetails.sortOrderObject}
                          handleChange={handleSortOrderChange}
                          options={basicDetails.sortOptions}
                          handleClear={() => {
                            setBascisDetails((prev) => ({
                              ...prev,
                              sortOrderObject: null,
                            }));
                          }}
                          // required
                        />{" "}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldSelectBRH
                          label="Property Furnished Status"
                          name="property_status"
                          required="true"
                          value={basicDetails.property_status}
                          handleChange={(e) => handlePropertyStatusChange(e)}
                          options={[
                            { id: "Fully Furnished", name: "Fully Furnished" },
                            { id: "Bareshell", name: "Bareshell" },
                          ]}
                          renderLabel="name"
                          renderValue="id"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Short Description"
                          name="short_desc"
                          required="true"
                          value={basicDetails.short_desc}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Long Description"
                          name="long_desc"
                          multiline
                          rows={4}
                          value={basicDetails.long_desc}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldSelectBRH
                          label="Availability Status"
                          name="statusAvailable"
                          value={basicDetails.statusAvailable}
                          options={statusAvailableOptions}
                          handleChange={handleChange}
                          renderLabel="label"
                          renderValue="value"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldSelectBRH
                          label="Fit Status"
                          name="statusFit"
                          value={basicDetails.statusFit}
                          options={statusFitOptions}
                          handleChange={handleChange}
                          renderLabel="label"
                          renderValue="value"
                          required
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={6}>
                          <label htmlFor="upload-button" aria-required>
                            <h5 className="text-center" style={{ color: '#fc9200', padding: 0 }}>
                              Upload your photo *
                            </h5>
                            <input
                              type="file"
                              id="upload-button"
                              required
                              multiple
                              style={{ color: 'orange' }}
                              onChange={handleImageSelection}
                            />
                          </label>
                          <Grid style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {imageError ? (
                              <p>upload image</p>
                            ) : (
                              selectedImages.map((image, index) => (
                                <div key={index}>
                                  <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview ${index}`}
                                    style={{
                                      width: '200px',
                                      height: '100px',
                                      marginRight: '10px',
                                      boxShadow: ' 0 3px 10px rgb(0 0 0 / 0.2)',
                                    }}
                                  />
                                  <button onClick={() => handleImageDelete(index)}>delete</button>
                                </div>
                              ))
                            )}
                          </Grid>
                        </Grid> */}
                      {/* <Grid item xs={12} sm={12} md={6}>
                          <Button sx={{ display: 'flex', gap: '10px', alignItems: 'center' }} component="label">
                            <CloudUploadIcon size={50} />
                            Upload Property Images
                            <input type="file" hidden multiple onChange={handleImageSelection} required />
                          </Button>
                          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {selectedImages.map((image, index) => (
                              <div key={index}>
                                <IconButton onClick={() => handleImageDelete(index)}>
                                  <ClearIcon />
                                </IconButton>
                                <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                  style={{ width: 'auto', height: '100px', marginRight: '10px' }}
                                />
                              </div>
                            ))}
                          </div>
                        </Grid> */}
                      {propertyId ? null : (
                        <Grid
                          sx={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "flex-end",
                          }}
                          item
                          xs={12}
                          sm={12}
                          md={6}
                        >
                          <LoadingButtonBRH
                            type="submit"
                            variant="containeds"
                            label="Next"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </FormControl>
                </form>
              </Box>
            </Card>

            {}
          </Container>
        </AccordionDetails>
      </Accordion>
      {propertyId ? (
        <>
          <Accordion
            sx={{ background: "light", padding: "20px" }}
            expanded={expanded === "panel2a-content"}
            onChange={handleChangeAccordian("panel2a-content")}
          >
            <AccordionSummary
              sx={{ backgroundColor: "#f9fafb" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{ background: "#f9fafb", width: "100%" }}>
                {" "}
                Property Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h4" gutterBottom>
                  Add Basic Details
                </Typography>
              </Stack> */}
                <Card sx={{ p: 2 }}>
                  <Box sx={{ flexGrow: 1, m: 4 }}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Button
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                        component="label"
                      >
                        <CloudUploadIcon size={50} />
                        Upload Property Images
                        <input
                          type="file"
                          hidden
                          multiple
                          onChange={handleImageSelection}
                          required
                        />
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        {selectedImages?.map((image, index) =>
                          image.image_path ? (
                            <div key={index}>
                              <IconButton
                                onClick={() => handleImageDelete(image)}
                              >
                                <ClearIcon />
                              </IconButton>
                              <img
                                // src={URL.createObjectURL(IMAGE_API+'images/properties/property-images/image/1655296974_1.jpg')}
                                src={`${IMAGE_API}${image.image_path}`}
                                alt={`Preview ${index}`}
                                style={{
                                  width: "auto",
                                  height: "100px",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                          ) : (
                            <div key={index}>
                              <IconButton
                                onClick={() => handleImageDeletenewAdded(index)}
                              >
                                <ClearIcon />
                              </IconButton>
                              <img
                                src={URL.createObjectURL(image)}
                                //  src={`${IMAGE_API}${image.image_path}`}
                                alt={`Preview ${index}`}
                                style={{
                                  width: "auto",
                                  height: "100px",
                                  marginRight: "10px",
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </Grid>
                  </Box>
                </Card>

                {}
              </Container>
            </AccordionDetails>
          </Accordion>
          <Grid
            sx={{
              display: "flex",
              alignItems: "end",
              my: 2,
              justifyContent: "flex-end",
            }}
            item
            xs={12}
            sm={12}
            md={6}
          >
            {/* <Button sx={{ height: '2.5rem', width: 150, mx: 1 }} variant="contained">
                          Cancel
                        </Button> */}
            {/* <LoadingButtonBRH type="submit" variant="containeds" label="Save" color="grey" />
          <LoadingButtonBRH type="submit" variant="containeds" label="Save" /> */}
            <Button
              type="button"
              sx={{
                height: "2.5rem",
                width: 150,
                mx: 1,
                backgroundColor: "grey",
                color: "white",
                ":hover": { color: "#fc9200", backgroundColor: "#fff7cd" },
              }}
              onClick={() => navigate(routeNames.PROSPECTPROPERTYLIST)}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{
                height: "2.5rem",
                width: 150,
                mx: 1,
                backgroundColor: "grey",
                color: "white",
                ":hover": { color: "#fc9200", backgroundColor: "#fff7cd" },
              }}
              variant="contained"
              onClick={() => handleDraft()}
            >
              Save as Draft
            </Button>
            {localStorage.getItem("verified") === "1" ||
            localStorage.getItem("verified") === "2" ? (
              <LoadingButtonBRH
                variant="containeds"
                label="Update"
                handleSubmit={handleBasicDetails}
              />
            ) : (
              <LoadingButtonBRH
                variant="containeds"
                label="Save & Next"
                handleSubmit={handleBasicDetails}
              />
            )}{" "}
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default BasicDetails;
