import React from "react";
import { Container } from "react-bootstrap";
import "../../App.css";
import logo from "../../assets/images/placeholder.png";
import moment from "moment";

const ProfileScreen = () => {
  const { name, contact_number, email, dob, mobile_no } = JSON.parse(
    localStorage.getItem("user")
  );

  return (
    <Container className="text-center mt-5">
      <Container className="shadow border rounded pt-3 pb-3 ">
        <h6 className="textPrimary">Profile Section</h6>
        <br />
        <img
          className="mb-2"
          width={200}
          height={200}
          src={logo}
          alt="section"
        />
        <div className="mt-5 mb-5">
          <h6>{name}</h6>
          <h6>{contact_number}</h6>
          <h6>{email}</h6>
          <h6>{moment(dob).format("Do MMM YYYY")}</h6>
        </div>
      </Container>
    </Container>
  );
};

export default ProfileScreen;
