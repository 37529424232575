import React from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Container style={{ height: "100vh" }}>
      <img
        style={{ width: "100%" }}
        src={require("../../assets/images/login/login-signup.png")}
        alt="page-not-found"
      />
      <div className="d-flex flex-column justify-content-center">
        <h3>Oops..</h3>
        <h1>Page Not Found</h1>
        <button
          style={{ background: "#fc9200", color: "#fff" }}
          className="btn btn-sm "
          onClick={() => navigate("/", { replace: true })}
        >
          Go To Home
        </button>
      </div>
    </Container>
  );
};

export default PageNotFound;
