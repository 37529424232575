import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Label } from "@mui/icons-material";

export const CheckBoxInputBRH = ({
  name,
  label,
  value,
  handleChange,
  imagePath,
  disabled,
}) => (
  <FormControlLabel
    label={label}
    control={
      <div
        style={{
          display: "flex",

          alignItems: "center",

          height: "10vh",
          marginRight: imagePath && "2vw",
        }}
      >
        <Checkbox
          name={name}
          aria-label={Label}
          checked={value}
          onChange={handleChange}
          disabled={disabled}
        />
        {imagePath && (
          <img
            src={imagePath}
            alt="vijay"
            style={{ width: "25px", height: "20px" }}
          />
        )}
      </div>
    }
  />
);
