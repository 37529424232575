import { FormControl, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';

export const MobileInputBRH = ({ name, label, value, handleChange, sx, required, size }) => {
  const [isAbove, setIsAbove] = useState(false);
  useEffect(() => {
    const strValue = String(value);
    if (strValue.length > 10) {
      //   console.log(strValue.length);
      setIsAbove(true);
    } else {
      setIsAbove(false);
    }
  }, [value]);
  return (
    <>
      <FormControl fullWidth>
        <TextField
          label={label}
          name={name}
          value={value}
          onChange={handleChange}
          required={required}
          sx={sx}
          variant="outlined"
          type="number"
          size={size}
          InputProps={{
            startAdornment: <InputAdornment position="start">+ 91</InputAdornment>,
          }}
          helperText={<span style={{ display: isAbove ? 'block' : 'none' }}>Entered Number is Above 10</span>}
        />
      </FormControl>
    </>
  );
};
