import { configureStore } from "@reduxjs/toolkit";
import optionReduser from "../components/options/OptionsSlice";
import savedFiltersReduser from "../components/HomePage/savedFilterSlice";
import BasicDetailsSlicereducer from "../Redux/reducers/BasicDetailsSlice";
import TabsSelectionSliceReducer from "../Redux/reducers//TabsSlice";

const store = configureStore({
  reducer: {
    optionReduser: optionReduser,
    saved: savedFiltersReduser,
    BasicDetails: BasicDetailsSlicereducer,
    TabsSelection: TabsSelectionSliceReducer,
  },
});
export default store;
