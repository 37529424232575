import { createSlice } from "@reduxjs/toolkit";

const starttime = new Date();
starttime.setHours(10);
starttime.setMinutes(0);
const endtime = new Date();
endtime.setHours(19);
endtime.setMinutes(0);
const initialState = {
  basicDetails: {
    address1: null,
    isSelectDataloadedCountry: true,
    address2: null,
    property_type_name: null,
    name: null,
    sortOptions: [],
    imageFile: [],
    contact_alternate_email: "",
    email: "",
    contact_person: "",
    countryList: [],
    countryObject: null,
    country_id: 101,
    stateList: [],
    stateObject: null,
    state_id: null,
    cityList: [],
    pseudo_name: null,
    cityObject: null,
    city_id: null,
    workingDayMon: true,
    workingDayTue: true,
    workingDayWed: true,
    workingDayThu: true,
    workingDayFri: true,
    workingDaySat: false,
    workingDaySun: false,
    workingHoursStart: starttime,
    workingHoursEnd: endtime,
    floor_no: "",

    property_status: "",
    is_draft: "",
    landmark: "",
    landline: "",
    latitude: "",
    locality: "",
    long_desc: "",
    longitude: "",
    contact_number: null,
    property_code: null,
    property_name: "",
    property_tabs: "",
    property_type_id: null,
    provider_id: null,
    pseudoname: "",
    short_desc: "",
    show_actual_name: "",
    sort_id: null,
    sortOrderObject: null,
    sort_order: null,
    start_at: "",
    propertyDataList: [],
    propertyTypeObject: null,
    status: "",
    total_sq_ft: "",
    zipcode: "",
    // pincode: null,
    providerDataList: [],
    isFetched: false,
    isSelectDataloaded: true,
  },
  selectedData: {},
  selectedImages: [],
  accordian1FormImcomplete: true,
};

export const BasicDetailsSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    AddBasicDetails: (state, action) => {
      state.basicDetails = { ...state.basicDetails, ...action.payload };
    },
    AddSelectedImages: (state, action) => {
      state.selectedImages = action.payload;
    },
    AddSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },
    setFormImcomplete: (state, action) => {
      state.accordian1FormImcomplete = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  AddBasicDetails,
  AddSelectedImages,
  setFormImcomplete,
  AddSelectedData,
} = BasicDetailsSlice.actions;

export default BasicDetailsSlice.reducer;
