import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  savedFiltersData: [],
};

export const slices = createSlice({
  name: "savedFilter",
  initialState,
  reducers: {
    onGetSavedFilters: (state, action) => {
      state.savedFiltersData = action.payload;
    },
  },
});

export const { onGetSavedFilters } = slices.actions;
export default slices.reducer;
