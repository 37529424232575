import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onRemoveSelected, onSelectedOptions } from "./OptionsSlice";
import Table from "react-bootstrap/Table";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { FaAngleUp } from "react-icons/fa";
import Api from "../../API_SERVICES/ApiServices";
import toast from "react-simple-toasts";

import airplane from "../../assets/images/email_images/airport.png";
import bus from "../../assets/images/email_images/bus.png";
import train from "../../assets/images/email_images/RlywSt (bLUE).png";
import moment from "moment";
import jsPDF from "jspdf";
import "./pdf.css";

const IMG_URL = process.env.REACT_APP_IMAGE_URL;
const SelectedOptions = () => {
  const pdfRef = useRef();

  const [propertyInfo, setPropertyInfo] = useState([]);
  const [officeSpaces, setOfficeSpaces] = useState([]);
  const [cardIndex, setCardIndex] = useState(null);
  const [moreInfo, setMoreInfo] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  //  console.log("PROPS", things?.state);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const dispatch = useDispatch();

  const selectedData = useSelector((state) => state?.optionReduser);
  const clientData = useSelector((state) => state.optionReduser?.clientData);

  const OptionsData = useSelector((state) => state.optionReduser?.selectedData);
  const queryData = useSelector((state) => state.optionReduser?.queryData);

  console.log(selectedData);

  var heading = ["SPACE", "C", "TQ", "A", "Price/m"];

  const [show, setShow] = useState(false);
  const [emailData, setEmailData] = useState({});

  const handleClose = () => setShow(false);
  const priceFormat = (str) => parseInt(str).toLocaleString("en-IN");

  let selectedPrice = selectedData?.selectedData?.map((value) => value.price);

  console.log(selectedPrice.reduce((a, b) => parseInt(a) + parseInt(b), 0));

  const history = useNavigate();

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [height, width]);

  const AddClientInfo = () => {
    if (selectedData?.selectedData <= 0) {
      Swal.fire("Select options").then((res) => {
        if (res.isConfirmed) {
          history("/");
        }
      });
    } else {
      history("/sales/add-client");
    }
  };

  const handleAdd = (data) => {
    if (selectedData.selectedData.find((sel) => sel.id === data.id)) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to remove this option",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Removed!",
            "Your option has been removed.",
            "success"
          ).then((res) => {
            if (res.isConfirmed) {
              dispatch(onRemoveSelected(data));
            }
          });
        }
      });
    } else {
      dispatch(onSelectedOptions(data));
    }
  };
  const emailCatalouge = () => {
    if (selectedData?.selectedData <= 0) {
      Swal.fire("Select options").then((res) => {
        if (res.isConfirmed) {
          history("/");
        }
      });
    } else if (clientData.length <= 0) {
      Swal.fire("Client information is missing").then((res) => {
        if (res.isConfirmed) {
          return history("/sales/add-client");
        }
      });
    } else {
      console.log("clientData", clientData);
      setShow(true);
    }
  };

  let { id } = JSON.parse(localStorage.getItem("user"));

  console.log(queryData?.address?.split(",", 1));

  const handleSaveChanges = (e) => {
    e.preventDefault();
    const formData = {
      flag: 2,
      contact_email: clientData?.contact_email,
      contact_person_email: clientData?.contact_person_email,
      optional_email: emailData?.optional_email,
      client_id: clientData?.id,
      user_id: id,
      options: selectedData?.selectedData,
      location: queryData?.address?.split(",", 1)[0],
      seats: queryData?.seats,
      start_date: queryData?.start_date,
    };
    setEmailLoading(true);
    Api.postMethod("api/option-pdf", "POST", formData)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          setEmailLoading(false);
          Swal.fire("Success", result.message, "success").then((res) => {
            if (res.isConfirmed) {
              handleClose();
              setEmailData({});
            }
          });
        }
      })
      .catch((err) => {
        console.log("Email Sending Err", err);
        setEmailLoading(false);
      });
  };

  const emailHandleChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ [name]: value });
  };

  const savePdf = () => {
    if (selectedData?.selectedData <= 0) {
      Swal.fire("Select options").then((res) => {
        if (res.isConfirmed) {
          history("/");
        }
      });
    } else if (clientData?.length === 0) {
      Swal.fire(
        "Client Information is Missing",
        "Add Client Information",
        "info"
      ).then((res) => {
        if (res.isConfirmed) {
          history("/sales/add-client");
        }
      });
    } else {
      const formData = {
        flag: 1,
        contact_email: clientData?.contact_email,
        contact_person_email: clientData?.contact_person_email,
        optional_email: emailData?.optional_email,
        client_id: clientData?.id,
        user_id: id,
        options: selectedData?.selectedData,
        location: queryData?.address?.split(",", 1)[0],
        seats: queryData?.seats,
        start_date: queryData?.start_date,
      };

      setPdfLoading(true);
      Api.postMethod("api/option-pdf", "POST", formData)
        .then((res) => res.blob())
        .then((data) => {
          var file = new Blob([data], { type: "application/pdf" });

          const href = window.URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = href;

          link.setAttribute(
            "download",
            `Sales Catalogue for ${clientData?.company_name}.pdf`
          );

          document.body.appendChild(link);
          link.click();

          setTimeout(() => {
            Swal.fire({
              title: "Do you want to create new filter",

              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes,Reset!",
            }).then((result) => {
              if (result.isConfirmed) {
                document.body.removeChild(link);
                setPdfLoading(false);
                window.location.href = "/";
              } else {
                setPdfLoading(false);
              }
            });
          }, 3000);
        });
    }
  };

  const getInfo = async (data, index) => {
    console.log("Data", data);
    await Api.postMethod("api/get-option-info", "POST", {
      property_id: data?.id,

      resource_id: data?.resource_id,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          setPropertyInfo(result.data[0]);
          console.log("RES", result.data[0]);
          setOfficeSpaces(result.data[0]?.resource);
          setCardIndex(index);
          if (data.id === result.data[0].id) {
            setMoreInfo(true);
          } else {
            setMoreInfo(false);
          }
        }
      })
      .catch((err) => {
        toast(err);
      });
  };

  const handleDownload = () => {
    const content = pdfRef.current;

    const doc = new jsPDF("p", "pt", "a4", true);
    doc.html(content, {
      callback: function (doc) {
        doc.save("sample.pdf");
      },
      width: 200, // <- here
      windowWidth: 200, // <- here
    });
  };

  const onBeforeGetContentResolve = React.useRef(null);
  const data = useRef();

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);
  // const pageStyle = ` @page {
  //   size: A4 portrait;
  // }`;

  const download = () => {
    //history("/sales/save-pdf");
  };

  return (
    <Container>
      <div className="d-flex justify-content-between mt-2">
        <button type="button" onClick={AddClientInfo} className="primary col3">
          Add Prospect
        </button>
        <button onClick={emailCatalouge} type="button" className="primary col3">
          Email Catalogue
        </button>
        <button onClick={savePdf} type="button" className="primary col3">
          {pdfLoading ? (
            <Spinner animation="border" variant="warning" />
          ) : (
            "Save PDF"
          )}
        </button>
        {/* <>
          <ReactToPrint
            // pageStyle={pageStyle}
            trigger={() => <button className="primary col3">Save PDF</button>}
            content={() => data.current}
            documentTitle="Sales Catalogue"
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint
          />
          <ComponentToPrint text={text} ref={data} />
        </> */}
      </div>
      <hr />
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="textPrimary">Selected Options</h5>
      </div>
      <Row className="d-flex  justify-content-center p-1">
        {OptionsData.length > 0 ? (
          OptionsData?.map((option, index) => (
            <Col
              className="border p-2 cardItem mb-2  "
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              key={index}
            >
              <div className="d-flex  ">
                <div className="p-1 d-flex flex-column justify-content-between  ">
                  {option?.property_type_id === 4 && (
                    <div className="ribbon-wrapper">
                      <span className="rounded-0  ribbon">BRH</span>
                    </div>
                  )}
                  <Image
                    width={140}
                    height={140}
                    src={
                      option?.images[0]?.image_path
                        ? IMG_URL + option?.images[0]?.image_path
                        : require("../../assets/images/placeholder.png")
                    }
                    // src={IMG_URL + option?.images[0]?.image_path}
                    alt="option_image"
                  />
                  <div className="ml-2 p-1">
                    <span className="textPrimary">T&nbsp;</span>
                    <span>{option?.total_seats}&nbsp;</span>
                    <span className="textPrimary">O&nbsp;</span>
                    <span>
                      {option?.property_type_id === 4
                        ? option?.total_seats - 11
                        : "--"}
                      &nbsp;
                    </span>
                    <span className="textPrimary">A&nbsp;</span>
                    <span style={{ color: "#34E16A" }}>
                      {option?.property_type_id === 4 ? (
                        option?.total_seats - 5
                      ) : (
                        <span className="mdi mdi-checkbox-blank-circle text-success"></span>
                      )}
                      &nbsp;
                    </span>
                  </div>
                </div>

                <div
                  style={{ width: "300px" }}
                  className="p-1 d-flex flex-column justify-content-between"
                >
                  <div className="mb-4">
                    <h6 style={{ padding: 0, margin: 0 }}>
                      {option?.property_name}
                    </h6>
                    <p style={{ padding: 0, margin: 0 }}>
                      <small>{option?.locality},</small>
                      <small>{option?.city?.name}</small>
                    </p>
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        whiteSpace: "nowrap",
                        fontSize: "14px  ",
                      }}
                    >
                      Price /<small>Seat (Monthly) </small> :
                      <span
                        style={{
                          padding: 0,
                          margin: 0,
                          whiteSpace: "nowrap",
                          fontSize: "14px  ",
                          marginLeft: "5px",
                        }}
                        className="textPrimary fw-bold"
                      >
                        ₹{priceFormat(option?.least_plan_price?.price)}
                      </span>
                    </p>
                    <small style={{ padding: 0, margin: 0 }}>
                      <i className="mdi mdi-map-marker-outline" />{" "}
                      {Math.round(option?.distance[0]?.distance)} Kms
                    </small>
                  </div>
                  <div className=" d-flex justify-content-between align-items-center">
                    {selectedData.selectedData.find(
                      (sel) => sel.id === option.id
                    ) ? (
                      <button
                        type="button"
                        onClick={() => handleAdd(option)}
                        className=" w-50 btn btn-danger rounded-0"
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleAdd(option)}
                        className=" w-50 btn btn-secondary rounded-0"
                      >
                        Add
                      </button>
                    )}

                    <button
                      onClick={() => {
                        getInfo(option, index);
                      }}
                      className="w-50 primary text-nowrap"
                    >
                      {moreInfo && index === cardIndex
                        ? "Less Info"
                        : "More Info"}
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={
                  moreInfo && index === cardIndex ? "d-block" : "d-none"
                }
              >
                <hr />
                <div className="p-2">
                  <div>
                    <div>
                      <span
                        className="mdi mdi-map-marker textPrimary"
                        style={{
                          padding: 0,
                          margin: 0,
                          fontSize: "20px",
                        }}
                      ></span>

                      <h6
                        style={{
                          padding: 0,
                          margin: 0,
                          display: "inline",
                        }}
                      >
                        &nbsp;
                        {propertyInfo?.address1 +
                          " , " +
                          propertyInfo?.address2}
                      </h6>
                    </div>
                    <p
                      className="d-flex justify-content-between"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <span>
                        <span
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: "20px",
                          }}
                          className="mdi mdi-account-outline textPrimary"
                        ></span>
                        <span>{propertyInfo?.contact_person}</span>
                      </span>

                      <span>
                        <span
                          style={{
                            padding: 0,
                            margin: 0,
                            fontSize: "20px",
                          }}
                          className="mdi mdi-phone-in-talk textPrimary"
                        ></span>
                        <span>
                          {propertyInfo?.contact_no ?? propertyInfo?.mobile_no}
                        </span>
                      </span>
                    </p>
                    <span style={{ padding: 0, margin: 0, fontSize: "20px" }}>
                      <span className="mdi mdi-email-outline textPrimary"></span>
                      <small>{propertyInfo?.email}</small>
                    </span>
                    <hr />
                    <p
                      className="d-flex justify-content-start"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <span>Property Type</span> :
                      <span className="textPrimary">
                        &nbsp; {propertyInfo?.property_type?.property_type_name}
                      </span>
                    </p>
                    <p
                      className="d-flex justify-content-start"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <span>Property Code</span> :
                      <span className="textPrimary">
                        {" "}
                        &nbsp;{propertyInfo?.property_code}
                      </span>
                    </p>
                    <p
                      className="d-flex justify-content-between"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <span>
                        Total Sq Ft:&nbsp;
                        <span className="textPrimary">
                          {propertyInfo?.total_sq_ft}
                        </span>
                      </span>
                      <span>
                        Floor:&nbsp;
                        <span className="textPrimary">
                          {propertyInfo?.floor_no}
                        </span>
                      </span>
                    </p>
                    <p
                      className="d-flex justify-content-start"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <span>Work Time</span> :
                      <span className="textPrimary">
                        &nbsp;
                        {moment(propertyInfo?.start_at, "hh").format("hh:mm A")}
                        to{" "}
                        {moment(propertyInfo?.end_at, "hh").format("hh:mm A")}
                      </span>
                    </p>
                    <hr />
                    <h6>OFFICE SPACES</h6>
                    <Table size="sm" striped bordered hover>
                      <thead
                        style={{
                          backgroundColor: "#fc9200",
                          color: "#ffffff",
                          fontWeight: "300",
                        }}
                      >
                        <tr>
                          {heading.map((th, index) => (
                            <th title={th}>{th}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {officeSpaces.map((td) => (
                          <tr>
                            <>
                              <td>{td?.resource_name}</td>
                              <td>{td?.capacity}</td>
                              <td>{td?.quantity}</td>
                              <td>{td?.quantity}</td>
                              <td>₹{priceFormat(td?.resource_price?.price)}</td>
                            </>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <hr />
                    <h6>AMENITIES</h6>
                    <div
                      style={{
                        padding: "5px",
                        margin: "5px",
                      }}
                    >
                      <Row>
                        {propertyInfo?.amenities?.map((ame) => (
                          <Col
                            style={{
                              width: "70px",
                              height: "65px",
                              backgroundColor: "#fff",
                              border: "1px solid #fc9200",
                              margin: "5px",
                            }}
                            xs={3}
                          >
                            <img
                              className="w-95 mt-3 "
                              width={35}
                              src={IMG_URL + ame.icon_path}
                              alt={ame.amenity_name}
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                    <hr />
                    {propertyInfo?.social_infrastructure?.length > 1 && (
                      <>
                        <h6>DISTANCE</h6>
                        <div
                          style={{
                            height: "80px",
                          }}
                          className="d-flex justify-content-between  "
                        >
                          <div className="d-flex justify-content-center align-items-center p-1">
                            <div
                              style={{
                                width: "70px",
                                height: "65px",
                                padding: "3px",
                              }}
                              className="d-flex flex-column"
                            >
                              <img
                                style={{ width: "80%", height: "90%" }}
                                alt="plane"
                                src={airplane}
                              />
                              <small>Airport</small>
                            </div>
                            <div
                              style={{ color: "#fc9200" }}
                              className="d-flex flex-column align-items-center"
                            >
                              <span style={{ fontSize: "40px" }}>
                                {propertyInfo?.social_infrastructure?.forEach(
                                  (a) =>
                                    a.id === 1 || a.id === 7 ? a.distance : ""
                                )}
                              </span>
                              <span>Kms</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center align-items-center p-1">
                            <div
                              style={{
                                width: "70px",
                                height: "65px",
                                padding: "3px",
                              }}
                              className="d-flex flex-column"
                            >
                              <img
                                style={{ width: "100%", height: "90%" }}
                                alt="plane"
                                src={bus}
                              />
                              <small>Bus Stop</small>
                            </div>
                            <div
                              style={{ color: "#fc9200" }}
                              className="d-flex flex-column align-items-center"
                            >
                              <span style={{ fontSize: "40px" }}>
                                {propertyInfo?.social_infrastructure?.map((a) =>
                                  a.id === 9 ? a.distance : ""
                                )}
                              </span>
                              <span>Kms</span>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center p-1">
                            <div
                              style={{
                                width: "70px",
                                height: "65px",
                                padding: "3px",
                              }}
                              className="d-flex flex-column"
                            >
                              <img
                                style={{ width: "80%", height: "90%" }}
                                alt="plane"
                                src={train}
                              />
                              <small>Railways</small>
                            </div>
                            <div
                              style={{ color: "#fc9200" }}
                              className="d-flex flex-column align-items-center"
                            >
                              <span style={{ fontSize: "40px" }}>
                                {propertyInfo?.social_infrastructure?.map((a) =>
                                  a.id === 2 ? a.distance : ""
                                )}
                              </span>
                              <span>Kms</span>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    <div
                      role="button"
                      style={{ color: "#fc9200" }}
                      onClick={() => setMoreInfo(!moreInfo)}
                      className="d-flex justify-content-center"
                    >
                      {<FaAngleUp />}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))
        ) : (
          <Container
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <h4 className="textPrimary">Selected Options Empty</h4>
          </Container>
        )}
      </Row>
      <Modal fullscreen={true} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="textPrimary">Email Catalogue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="textPrimary">
                Contact Person Email
              </Form.Label>
              <Form.Control
                value={clientData?.contact_person_email}
                className="rounded-0"
                type="email"
                placeholder="name@example.com"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="textPrimary">Contact Email</Form.Label>
              <Form.Control
                value={clientData?.contact_email}
                className="rounded-0"
                type="email"
                placeholder="name@example.com"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="textPrimary">Optional Email</Form.Label>
              <Form.Control
                name="optional_email"
                onChange={emailHandleChange}
                className="rounded-0"
                type="email"
                placeholder="name@example.com"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <div className="d-flex justify-content-between p-3">
          <button
            className="rounded-0 btn btn-secondary col-6"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="button"
            onClick={handleSaveChanges}
            className="primary rounded-0 col-6"
          >
            {emailLoading ? (
              <Spinner animation="border" variant="warning" />
            ) : (
              " Send Email"
            )}
          </button>
        </div>
      </Modal>
    </Container>
  );
};

export default SelectedOptions;
