import React from 'react';
import Swal from 'sweetalert2';
import './toast.css';

export const ToastBRH = (icon, title, navigateTo, isReload = true, timer = 2000, position = 'top-end') => {
  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton: false,
    timer,
    timerProgressBar: true,
    didOpen: (toast) => {
      console.log('lll');
      toast.addEventListener('mouseenter', Swal.stopTimer);
      // toast.addEventListener('mouseleave', Swal.resumeTimer);
      toast.addEventListener('mouseleave', () => console.log('laksh'));
      // toast.addEventListener('click', () => console.log('laksh'));
    },

    didClose: (toast) => {
      if (isReload === true) {
        // window.location.reload();
        if (navigateTo) {
          window.location.href = `${navigateTo}`;
        }
      }
    },
  });
  return Toast.fire({
    icon,
    title,
  });
};
