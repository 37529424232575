import React from "react";
import API from "../../API_SERVICES/ApiServices";
import Alert from "react-bootstrap/Alert";
import toast from "react-simple-toasts";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onSearchData } from "../options/OptionsSlice";
import moment from "moment";

const SavedFilters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const properties = useSelector((state) => state?.optionReduser?.optionsData);

  const savedFilter = useSelector((state) => state?.saved?.savedFiltersData);

  const searchHandle = (data) => {
    console.log("Data", data);

    let formData = {
      latitude: data?.latitude,
      longitude: data?.longitude,
      seats: data?.no_of_seat,
      property_type_id:
        data?.partner_centre === 1
          ? [2, 3, 1]
          : data?.brh_managed_centre === 1
          ? [4]
          : [1, 2, 3, 4],
      is_meeting_space: data?.is_meeting_space,
      //is_workspace:data?.is_work_space ,
      //type_property: data?.,
      resource_group_id: data?.resource_group_id?.map(
        (r) => r.resource_group_id
      ),
      amenity_id: data?.amenity_id.map((a) => a.amenity_id),
      min_price: data?.min_price,
      max_price: data?.max_price,
      status: data?.status,
      start_date: data?.start_date,
      months: data?.no_of_month,
    };

    //saveFilter();
    let page = 1;

    API.postMethod("api/get-option-list?limit=10000", "POST", formData)
      .then((res) => res.json())

      .then((result) => {
        if (result.status === "success") {
          let {
            data,
            to,
            total,
            current_page: currentPage,
            last_page: lastPage,
          } = result.data;

          const newData = page > 1 ? [...properties.data, ...data] : data;

          dispatch(
            onSearchData({
              currentPage,
              data: newData,
              lastPage,
              to,
              total,
            })
          );

          console.log("Data", newData);

          //  const newData = page > 1 ? [...properties.data, ...data] : data;

          navigate("/", { state: data });
        } else {
          toast(result.message, 3000);
        }
      });

    console.log("FormData", formData);
  };

  return (
    <div>
      <div className="form-group m-2">
        <h5 className="textPrimary">Saved Filter List</h5>
      </div>
      {savedFilter?.length > 0 ? (
        savedFilter?.map((m, index) => (
          <Alert
            className="border rounded-0 m-2 p-2 d-flex justify-content-between align-items-center"
            key={index}
            variant="light"
          >
            <div className="d-flex flex-column">
              <span style={{ fontWeight: "600" }}>{m.filter_name}</span>
              <small style={{ fontSize: "10px", fontWeight: "600" }}>
                {moment(m.created_at).format("DD MMM  yyyy")}
              </small>
            </div>
            <button onClick={() => searchHandle(m)} className=" primary">
              Search
            </button>
          </Alert>
        ))
      ) : (
        <h6 className="textPrimary text-capitalize text-center">
          No Filters Found
        </h6>
      )}
    </div>
  );
};

export default SavedFilters;
