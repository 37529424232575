import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import palette from "../../theme/palette";

export const DatePickerInputBRH = ({
  name,
  label,
  maxDate,
  value,
  handleChange,
  isAutoPopulate = 0,
}) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          format="dd - MMM - yyyy"
          sx={{
            width: "100%",
            backgroundColor: isAutoPopulate
              ? palette.secondary.lighter
              : "inherit",
          }}
          label={`${label}`}
          name={name}
          value={value}
          onChange={handleChange}
          maxDate={maxDate}
        />
      </LocalizationProvider>
    </>
  );
};
